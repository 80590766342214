import { NavLink, useLocation } from 'react-router-dom'
import React, { ReactNode, useMemo } from 'react'
import { useInjectStyle } from '../lib/style'
import { Green500 } from '../lib/colors'

export const SwitchMenu: React.FC<{
  options: Array<{
    title: string | ReactNode
    to: string
    isActive?: () => boolean
  }>
  className?: string
  optionClassName?: string
}> = ({ options, className, optionClassName }) => {
  const location = useLocation()

  const [left, right] = useMemo(() => {
    const index = options.findIndex(({ to, isActive }) =>
      isActive ? isActive() : location.pathname === to
    )
    if (index === -1) {
      return ['auto', 'auto']
    }

    return [
      index / options.length === 0 ? '0' : `${(index / options.length) * 100}%`,
      (index + 1) / options.length === 1
        ? '0'
        : `${((options.length - index - 1) / options.length) * 100}%`,
    ]
  }, [options, location.pathname])

  const cssClassName = useMemo(
    () => 'hl-menu-' + Math.round(Math.random() * 9999999).toString(36),
    []
  )

  useInjectStyle(`
  .${cssClassName}:before {
    content: '';
    background-color: ${Green500};
    border-radius: 0.75rem;
    top: 0;
    position: absolute;
    bottom: 0;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    transition: all 0.3s ease-in-out;
    left: ${left};
    right: ${right};
  }
  `)

  return (
    <ul
      className={`rounded-xl bg-white bg-opacity-30 flex text-base font-bold h-9 ${cssClassName} relative overflow-hidden ${
        className || ''
      }`}
    >
      {options.map((option) => (
        <li
          key={option.to}
          className={`flex-1 flex items-center relative w-[140px] ${
            optionClassName || ''
          }`}
        >
          <NavLink
            to={option.to}
            className={({ isActive }) =>
              `text-center w-full py-2 transition-colors no-underline ${
                (option.isActive ? option.isActive() : isActive)
                  ? 'text-blue-900 hover:text-blue-700'
                  : 'text-white hover:text-blue-300'
              }`
            }
          >
            {option.title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}
