import browserLang from 'browser-lang'
import { I18n } from 'i18n-js'
import { set } from 'lodash'
import { createContext } from 'react'

const Languages = {
  pl: {
    translations: require('../translations/pl.json'),
    flag: '🇵🇱',
    name: 'Polski',
    countryCode: 'PL',
  },
  en: {
    translations: require('../translations/en.json'),
    flag: '🇺🇸',
    name: 'English',
    countryCode: 'GB',
  },
  uk: {
    translations: require('../translations/uk.json'),
    flag: '🇺🇦',
    name: 'українська',
    countryCode: 'UA',
  },
}

const DefaultLocale: keyof typeof Languages = 'en'

const i18n = new I18n(
  Object.fromEntries(
    Object.entries(Languages).map(([key, { translations }]) => [
      key,
      Object.entries(translations).reduce(
        (nested, [path, translation]) => set(nested, path, translation),
        {}
      ),
    ])
  )
)

function setI18nConfig() {
  const locale = window.localStorage.getItem('locale')

  i18n.defaultLocale = DefaultLocale
  i18n.locale =
    locale ||
    browserLang({
      languages: Object.keys(Languages),
      fallback: DefaultLocale,
    })
}

setI18nConfig()

export const I18nContext = createContext<I18n>(i18n)

export function useI18n() {
  return i18n
}

export function useI18nManager() {
  return {
    locale: i18n.locale,
    setLocale: (locale: string) => {
      window.localStorage.setItem('locale', locale)
      i18n.locale = locale
      window.location.reload()
    },
    locales: Object.entries(Languages).map(
      ([key, { flag, name, countryCode }]) => ({
        key,
        flag,
        name,
        countryCode,
      })
    ),
  }
}
