import { GoogleRecaptchaSiteKey } from '../const'

export const useRecaptcha = () => {
  return async ({ action }: { action?: string }): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!('grecaptcha' in window)) {
        reject(new Error('Recaptcha not loaded'))
        return
      }
      // @ts-ignore
      window.grecaptcha.ready(function () {
        // @ts-ignore
        window.grecaptcha
          .execute(GoogleRecaptchaSiteKey, { action: action || 'submit' })
          .then(resolve)
      })
    })
  }
}
