import React, { Fragment, ReactNode, useContext, useMemo } from 'react'
import HomeLabPatientLogo from '../assets/homelab-patient-logo.svg'
import { SwitchMenu } from './SwitchMenu'
import { MdArrowDropDown, MdMenu, MdShoppingCart } from 'react-icons/md'
import { IoPeopleCircle, IoPersonCircle } from 'react-icons/io5'
import { Menu, Transition } from '@headlessui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoMdLogOut } from 'react-icons/io'
import QRCode from 'qrcode.react'
import { useI18n, useI18nManager } from '../provider/i18n'
import ReactCountryFlag from 'react-country-flag'
import {
  FacebookUrl,
  InstagramUrl,
  MobileAppAndroidDownloadLink,
  MobileAppDownloadLink,
  MobileAppiOSDownloadLink,
} from '../const'
import FacebookIcon from '../assets/facebook.svg'
import InstagramIcon from '../assets/instagram.svg'
import { AuthContext } from '../provider/auth'
import { BasketContext } from '../provider/basket'
import {
  useGetAttentionOrdersSubscription,
  useGetProfilesSubscription,
  useLogoutMutation,
} from '../generated/urql.client'
import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../lib/colors'
import { FaCarSide } from 'react-icons/fa6'
import {
  AddressSelectorModal,
  AddressSelectorModalType,
} from './AddressSelectorModal'
import AppleLogo from '../assets/apple-logo.svg'
import AndroidLogo from '../assets/android-logo.svg'
import HomeLabPatientLogoVertical from '../assets/homelab-patient-logo-vertical.svg'
import { Drawer, DrawerType } from './Drawer'
import { useInjectStyle } from '../lib/style'

export const Frame: React.FC<
  React.PropsWithChildren<{
    className?: string
    renderLeft?: ReactNode
    renderRight?: ReactNode
    leftClassName?: string
    rightClassName?: string
  }>
> = ({
  children,
  className,
  renderLeft,
  renderRight,
  leftClassName,
  rightClassName,
}) => {
  const i18nManager = useI18nManager()
  const location = useLocation()
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const navigate = useNavigate()
  const basket = useContext(BasketContext)
  const [, logout] = useLogoutMutation()
  const [{ data: profilesData, fetching: profilesFetching }] =
    useGetProfilesSubscription()
  const addressSelectorModalRef = React.useRef<AddressSelectorModalType>(null)
  const drawerRef = React.useRef<DrawerType>(null)
  const [{ data: attentionOrdersData }] = useGetAttentionOrdersSubscription()

  useInjectStyle(`.grecaptcha-badge { visibility: hidden; }`)

  const mainProfile = useMemo(
    () => profilesData?.client_profile?.find((profile) => profile.isMain),
    [profilesData]
  )

  return (
    <div
      className={`min-h-screen w-screen pt-16 ${className || ''} flex flex-col`}
    >
      <div className="bg-blue-900 h-16 w-screen justify-center md:justify-between items-center fixed top-0 z-10 flex">
        <Link to="/" className="flex-1 hidden md:block">
          <img
            className="px-4 xl:px-8"
            src={HomeLabPatientLogo}
            alt="HomeLab Patient app logo"
          />
        </Link>
        <nav className="flex gap-5 flex-2 w-screen md:w-auto px-4 justify-between">
          <button
            className="md:hidden"
            onClick={() => drawerRef.current?.open()}
          >
            <MdMenu size={24} color="#fff" />
          </button>
          <SwitchMenu
            options={[
              {
                title: i18n.t('components.frame.menu.catalog'),
                to: '/',
                isActive: () =>
                  location.pathname === '/' ||
                  location.pathname.startsWith('/tag') ||
                  location.pathname.startsWith('/search') ||
                  location.pathname.startsWith('/test'),
              },
              ...(auth.isLogged
                ? [
                    {
                      title: (
                        <div className="flex gap-3 justify-center items-center">
                          <span className="hidden md:inline">
                            {i18n.t('components.frame.menu.orders')}
                          </span>
                          {!!attentionOrdersData?.order.length &&
                            !location.pathname.startsWith('/orders') && (
                              <div className="relative">
                                <div
                                  className="bg-red-800 font-bold text-white rounded-full border border-white absolute -right-2 -top-2 flex items-center justify-center w-4 h-4"
                                  style={{ fontSize: '9px' }}
                                >
                                  {attentionOrdersData.order.length}
                                </div>
                              </div>
                            )}
                        </div>
                      ),
                      to: '/orders',
                      isActive: () => location.pathname.startsWith('/orders'),
                    },
                  ]
                : []),
            ]}
          />
          <SwitchMenu
            optionClassName="w-[36px] md:w-[120px]"
            options={[
              {
                title: (
                  <div className="flex gap-3 justify-center items-center">
                    <span className="hidden md:inline">
                      {i18n.t('components.frame.menu.basket')}
                    </span>
                    <div className="relative">
                      <MdShoppingCart />
                      {basket.items.length > 0 &&
                        location.pathname !== '/basket' && (
                          <div
                            className="bg-green-500 font-bold text-blue-900 rounded-full border border-white absolute -right-2 -top-2 flex items-center justify-center w-4 h-4"
                            style={{ fontSize: '9px' }}
                          >
                            {basket.items.length}
                          </div>
                        )}
                    </div>
                  </div>
                ),
                to: '/basket',
              },
            ]}
          />
        </nav>
        <div className="text-white md:flex gap-1 mr-4 xl:mr-8 flex-1 justify-end items-center hidden">
          <button
            className="flex gap-1.5 mx-3"
            onClick={() => addressSelectorModalRef.current?.show()}
          >
            <FaCarSide size={20} />
            {basket.address?.city}
          </button>
          <span className="text-gray-400">|</span>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              {auth.isLogged && (
                <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 pl-3 py-2 text-white">
                  {profilesFetching && !mainProfile && (
                    <TailSpin color={Blue300} height={20} />
                  )}
                  {mainProfile?.firstName && (
                    <>
                      <IoPersonCircle size={20} />
                      {mainProfile.firstName} {mainProfile.lastName}
                      <MdArrowDropDown
                        className="-mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Menu.Button>
              )}
              {!auth.isLogged && (
                <Link
                  to="/login"
                  className="inline-flex w-full justify-center gap-x-1.5 pl-3 py-2 text-white"
                >
                  <IoPersonCircle size={20} />
                  {i18n.t('components.frame.menu.login')}
                </Link>
              )}
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="divide-y divide-gray-100 absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profiles"
                        className={`${
                          active
                            ? 'bg-gray-100 !text-gray-900'
                            : 'text-gray-700'
                        } block px-4 py-2 flex items-center gap-1.5 no-underline transition-colors`}
                      >
                        <IoPeopleCircle size={20} className="text-gray-400" />
                        {i18n.t('components.frame.menu.account.profiles')}
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={async () => {
                          await logout({})
                          await auth.clearTokens()
                          basket.logout()
                          navigate('/')
                        }}
                        className={`${
                          active
                            ? 'bg-gray-100 !text-gray-900'
                            : 'text-gray-700'
                        } block px-4 py-2 flex items-center gap-1.5 no-underline transition-colors w-full`}
                      >
                        <IoMdLogOut size={20} className="text-gray-400" />
                        {i18n.t('components.frame.menu.account.logout')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 p-5">
        <div className={`flex-none ${leftClassName || ''}`}>{renderLeft}</div>
        <div className="flex-1">{children}</div>
        <div className={`flex-none ${rightClassName || ''}`}>{renderRight}</div>
      </div>
      <div className="flex flex-col md:hidden bg-blue-300 p-8 items-center gap-5 mt-auto">
        <a
          href={MobileAppiOSDownloadLink}
          target="_blank"
          className="flex items-center justify-center bg-white rounded-2xl w-[240px] h-[48px] text-center font-bold text-black no-underline gap-2.5"
          rel="noreferrer"
        >
          <img src={AppleLogo} />
          <span>{i18n.t('components.frame.footer.mobileInstall.iOS')}</span>
        </a>
        <a
          href={MobileAppAndroidDownloadLink}
          target="_blank"
          className="flex items-center justify-center bg-white rounded-2xl w-[240px] h-[48px] text-center font-bold text-black no-underline gap-2.5"
          rel="noreferrer"
        >
          <img src={AndroidLogo} />
          <span>{i18n.t('components.frame.footer.mobileInstall.android')}</span>
        </a>
      </div>
      <footer className="bg-green-500 h-20 px-5 lg:px-20 flex justify-center md:justify-between md:mt-auto">
        <div className="md:flex items-center h-full gap-4 hidden">
          <a
            href={MobileAppDownloadLink}
            className="no-underline text-black hover:text-gray-600"
          >
            {i18n.t('components.frame.footer.mobileInstall.label')}
          </a>
          <div>
            <a
              href={MobileAppDownloadLink}
              className="no-underline text-black hover:text-gray-600"
            >
              <QRCode
                value={MobileAppDownloadLink}
                width={72}
                height={72}
                renderAs="svg"
                className="bg-white p-2.5 rounded -mt-6"
                style={{ boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}
              />
            </a>
          </div>
        </div>
        <div className="flex items-center h-full gap-2">
          <Link
            to="/contact"
            className="no-underline text-black hover:text-gray-600"
          >
            {i18n.t('components.frame.footer.contact.label')}
          </Link>
          <span>|</span>
          <a
            href="https://homelab24.pl"
            target="_blank"
            className="no-underline text-black hover:text-gray-600"
            rel="noreferrer"
          >
            {i18n.t('components.frame.footer.homelab24.label')}
          </a>
          <span>|</span>
          {i18nManager.locales
            .filter((locale) => locale.key !== i18nManager.locale)
            .map(({ name, key, countryCode }) => (
              <button
                key={key}
                title={name}
                onClick={(event) => {
                  event.preventDefault()
                  i18nManager.setLocale(key)
                }}
                className="text-xl"
              >
                <ReactCountryFlag countryCode={countryCode} svg />
              </button>
            ))}
          <span>|</span>
          <p className="mr-2 leading-4 hidden md:block">
            {i18n.t('components.frame.footer.socialMedia.visitUsOn')}
            <br />
            <strong>
              {i18n.t('components.frame.footer.socialMedia.socialMedia')}
            </strong>
          </p>
          <a
            href={FacebookUrl}
            target="_blank"
            rel="noreferrer"
            className="mr-1"
          >
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a href={InstagramUrl} target="_blank" rel="noreferrer">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
        </div>
      </footer>
      <AddressSelectorModal ref={addressSelectorModalRef} />
      <Drawer ref={drawerRef} className="md:hidden">
        <div className="flex flex-col h-full">
          <img
            src={HomeLabPatientLogoVertical}
            alt="Logo"
            className="m-10 my-auto"
          />
          <ul className="border-t border-t-border border-opacity-20">
            <li className="border-b border-b-border border-opacity-20">
              <button
                className="flex gap-2.5 p-5 text-white text-lg"
                onClick={() => {
                  drawerRef.current?.close()
                  addressSelectorModalRef.current?.show()
                }}
              >
                <FaCarSide size={24} color="#fff" />
                {basket.address?.city}
              </button>
            </li>
            {auth.isLogged && (
              <li className="border-b border-b-border border-opacity-20">
                <Link
                  to="/profiles"
                  className="flex gap-2.5 text-white text-lg p-5 no-underline"
                >
                  <IoPersonCircle size={24} />
                  {mainProfile?.firstName &&
                    mainProfile.firstName + ' ' + mainProfile.lastName}
                  {profilesFetching && !mainProfile && (
                    <TailSpin color={Blue300} height={20} />
                  )}
                </Link>
              </li>
            )}
            {!auth.isLogged && (
              <li className="border-b border-b-border border-opacity-20">
                <Link
                  to="/login"
                  className="inline-flex w-full gap-x-2.5 text-white text-lg p-5 no-underline"
                >
                  <IoPersonCircle size={24} />
                  {i18n.t('components.frame.menu.login')}
                </Link>
              </li>
            )}
            {auth.isLogged && (
              <li className="border-b border-b-border border-opacity-20">
                <button
                  onClick={async () => {
                    await logout({})
                    await auth.clearTokens()
                    basket.logout()
                    navigate('/')
                  }}
                  className={`text-white block flex items-center gap-2.5 no-underline transition-colors w-full text-lg p-5`}
                >
                  <IoMdLogOut size={24} className="text-gray-400" />
                  {i18n.t('components.frame.menu.account.logout')}
                </button>
              </li>
            )}
          </ul>
        </div>
      </Drawer>
    </div>
  )
}
