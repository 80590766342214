import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  GetCurrentOrdersSubscription,
  Order_State_Enum_Enum,
  useGetCurrentOrdersSubscription,
  useGetPastOrdersSubscription,
} from '../../generated/urql.client'
import { useI18n } from '../../provider/i18n'
import { OrderState2Label } from '../../types'
import { Link } from 'react-router-dom'
import { getOrderStateLabel, getStateColor } from '../../lib/order'
import { MdFolderShared, MdKeyboardArrowRight } from 'react-icons/md'
import { Blue600 } from '../../lib/colors'
import { TailSpin } from 'react-loader-spinner'
import moment from 'moment'

const OrderItem: React.FC<{
  order: GetCurrentOrdersSubscription['order'][number]
}> = ({ order }) => {
  const i18n = useI18n()
  const navigate = useNavigate()

  const getStateLabel = useCallback(
    (state?: string | null) => {
      if (!state) return ''
      return i18n.t(OrderState2Label[state as Order_State_Enum_Enum])
    },
    [i18n]
  )

  const itemNames = useMemo(
    () => [
      ...order.orderTests.map(({ test }) => test.name),
      ...order.orderConsultations.map(({ consultation }) => consultation.name),
      ...order.orderTrainings.map(({ training }) => training.name),
    ],
    [order.orderConsultations, order.orderTests, order.orderTrainings]
  )

  return (
    <div className="hl-panel flex">
      <div
        className={`rounded-l-xl w-5`}
        style={{ backgroundColor: getStateColor(order.currentState) }}
      />
      <button
        className="p-5 flex gap-5 items-center w-full"
        onClick={() => navigate(`/orders/${order.id}`)}
      >
        <div className="mr-auto text-left">
          <p
            className="md:hidden"
            style={{
              color: getStateColor(order.currentState),
              filter: 'brightness(.75)',
            }}
          >
            {getOrderStateLabel(order)}
          </p>
          <p className={`${itemNames.length <= 1 && 'text-lg'} font-bold`}>
            {itemNames.join(', ')}
          </p>
        </div>
        <p
          className="hidden md:block"
          style={{
            color: getStateColor(order.currentState),
            filter: 'brightness(.75)',
          }}
        >
          {getStateLabel(order.currentState)}
        </p>
        <MdKeyboardArrowRight size={24} color={Blue600} className="min-w-6" />
      </button>
    </div>
  )
}
export const OrdersIndexPage = () => {
  const i18n = useI18n()
  const monthAgo = useMemo(
    () => moment().subtract(1, 'month').toISOString(),
    []
  )
  const [{ data: currentOrdersData, fetching: currentOrdersFetching }] =
    useGetCurrentOrdersSubscription({ variables: { date: monthAgo } })
  const [{ data: pastOrdersData, fetching: pastOrdersFetching }] =
    useGetPastOrdersSubscription({ variables: { date: monthAgo } })
  const [pastOrdersOpen, setPastOrdersOpen] = useState(false)

  const currentOrders = useMemo(
    () => currentOrdersData?.order,
    [currentOrdersData]
  )
  const pastOrders = useMemo(() => pastOrdersData?.order, [pastOrdersData])

  return (
    <div>
      <div className="text-blue-700 mb-5">
        <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
        {' > '}
        {i18n.t('screens.orders.index.breadcrumbs.orders')}
      </div>

      <h1 className="mb-8 mt-5 text-2xl md:text-3xl font-bold flex items-center gap-2.5">
        {i18n.t('screens.orders.index.title')}
        {(currentOrdersFetching || pastOrdersFetching) && (
          <TailSpin color={Blue600} width={20} height={20} />
        )}
      </h1>

      {!!currentOrders && (
        <ul className="flex flex-col gap-5">
          {currentOrders.map((order) => (
            <li key={order.id}>
              <OrderItem order={order} />
            </li>
          ))}
        </ul>
      )}
      {!!pastOrders && (
        <>
          {!!currentOrders?.length && <hr className="my-7" />}
          <div
            className="hl-panel flex p-5 gap-2.5 md:gap-9 items-center cursor-pointer mb-5"
            onClick={() => setPastOrdersOpen(!pastOrdersOpen)}
          >
            <p className="text-lg font-bold">
              {i18n.t('screens.orders.index.archive.title')}
            </p>
            <div className="flex items-center gap-1.5 md:gap-2.5 ml-auto md:ml-0">
              <MdFolderShared size={24} color={Blue600} />
              <p>
                <strong>{pastOrders.length}</strong>{' '}
                <span className="hidden md:block">
                  {i18n.t('screens.orders.index.archive.resultsCount')}
                </span>
              </p>
            </div>
            <MdKeyboardArrowRight
              className={`md:ml-auto ${
                pastOrdersOpen ? 'rotate-90 transform' : ''
              }`}
              size={24}
              color={Blue600}
            />
          </div>
          {pastOrdersOpen && (
            <ul className="flex flex-col gap-5 md:ml-5">
              {pastOrders.map((order) => (
                <li key={order.id}>
                  <OrderItem order={order} />
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  )
}
