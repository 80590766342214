import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import { useI18n } from '../provider/i18n'
import { useUpdateOrderTestIdentitiesMutation } from '../generated/urql.client'
import Switch from 'react-switch'
import { Blue300, Green600 } from '../lib/colors'
import { ClientIdentity } from '../lib/identity'
import Countries from '../assets/countries.json'
import IconInput from './IconInput'
import { MdAspectRatio, MdFlag } from 'react-icons/md'
import isPeselValid from 'pesel-check'
import ReactSelect, { components } from 'react-select'
import { FaAddressCard } from 'react-icons/fa6'
import { FaBaby } from 'react-icons/fa'

export type OrderIdentityModalType = {
  show: (args: {
    orderTestIds: number[]
    firstName: string
    lastName: string
    onError?: (error: string) => void
    onSuccess?: () => void
  }) => void
}

type OrderIdentityForm = {
  identityType: string
  identityValue: string
  identityCountryCode: string
  identityDateOfBirth: string
}

export const OrderIdentityModal = forwardRef<OrderIdentityModalType>(
  (_, ref) => {
    const [show, setShow] = useState(false)
    const [orderTestIds, setOrderTestIds] = useState<number[]>([])
    const [firstName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [onSuccess, setOnSuccess] = useState<() => void>()
    const [onError, setOnError] = useState<(error: string) => void>()
    const [
      { fetching: updateOrderTestIdentitiesFetching },
      updateOrderTestIdentities,
    ] = useUpdateOrderTestIdentitiesMutation()
    const i18n = useI18n()

    const {
      control,
      register,
      handleSubmit,
      watch,
      formState: { errors },
      reset,
    } = useForm<OrderIdentityForm>({
      defaultValues: {
        identityType: 'pesel',
        identityValue: '',
        identityCountryCode: '',
        identityDateOfBirth: '',
      },
    })

    useImperativeHandle(ref, () => ({
      show: ({ orderTestIds, firstName, lastName, onError, onSuccess }) => {
        setOrderTestIds(orderTestIds)
        setFirstName(firstName)
        setLastName(lastName)
        setOnSuccess(() => onSuccess)
        setOnError(() => onError)
        setShow(true)
        reset()
      },
    }))

    const IdentityTypeOptions = useMemo(
      () => [
        {
          value: 'id_card',
          label: i18n.t('common.identity.idCard'),
        },
        {
          value: 'passport',
          label: i18n.t('common.identity.passport'),
        },
      ],
      [i18n]
    )

    const IdentityCountryCodeOptions = useMemo(
      () =>
        Countries.map(({ name, flag, isoCode }) => ({
          labelWithFlag: `${flag} ${i18n.t(`common.country.${name}`)}`,
          label: i18n.t(`common.country.${name}`),
          value: isoCode,
          flag,
        })),
      [i18n]
    )

    const doSubmit = useCallback(
      async (form: OrderIdentityForm) => {
        let identity: ClientIdentity
        switch (form.identityType) {
          case 'id_card':
            identity = {
              type: 'id_card',
              value: form.identityValue,
              countryCode: form.identityCountryCode,
              dateOfBirth: form.identityDateOfBirth,
            }
            break
          case 'passport':
            identity = {
              type: 'passport',
              value: form.identityValue,
              countryCode: form.identityCountryCode,
              dateOfBirth: form.identityDateOfBirth,
            }
            break
          case 'pesel':
          default:
            identity = {
              type: 'pesel',
              value: form.identityValue,
            }
        }

        const { error } = await updateOrderTestIdentities({
          orderTestIds,
          identity,
        })

        if (error) {
          onError?.(error.message)
          setShow(false)
          return
        }

        setShow(false)
        onSuccess?.()
      },
      [onError, onSuccess, orderTestIds, updateOrderTestIdentities]
    )

    const doCancel = useCallback(() => {
      setShow(false)
    }, [setShow])

    if (!show) return null

    return (
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={show}
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Title className="text-lg mb-2.5 text-blue-700">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: i18n.t('components.orderIdentityModal.title', {
                          firstName,
                          lastName,
                        }),
                      }}
                    ></span>
                  </Dialog.Title>
                  <div className="mb-10">
                    {watch('identityType') === 'pesel' && (
                      <div className="my-4">
                        <IconInput
                          leftIcon={<MdAspectRatio size={24} color={Blue300} />}
                          {...register('identityValue', {
                            required: i18n.t(
                              'components.orderIdentityModal.pesel.rules.required'
                            ),
                            validate: (value) =>
                              isPeselValid(value) ||
                              watch('identityType') !== 'pesel'
                                ? undefined
                                : i18n.t(
                                    'components.orderIdentityModal.pesel.rules.pattern'
                                  ),
                          })}
                          placeholder={i18n.t(
                            'components.orderIdentityModal.pesel.placeholder'
                          )}
                        />
                        {errors.identityValue && (
                          <p className="hl-input-error">
                            {errors.identityValue.message}
                          </p>
                        )}
                      </div>
                    )}

                    <Controller
                      name="identityType"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <label>
                            <Switch
                              checked={value !== 'pesel'}
                              onChange={(v) =>
                                onChange(v ? 'id_card' : 'pesel')
                              }
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={24}
                              onColor={Green600}
                              className="align-middle mr-1"
                            />
                            <span className="text-xs">
                              {i18n.t(
                                'components.orderIdentityModal.pesel.noPesel'
                              )}
                            </span>
                          </label>
                          {error && <p>{error.message}</p>}
                        </div>
                      )}
                    />

                    {watch('identityType') !== 'pesel' && (
                      <>
                        <div className="my-4">
                          <Controller
                            name="identityType"
                            control={control}
                            rules={{
                              required: i18n.t(
                                'components.orderIdentityModal.identityType.rules.required'
                              ),
                            }}
                            render={({ field: { value, onChange } }) => (
                              <ReactSelect
                                value={IdentityTypeOptions.find(
                                  (identityType) => identityType.value === value
                                )}
                                isSearchable={false}
                                onChange={onChange}
                                options={IdentityTypeOptions}
                                classNames={{
                                  input: () => '!p-0 !m-0',
                                  control: () =>
                                    '!shadow-none !rounded-none !border-t-0 !border-l-0 !border-r-0 !border-b !border-b-border !border-solid !border-opacity-50 !px-2 !py-2.5 !bg-transparent !text-lg',
                                  valueContainer: () => '!p-0 !pl-7',
                                  option: ({ isFocused, isSelected }) =>
                                    `!text-blue-700 ${
                                      isFocused ? '!bg-blue-200' : ''
                                    } ${
                                      isSelected ? 'font-bold !bg-white' : ''
                                    }`,
                                  dropdownIndicator: () =>
                                    '!text-blue-300 !p-0',
                                  indicatorSeparator: () => 'hidden',
                                  clearIndicator: () => '!text-blue-300 !p-1',
                                }}
                                placeholder={i18n.t(
                                  'components.orderIdentityModal.identityType.placeholder'
                                )}
                                components={{
                                  ValueContainer: ({ children, ...props }) => (
                                    <components.ValueContainer {...props}>
                                      <FaAddressCard
                                        size={24}
                                        color={Blue300}
                                        className="absolute"
                                      />
                                      {children}
                                    </components.ValueContainer>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="my-4">
                          <Controller
                            name="identityCountryCode"
                            control={control}
                            rules={{
                              required: i18n.t(
                                'components.orderIdentityModal.identityCountryCode.rules.required'
                              ),
                            }}
                            render={({ field: { value, onChange } }) => (
                              <ReactSelect
                                value={IdentityCountryCodeOptions.find(
                                  (identityType) => identityType.value === value
                                )}
                                onChange={(option) => onChange(option?.value)}
                                options={IdentityCountryCodeOptions.map(
                                  (option) => ({
                                    ...option,
                                    label: option.labelWithFlag,
                                  })
                                )}
                                maxMenuHeight={200}
                                classNames={{
                                  input: () => '!p-0 !m-0',
                                  control: () =>
                                    '!shadow-none !rounded-none !border-t-0 !border-l-0 !border-r-0 !border-b !border-b-border !border-solid !border-opacity-50 !px-2 !py-2.5 !bg-transparent !text-lg',
                                  valueContainer: () => '!p-0 !pl-7',
                                  option: ({ isFocused, isSelected }) =>
                                    `!text-blue-700 ${
                                      isFocused ? '!bg-blue-200' : ''
                                    } ${
                                      isSelected ? 'font-bold !bg-white' : ''
                                    }`,
                                  dropdownIndicator: () =>
                                    '!text-blue-300 !p-0',
                                  indicatorSeparator: () => 'hidden',
                                  clearIndicator: () => '!text-blue-300 !p-1',
                                }}
                                placeholder={i18n.t(
                                  'components.orderIdentityModal.identityCountryCode.placeholder'
                                )}
                                components={{
                                  ValueContainer: ({ children, ...props }) => {
                                    const flag =
                                      IdentityCountryCodeOptions.find(
                                        (option) => option.value === value
                                      )?.flag
                                    return (
                                      <components.ValueContainer {...props}>
                                        {flag ? (
                                          <div className="absolute text-xl">
                                            {flag}
                                          </div>
                                        ) : (
                                          <MdFlag
                                            size={24}
                                            color={Blue300}
                                            className="absolute"
                                          />
                                        )}
                                        {children}
                                      </components.ValueContainer>
                                    )
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="my-4">
                          <IconInput
                            leftIcon={
                              <MdAspectRatio size={24} color={Blue300} />
                            }
                            {...register('identityValue', {
                              required: i18n.t(
                                'components.orderIdentityModal.identityValue.rules.required'
                              ),
                            })}
                            placeholder={i18n.t(
                              'components.orderIdentityModal.identityValue.placeholder'
                            )}
                          />
                          {errors.identityValue && (
                            <p className="hl-input-error">
                              {errors.identityValue.message}
                            </p>
                          )}
                        </div>
                        <div className="my-4">
                          <IconInput
                            leftIcon={<FaBaby size={24} color={Blue300} />}
                            type="date"
                            max={new Date().toISOString()}
                            {...register('identityDateOfBirth', {
                              required: i18n.t(
                                'components.orderIdentityModal.identityDateOfBirth.rules.required'
                              ),
                            })}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex justify-end gap-2.5">
                    <button
                      className="hl-button"
                      disabled={updateOrderTestIdentitiesFetching}
                      onClick={handleSubmit(doSubmit)}
                    >
                      {i18n.t('common.ok')}
                    </button>
                    <button
                      className="hl-button hl-gradient-red"
                      disabled={updateOrderTestIdentitiesFetching}
                      onClick={doCancel}
                    >
                      {i18n.t('common.cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
)
