import React, { useCallback } from 'react'
import {
  GetProfilesSubscription,
  useGetProfilesSubscription,
} from '../generated/urql.client'
import { isIdentity } from '../lib/identity'
import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../lib/colors'
import ReactSelect, { components } from 'react-select'
import { MdPerson } from 'react-icons/md'
import { useI18n } from '../provider/i18n'

type Profile = GetProfilesSubscription['client_profile'][number]

const ProfileSelect: React.FC<{
  onChange: (profile?: Profile) => void
  value?: Profile
  filter?: (profile: Profile) => boolean
  appearance?: 'input' | 'button'
  className?: string
}> = ({ onChange, filter, value, appearance = 'button', className }) => {
  const i18n = useI18n()
  const [{ data: profilesData, fetching: profilesFetching }] =
    useGetProfilesSubscription()

  const renderIdentity = useCallback(
    (identity: unknown) => {
      if (!isIdentity(identity)) {
        return '?'
      }

      switch (identity.type) {
        case 'id_card':
          return i18n.t('components.profileSelect.identity.id_card', {
            value: identity.value,
            countryCode: identity.countryCode,
          })
        case 'passport':
          return i18n.t('components.profileSelect.identity.passport', {
            value: identity.value,
            countryCode: identity.countryCode,
          })
        case 'pesel':
          return i18n.t('components.profileSelect.identity.pesel', {
            value: identity.value,
          })
      }
    },
    [i18n]
  )

  const profiles = profilesData?.client_profile.filter(filter || (() => true))

  return (
    <ReactSelect
      className={className}
      classNames={{
        control: () =>
          appearance === 'button'
            ? '!border-green-700 !border-2 !rounded-xl h-12 !shadow-none'
            : '!shadow-none !rounded-none !border-t-0 !border-l-0 !border-r-0 !border-b !border-b-border !border-solid !border-opacity-50 !p-2.5 !bg-transparent !text-lg',
        singleValue: () =>
          '!text-blue-700 font-bold !whitespace-normal leading-none',
        valueContainer: () => '!pr-0 !pl-7',
        indicatorSeparator: () => 'hidden',
        dropdownIndicator: () => '!text-blue-600',
        option: ({ isFocused, isSelected }) =>
          `!text-blue-700 ${isFocused ? '!bg-blue-200' : ''} ${
            isSelected ? 'font-bold !bg-white' : ''
          }`,
        menu: () => '!w-auto',
      }}
      noOptionsMessage={() => i18n.t('components.profileSelect.noOptions')}
      loadingMessage={() => i18n.t('components.profileSelect.loading')}
      isLoading={profilesFetching}
      isOptionSelected={(option, selectValue) =>
        !!selectValue.find((o) => o.value.id === option.value.id)
      }
      components={{
        LoadingIndicator: (props) => (
          <TailSpin {...props} color={Blue300} height={20} width={20} />
        ),
        ValueContainer: ({ children, ...props }) => (
          <components.ValueContainer {...props}>
            <MdPerson size={24} color={Blue300} className="absolute" />
            {children}
          </components.ValueContainer>
        ),
      }}
      placeholder={''}
      value={
        value
          ? {
              label: <div>{value.firstName + ' ' + value.lastName}</div>,
              value: value,
            }
          : null
      }
      onChange={(option) => option && onChange(option.value)}
      options={profiles?.map((profile) => ({
        value: profile,
        label: (
          <div>
            <p>{profile.firstName + ' ' + profile.lastName}</p>
            <p className="text-xs whitespace-nowrap text-black">
              {renderIdentity(profile.identity)}
            </p>
          </div>
        ),
      }))}
      hideSelectedOptions
    />
  )
}

export default ProfileSelect
