import { Order_State_Enum_Enum } from './generated/urql.client'

export const OrderState2Label: {
  [key in keyof typeof Order_State_Enum_Enum]: string
} = {
  [Order_State_Enum_Enum.Unpaid]: 'order.state.unpaid',
  [Order_State_Enum_Enum.AwaitingClientConfirmation]:
    'order.state.awaitingClientConfirmation',
  [Order_State_Enum_Enum.AwaitingConfirmation]:
    'order.state.awaitingConfirmation',
  [Order_State_Enum_Enum.DifferentDateProposed]:
    'order.state.differentDateProposed',
  [Order_State_Enum_Enum.DifferentDateAccepted]:
    'order.state.differentDateAccepted',
  [Order_State_Enum_Enum.DifferentDateRejected]:
    'order.state.differentDateRejected',
  [Order_State_Enum_Enum.Confirmed]: 'order.state.confirmed',
  [Order_State_Enum_Enum.OnTheWayToClient]: 'order.state.onTheWayToClient',
  [Order_State_Enum_Enum.CollectingSamples]: 'order.state.collectingSamples',
  [Order_State_Enum_Enum.OnTheWayToLab]: 'order.state.onTheWayToLab',
  [Order_State_Enum_Enum.ExaminingSamples]: 'order.state.examiningSamples',
  [Order_State_Enum_Enum.Finished]: 'order.state.finished',
  [Order_State_Enum_Enum.Cancelled]: 'order.state.cancelled',
}
