import React, { useContext, useEffect, useMemo } from 'react'
import { CookiesProvider } from 'react-cookie'
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import { getClient } from './lib/client'
import { ComplaintPage } from './pages/Complaint'
import { ContactPage } from './pages/Contact'
import { LoginPage } from './pages/Login'
import { LoginRecoverPage } from './pages/LoginRecover'
import { OrdersPage } from './pages/Orders'
import { ProfilesPage } from './pages/Profiles'
import { RegisterPage } from './pages/Register'
import { RegisterFinishPage } from './pages/RegisterFinish'
import { ResetPasswordFinishPage } from './pages/ResetPasswordFinish'
import { ResetPasswordInitPage } from './pages/ResetPasswordInit'
import { AuthContext, useAuth } from './provider/auth'
import { BasketContext, useBasket } from './provider/basket'
import { I18nContext, useI18n } from './provider/i18n'
import { AddressSelectorPage } from './pages/AddressSelector'
import { CatalogPage } from './pages/Catalog'
import { CatalogIndexPage } from './pages/catalog/Index'
import { CatalogTagPage } from './pages/catalog/Tag'
import { CatalogSearchPage } from './pages/catalog/Search'
import { OrdersIndexPage } from './pages/orders/Index'
import { CatalogTestPage } from './pages/catalog/Test'
import { BasketPage } from './pages/Basket'
import { Navigate, useParams } from 'react-router'
import { NotFoundPage } from './pages/NotFound'
import { PingDocument } from './generated/urql.anonymous'
import { OrdersOrderPage } from './pages/orders/Order'
import { ProtectedRoute } from './components/ProtectedRoute'
import { CatalogConsultationPage } from './pages/catalog/Consultation'
import { CatalogTrainingPage } from './pages/catalog/Training'

const RedirectOrder = () => {
  const params = useParams<{ id: string }>()
  return <Navigate to={`/orders/${params.id}`} />
}

const EnsureClientLocation = () => {
  const basket = useContext(BasketContext)
  const location = useLocation()

  if (
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/register') ||
    location.pathname.startsWith('/resetpassword')
  ) {
    return <Outlet />
  }

  if (!basket.address?.city) {
    return <Navigate to={`/address-selector`} />
  }

  return <Outlet />
}

function AnimatedRoutes() {
  const location = useLocation()

  return (
    /*<TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>*/
    <Routes location={location}>
      <Route element={<EnsureClientLocation />}>
        <Route path="/" element={<CatalogPage />}>
          <Route index element={<CatalogIndexPage />} />
          <Route path="/tag/:tagId" element={<CatalogTagPage />} />
          <Route path="/search" element={<CatalogSearchPage />} />
          <Route path="/test/:id" element={<CatalogTestPage />} />
          <Route
            path="/consultation/:id"
            element={<CatalogConsultationPage />}
          />
          <Route path="/training/:id" element={<CatalogTrainingPage />} />
        </Route>
        <Route path="/basket" element={<BasketPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/recover" element={<LoginRecoverPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/finish" element={<RegisterFinishPage />} />
        <Route path="/resetpassword" element={<ResetPasswordInitPage />} />
        <Route
          path="/resetpassword/finish"
          element={<ResetPasswordFinishPage />}
        />
        <Route element={<ProtectedRoute />}>
          <Route path="/profiles" element={<ProfilesPage />} />
          <Route path="/profiles/:id" element={<ProfilesPage />} />
          <Route path="/orders" element={<OrdersPage />}>
            <Route index element={<OrdersIndexPage />} />
            <Route path="/orders/:id" element={<OrdersOrderPage />} />
          </Route>
          <Route path="/compliant" element={<ComplaintPage />} />
        </Route>
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/address-selector" element={<AddressSelectorPage />} />
      <Route path="/order/:id" element={<RedirectOrder />} />
    </Routes>
    /*</CSSTransition>
    </TransitionGroup>*/
  )
}

function App() {
  const auth = useAuth()
  const basket = useBasket()
  const i18n = useI18n()
  const client = useMemo(() => getClient(auth, i18n), [auth, i18n])

  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.isLogged) {
        client.mutation(PingDocument, {}).toPromise().catch(console.error)
      }
    }, 60000)
    return () => clearInterval(interval)
  })

  return (
    <CookiesProvider>
      <I18nContext.Provider value={i18n}>
        <AuthContext.Provider value={auth}>
          <BasketContext.Provider value={basket}>
            <UrqlProvider value={client}>
              <Router>
                <AnimatedRoutes />
              </Router>
            </UrqlProvider>
          </BasketContext.Provider>
        </AuthContext.Provider>
      </I18nContext.Provider>
    </CookiesProvider>
  )
}

export default App
