import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { GetProfilesSubscription } from '../generated/urql.client'
import { Dialog, Transition } from '@headlessui/react'
import ProfileSelect from './ProfileSelect'
import { useI18n } from '../provider/i18n'

export type ProfileSelectorModalType = {
  show: (args: {
    onChange: (profile: Profile) => void
    filter?: (profile: Profile) => boolean
  }) => void
}

type Profile = GetProfilesSubscription['client_profile'][number]

const ProfileSelectorModal = forwardRef<ProfileSelectorModalType>((_, ref) => {
  const [onChange, setOnChange] = useState<(profile: Profile) => void>()
  const [filter, setFilter] = useState<(profile: Profile) => boolean>()
  const [show, setShow] = useState(false)
  const i18n = useI18n()
  const [selectedProfile, setSelectedProfile] = useState<Profile>()

  useImperativeHandle(ref, () => ({
    show: (args) => {
      setOnChange(() => args.onChange)
      setFilter(() => args.filter)
      setShow(true)
      setSelectedProfile(undefined)
    },
  }))

  const doCancel = useCallback(() => {
    setShow(false)
  }, [])

  if (!show) return null

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" open={show} onClose={doCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <Dialog.Title className="text-lg mb-2.5 text-blue-700">
                  {i18n.t('components.profileSelectorModal.title')}
                </Dialog.Title>

                <ProfileSelect
                  onChange={setSelectedProfile}
                  filter={filter}
                  value={selectedProfile}
                  appearance="input"
                  className="mb-5"
                />

                <div className="flex justify-end gap-2.5">
                  <button
                    className="hl-button"
                    disabled={!selectedProfile}
                    onClick={() => {
                      setShow(false)
                      onChange && selectedProfile && onChange(selectedProfile)
                    }}
                  >
                    {i18n.t('common.ok')}
                  </button>
                  <button
                    className="hl-button hl-gradient-red"
                    onClick={doCancel}
                  >
                    {i18n.t('common.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
})

export default ProfileSelectorModal
