import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useClientContactSendMutation } from '../generated/urql.client'
import { EmailRegex } from '../lib/patterns'
import { AuthContext } from '../provider/auth'
import { useI18n } from '../provider/i18n'
import { Frame } from '../components/Frame'
import { Link } from 'react-router-dom'
import { Blue300 } from '../lib/colors'
import IconInput from '../components/IconInput'
import { MdOutlineEmail } from 'react-icons/md'
import { MessageBox } from '../components/MessageBox'

export const ContactPage = () => {
  const i18n = useI18n()
  const [generalError, setGeneralError] = useState<string>()
  const [, sendClientContact] = useClientContactSendMutation()
  const [sent, setSent] = useState(false)
  const auth = useContext(AuthContext)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      message: '',
    },
  })

  const doSend = useCallback(
    async (form: { email?: string; message: string }) => {
      const { error } = await sendClientContact(form)
      if (error) {
        setGeneralError(error.message)
        return
      }

      reset({
        message: '',
        email: '',
      })

      setSent(true)
    },
    [reset, sendClientContact]
  )

  return (
    <Frame
      leftClassName="w-[210px] hidden xl:block"
      rightClassName="w-[210px] hidden xl:block"
    >
      <div className="md:mx-10">
        <div className="text-blue-700 mb-5">
          <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
          {' > '}
          {i18n.t('screens.contact.breadcrumbs.contact')}
        </div>

        <h1 className="mb-8 mt-5 text-2xl md:text-3xl font-bold flex items-center gap-2.5">
          {i18n.t('screens.contact.title')}
        </h1>

        {!!generalError && <MessageBox>{generalError}</MessageBox>}
        {sent && (
          <>
            <MessageBox variant="success">
              {i18n.t('screens.contact.sent.messageBox')}
            </MessageBox>

            <div className="flex justify-center">
              <button
                className="font-md font-bold text-blue-700 my-10 underline"
                onClick={() => setSent(false)}
              >
                {i18n.t('screens.contact.sent.sendNext')}
              </button>
            </div>
          </>
        )}

        {!sent && (
          <>
            <div className="hl-panel p-5">
              {!auth.isLogged && (
                <div className="mb-4">
                  <IconInput
                    className="md:w-1/2"
                    leftIcon={<MdOutlineEmail size={24} color={Blue300} />}
                    {...register('email', {
                      required: i18n.t(
                        'screens.contact.form.email.rules.required'
                      ),
                      pattern: {
                        value: EmailRegex,
                        message: i18n.t(
                          'screens.contact.form.email.rules.pattern'
                        ),
                      },
                    })}
                    placeholder={i18n.t(
                      'screens.contact.form.email.placeholder'
                    )}
                  />
                  {errors.email && (
                    <p className="hl-input-error">{errors.email.message}</p>
                  )}
                </div>
              )}

              <div className="mb-4">
                <textarea
                  className="bg-blue-100 w-full text-lg rounded-2xl p-5 border-border border border-opacity-20 outline-none"
                  rows={5}
                  {...register('message', {
                    required: i18n.t(
                      'screens.contact.form.message.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.contact.form.message.placeholder'
                  )}
                />
                {errors.message && (
                  <p className="hl-input-error">{errors.message.message}</p>
                )}
              </div>

              <div className="flex justify-end gap-2.5">
                <button className="hl-button" onClick={handleSubmit(doSend)}>
                  {i18n.t('screens.contact.form.submit')}
                </button>
              </div>
            </div>
            {auth.isLogged && (
              <p className="font-md font-bold text-blue-700 my-10 text-center">
                {i18n.t('screens.contact.complaint.text')}{' '}
                <Link to="/compliant">
                  {i18n.t('screens.contact.complaint.link')}
                </Link>
              </p>
            )}
          </>
        )}
      </div>
    </Frame>
  )
}
