import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import { useClient } from 'urql'
import {
  GetTrainingDocument,
  GetTrainingQuery,
} from '../../generated/urql.anonymous'
import { BasketContext, isTrainingBasketItem } from '../../provider/basket'
import { useI18n } from '../../provider/i18n'
import { Link } from 'react-router-dom'
import { Disclosure, Transition } from '@headlessui/react'
import { MdKeyboardArrowRight, MdShoppingBasket } from 'react-icons/md'
import { Blue600 } from '../../lib/colors'
import Markdown from 'react-markdown'
import { MessageBox } from '../../components/MessageBox'
import {
  ConfirmDialog,
  ConfirmDialogType,
} from '../../components/ConfirmDialog'
import { guessStorageAddress } from '../../lib/storage'
import { AuthContext } from '../../provider/auth'

export function getStorageUrl(fileId: number, accessToken?: string) {
  return (
    guessStorageAddress() +
    `/files/${fileId}${accessToken ? `?accessToken=${accessToken}` : ''}`
  )
}

export const CatalogTrainingPage = () => {
  const params = useParams<{ id: string }>()
  const i18n = useI18n()
  const basket = useContext(BasketContext)
  const client = useClient()
  const auth = useContext(AuthContext)
  const [generalError, setGeneralError] = useState<string>()
  const [training, setTraining] = useState<GetTrainingQuery['training_by_pk']>()
  const id = parseInt(params.id || '0', 10)
  const confirmDialogRef = useRef<ConfirmDialogType>(null)
  const isAddedToBasket = useMemo(
    () =>
      basket.items
        .filter(isTrainingBasketItem)
        .find(({ training }) => training.id === id),
    [basket.items, id]
  )

  const fetchTraining = useCallback(() => {
    client
      .query(
        GetTrainingDocument,
        {
          id,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then(({ data }) => setTraining(data.training_by_pk))
      .catch(console.error)
  }, [client, id])

  useEffect(fetchTraining, [fetchTraining])

  const doAddToBasket = useCallback(() => {
    if (isAddedToBasket || !training) {
      return
    }

    basket.addItem({ training })
  }, [basket, isAddedToBasket, training])

  const doRemoveFromBasket = useCallback(() => {
    if (isAddedToBasket && training) {
      const basketItem = basket.items
        .filter(isTrainingBasketItem)
        .find((basketItem) => basketItem.training.id === id)
      if (basketItem) {
        basket.removeItem(basketItem)
      }
    }
  }, [basket, id, isAddedToBasket, training])

  return (
    <div className="mb-10">
      <div className="text-blue-700 mb-5">
        <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
        {' > '}
        <>
          <Link to={`/`}>{i18n.t('common.breadcrumbs.trainings')}</Link>
          {' > '}
        </>
        {training?.name || '-'}
      </div>

      {generalError && <MessageBox>{generalError}</MessageBox>}

      {training && (
        <>
          <div className="flex justify-between items-center mb-8 mt-5">
            <h1 className="text-2xl md:text-3xl font-bold leading-tight">
              {training.name}
            </h1>
          </div>
          {!!training.previewFileId && (
            <div className="flex justify-center">
              <div className="hl-panel overflow-hidden mb-8 max-w-3xl">
                <img
                  src={
                    guessStorageAddress() + '/files/' + training.previewFileId
                  }
                  alt={training.name || ''}
                />
              </div>
            </div>
          )}
          <div className="hl-panel">
            {!!training.videoFile && (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="bg-green-100 p-4 md:p-6 text-lg font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                      <>
                        {i18n.t('screens.catalog.training.video.label')}
                        <MdKeyboardArrowRight
                          className={open ? 'rotate-90 transform' : ''}
                          size={24}
                          color={Blue600}
                        />
                      </>
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform max-h-0"
                      enterTo="transform max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform max-h-[1000px]"
                      leaveTo="transform max-h-0"
                    >
                      <Disclosure.Panel className="p-4 md:p-6 border-b border-b-border border-opacity-20">
                        {!!auth.accessToken && (
                          <video
                            controls
                            src={getStorageUrl(
                              training.videoFile.id,
                              auth.accessToken
                            )}
                            className="w-full"
                          />
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            {!!training.description && (
              <Disclosure defaultOpen={!training.videoFile}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="p-4 md:p-6 text-lg font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                      <>
                        {i18n.t('screens.catalog.training.description.label')}
                        <MdKeyboardArrowRight
                          className={open ? 'rotate-90 transform' : ''}
                          size={24}
                          color={Blue600}
                        />
                      </>
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform max-h-0"
                      enterTo="transform max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform max-h-[1000px]"
                      leaveTo="transform max-h-0"
                    >
                      <Disclosure.Panel className="p-4 md:p-6 border-b border-b-border border-opacity-20">
                        <Markdown className="markdown">
                          {training.description}
                        </Markdown>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            {!training.videoFile && (
              <div className="bg-blue-200 rounded-b-2xl md:flex justify-end items-center p-5 md:py-12 md:px-8 gap-2.5 md:gap-5 grid grid-cols-2">
                <div className="flex gap-2.5 items-center justify-center md:justify-start">
                  <MdShoppingBasket size={24} color={Blue600} />
                  <p className="text-md md:text-lg font-bold">
                    {i18n.t('screens.catalog.test.price', {
                      price: training.price,
                    })}
                  </p>
                </div>
                <button
                  className={`hl-button ${
                    isAddedToBasket ? 'hl-gradient-red' : ''
                  }`}
                  onClick={
                    !isAddedToBasket ? doAddToBasket : doRemoveFromBasket
                  }
                >
                  {!isAddedToBasket
                    ? i18n.t('screens.catalog.training.basket.add')
                    : i18n.t('screens.catalog.training.basket.remove')}
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ConfirmDialog ref={confirmDialogRef} />
    </div>
  )
}
