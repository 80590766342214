import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { RegisterOptions, useForm } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import Input from './Input'
import { useI18n } from '../provider/i18n'

export type PromptModalType = {
  show: (
    args: {
      message: string
      onSuccess?: (value: string) => void
      onCancel?: () => void
      validate?: RegisterOptions<{ value: string }, 'value'>
      value?: string
    } & ({ type?: 'text' } | { type: 'datetime-local'; min?: string })
  ) => void
}

export const PromptModal = forwardRef<PromptModalType>((_, ref) => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<string>()
  const [initialValue, setInitialValue] = useState<string>()
  const [type, setType] = useState<'text' | 'datetime-local'>('text')
  const [min, setMin] = useState<string>()
  const [onSuccess, setOnSuccess] = useState<(value: string) => void>()
  const [onCancel, setOnCancel] = useState<() => void>()
  const [validate, setValidate] =
    useState<RegisterOptions<{ value: string }, 'value'>>()
  const i18n = useI18n()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      value: initialValue || '',
    },
  })

  useImperativeHandle(ref, () => ({
    show: ({ message, onSuccess, onCancel, validate, value, ...args }) => {
      setShow(true)
      setMessage(message)
      setOnSuccess(() => onSuccess)
      setOnCancel(() => onCancel)
      setValidate(validate)
      setInitialValue(value)
      setType(args.type || 'text')
      setMin(args.type === 'datetime-local' ? args.min : undefined)
      reset({ value })
    },
  }))

  const doSubmit = useCallback(
    ({ value }: { value: string }) => {
      if (onSuccess) {
        onSuccess(value)
      }
      setShow(false)
    },
    [setShow, onSuccess]
  )

  const doCancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    setShow(false)
  }, [setShow, onCancel])

  if (!show) return null

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        open={show}
        onClose={() => setShow(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <Dialog.Title className="text-lg mb-2.5 text-blue-700">
                  {message}
                </Dialog.Title>
                <div className="mb-10">
                  <Input
                    className="w-3/4"
                    {...register('value', validate)}
                    type={type}
                    min={min}
                  />
                  {errors.value && (
                    <p className="mt-1 text-red-700 text">
                      {errors.value.message}
                    </p>
                  )}
                </div>
                <div className="flex justify-end gap-2.5">
                  <button
                    className="hl-button"
                    onClick={handleSubmit(doSubmit)}
                  >
                    {i18n.t('common.ok')}
                  </button>
                  <button
                    className="hl-button hl-gradient-red"
                    onClick={doCancel}
                  >
                    {i18n.t('common.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
})
