import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  ClientComplaintSendAttachment,
  SendComplaintMutationVariables,
  useSendComplaintMutation,
} from '../generated/urql.client'
import { useI18n } from '../provider/i18n'
import { MessageBox } from '../components/MessageBox'
import { Link } from 'react-router-dom'
import IconInput from '../components/IconInput'
import { MdDelete, MdOutlineTitle, MdPerson } from 'react-icons/md'
import { Frame } from '../components/Frame'
import { Blue300, Green500, Red800 } from '../lib/colors'
import { FaPlus } from 'react-icons/fa6'

type FormField = Omit<SendComplaintMutationVariables, 'attachments'> & {
  attachments: (ClientComplaintSendAttachment & { id: string })[]
}

function createDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const ComplaintPage = () => {
  const i18n = useI18n()
  const [generalError, setGeneralError] = useState<string>()
  const [{ fetching: sendComplaintFetching }, sendComplaint] =
    useSendComplaintMutation()
  const [sent, setSent] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<FormField>({
    defaultValues: {
      name: '',
      subject: '',
      message: '',
      attachments: [],
    },
  })

  async function doSendComplaint(complaint: FormField) {
    setGeneralError(undefined)

    const { error } = await sendComplaint({
      ...complaint,
      attachments: complaint.attachments?.map(({ filename, dataUrl }) => ({
        filename,
        dataUrl,
      })),
    })
    if (error) {
      setGeneralError(error.message)
      return
    }

    reset()
    setSent(true)
  }

  return (
    <Frame
      leftClassName="w-[210px] hidden xl:block"
      rightClassName="w-[210px] hidden xl:block"
    >
      <div className="md:mx-10">
        <div className="text-blue-700 mb-5">
          <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
          {' > '}
          {i18n.t('screens.complaint.breadcrumbs.complaint')}
        </div>

        <h1 className="mb-8 mt-5 text-2xl md:text-3xl font-bold flex items-center gap-2.5">
          {i18n.t('screens.complaint.title')}
        </h1>

        {!!generalError && <MessageBox>{generalError}</MessageBox>}
        {sent && (
          <MessageBox variant="success">
            {i18n.t('screens.complaint.sentMessage')}
          </MessageBox>
        )}

        <div className="hl-panel p-5">
          <div className="mb-4">
            <IconInput
              className="md:w-1/2"
              leftIcon={<MdPerson size={24} color={Blue300} />}
              {...register('name', {
                required: i18n.t('screens.complaint.form.name.rules.required'),
              })}
              placeholder={i18n.t('screens.complaint.form.name.placeholder')}
            />
            {errors.name && (
              <p className="hl-input-error">{errors.name.message}</p>
            )}
          </div>

          <div className="mb-4">
            <IconInput
              className="md:w-3/4"
              leftIcon={<MdOutlineTitle size={24} color={Blue300} />}
              {...register('subject', {
                required: i18n.t(
                  'screens.complaint.form.subject.rules.required'
                ),
              })}
              placeholder={i18n.t('screens.complaint.form.subject.placeholder')}
            />
            {errors.subject && (
              <p className="hl-input-error">{errors.subject.message}</p>
            )}
          </div>

          <div className="mb-4">
            <textarea
              className="bg-blue-100 w-full text-lg rounded-2xl p-5 border-border border border-opacity-20 outline-none"
              rows={5}
              {...register('message', {
                required: i18n.t(
                  'screens.complaint.form.message.rules.required'
                ),
              })}
              placeholder={i18n.t('screens.complaint.form.message.placeholder')}
            />
            {errors.message && (
              <p className="hl-input-error">{errors.message.message}</p>
            )}
          </div>

          <Controller
            control={control}
            name="attachments"
            render={({ field: { onChange, value } }) => (
              <div>
                <p className="my-4 text-blue-700">Attachments</p>
                <ul className="flex gap-2.5">
                  {value.map((attachment) => (
                    <li
                      key={attachment.id}
                      className="flex flex-col items-center"
                    >
                      <img
                        src={attachment.dataUrl}
                        className="w-16 h-16 rounded border border-border border-opacity-20 shadow-md"
                      />
                      <button
                        onClick={() =>
                          onChange(value.filter((a) => a.id !== attachment.id))
                        }
                      >
                        <MdDelete size={20} color={Red800} className="mt-2" />
                      </button>
                    </li>
                  ))}

                  <li className="w-8 h-16 flex justify-center items-center">
                    <input
                      type="file"
                      id="file-input"
                      className="hidden"
                      multiple
                      accept="image/*"
                      onChange={async (event) => {
                        if (event.target.files) {
                          const attachments: Array<
                            ClientComplaintSendAttachment & { id: string }
                          > = []
                          for (const file of Array.from(event.target.files)) {
                            if (
                              attachments.find(({ id }) => file.name === id)
                            ) {
                              continue
                            }

                            attachments.push({
                              id: file.name,
                              filename: file.name,
                              dataUrl: await createDataURL(file),
                            })
                          }

                          onChange([...value, ...attachments])
                        }
                      }}
                    />
                    <button
                      onClick={() =>
                        document.getElementById(`file-input`)?.click()
                      }
                    >
                      <FaPlus size={24} color={Green500} />
                    </button>
                  </li>
                </ul>
              </div>
            )}
          />

          <div className="flex justify-end gap-2.5">
            <button
              className="hl-button"
              onClick={handleSubmit(doSendComplaint)}
              disabled={sendComplaintFetching}
            >
              {i18n.t('screens.complaint.form.submit')}
            </button>
          </div>
        </div>
      </div>
    </Frame>
  )
}
