import { Link } from 'react-router-dom'
import React from 'react'
import { useI18n } from '../provider/i18n'
import { Consultation } from '../generated/urql.client'

const AddButtonSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <circle cx="18" cy="18" r="18" fill="#71C09D" />
    <rect x="16" y="9" width="4" height="18" fill="white" />
    <rect
      x="9"
      y="20"
      width="4"
      height="18"
      transform="rotate(-90 9 20)"
      fill="white"
    />
  </svg>
)

export const ConsultationItem: React.FC<{
  consultation: Pick<
    Consultation,
    'id' | 'svgIcon' | 'name' | 'description' | 'price'
  >
  onConsultationAddClick?: () => void
  showAddButton?: boolean
}> = ({ consultation, onConsultationAddClick, showAddButton }) => {
  const i18n = useI18n()

  return (
    <>
      <div
        key={consultation.id}
        className="hl-panel md:h-28 flex overflow-hidden"
      >
        <div
          className={`p-4 bg-blue-200 flex min-w-28 items-center justify-center`}
        >
          {!!consultation.svgIcon && (
            <img
              className="w-20 h-20 max-w-none"
              alt={consultation.name || ''}
              src={`data:image/svg+xml,${encodeURIComponent(
                consultation.svgIcon
              )}`}
            />
          )}
        </div>
        <div className="pl-5 py-4 flex-1">
          <Link
            to={`/consultation/${consultation.id}`}
            className="no-underline text-blue-900 hover:text-blue-700 flex flex-col h-full"
          >
            <p className="font-bold mb-2 leading-tight">{consultation.name}</p>
            <p className="text-xs font-normal text-justify hidden md:block xl:hidden">
              {(consultation.description || '').length > 150
                ? consultation.description?.substring(0, 150) + '...'
                : consultation.description}
            </p>
            <p className="text-xs font-normal text-justify hidden xl:block">
              {(consultation.description || '').length > 280
                ? consultation.description?.substring(0, 280) + '...'
                : consultation.description}
            </p>
            <p className="font-bold text-xs md:hidden mt-auto">
              {i18n.t('components.consultationItem.price', {
                price: consultation.price,
              })}
            </p>
          </Link>
        </div>
        <div className="basis-24 flex-none items-center justify-end hidden md:flex ml-4">
          <p className="font-bold whitespace-nowrap">
            {i18n.t('components.consultationItem.price', {
              price: consultation.price,
            })}
          </p>
        </div>
        <div className="flex items-center mx-4 xl:ml-5 zl:mr-7">
          <button
            onClick={onConsultationAddClick}
            disabled={!showAddButton}
            className={showAddButton ? '' : 'opacity-20'}
          >
            {AddButtonSvg}
          </button>
        </div>
      </div>
    </>
  )
}
