import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react'
import Input from './Input'

const IconInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    leftIcon?: ReactNode
    rightIcon?: ReactNode
  }
>(({ leftIcon, rightIcon, ...props }, ref) => {
  return (
    <div className={`${props.className || ''} relative`}>
      {leftIcon && (
        <div className="absolute h-full flex items-center justify-center px-2">
          {leftIcon}
        </div>
      )}
      <Input
        {...props}
        ref={ref}
        className={`w-full ${leftIcon ? '!pl-10' : ''} ${
          rightIcon ? '!pr-10' : ''
        }`}
      />
      {rightIcon && (
        <div className="absolute right-0 top-0 h-full flex items-center justify-center px-2">
          {rightIcon}
        </div>
      )}
    </div>
  )
})

export default IconInput
