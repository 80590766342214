import React, { useCallback } from 'react'
import { useI18n } from '../provider/i18n'
import { findLocation, Point } from '../lib/geography'
import { Blue300 } from '../lib/colors'
import { FaLocationDot } from 'react-icons/fa6'
import { useForm } from 'react-hook-form'
import IconInput from './IconInput'
import { FaCity, FaEnvelope } from 'react-icons/fa'
import { debounce } from 'lodash'

export type Address = {
  address: string
  postalCode: string
  city: string
  location: Point
}

export const AddressSelect: React.FC<{
  onChange: (address?: Address) => void
  value?: {
    address?: string | null
    postalCode?: string | null
    city?: string | null
    location?: Point | null
  }
  disabled?: boolean
}> = ({ onChange, disabled, value }) => {
  const i18n = useI18n()
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<{
    address: string
    postalCode: string
    city: string
    location: Point
  }>({
    defaultValues: {
      address: value?.address || '',
      postalCode: value?.postalCode || '',
      city: value?.city || '',
      location: value?.location || { type: 'Point', coordinates: [0, 0] },
    },
    mode: 'all',
  })

  const resolveLocation = useCallback(
    async ({ address, postalCode, city }: Omit<Address, 'location'>) => {
      if (address && postalCode && city) {
        const location = await findLocation({
          address,
          postalCode,
          city,
        })

        if (location) {
          setValue('location', location)
          onChange({ address, city, postalCode, location })
          return true
        } else {
          onChange(undefined)
          return false
        }
      }

      onChange(undefined)
      return true
    },
    [onChange, setValue]
  )

  return (
    <div className="relative">
      <div className="my-4">
        <IconInput
          leftIcon={<FaLocationDot size={24} color={Blue300} />}
          disabled={disabled}
          {...register('address', {
            //required: i18n.t('components.addressSelect.address.rules.required'),
            validate: debounce(async (address, { postalCode, city }) => {
              if (!(await resolveLocation({ address, postalCode, city }))) {
                return i18n.t('components.addressSelect.address.rules.invalid')
              }

              return true
            }, 500),
          })}
          placeholder={i18n.t(
            'components.addressSelect.address.rules.placeholder'
          )}
        />
        {errors.address && (
          <p className="hl-input-error">{errors.address.message}</p>
        )}
      </div>

      <div className="my-4 w-4/5">
        <IconInput
          disabled={disabled}
          leftIcon={<FaEnvelope size={24} color={Blue300} />}
          {...register('postalCode', {
            /* required: i18n.t(
              'components.addressSelect.postalCode.rules.required'
            ),*/
            pattern: {
              value: /^[0-9]{2}-[0-9]{3}$/,
              message: i18n.t(
                'components.addressSelect.postalCode.rules.pattern'
              ),
            },
            validate: debounce(async (postalCode, { address, city }) => {
              if (!(await resolveLocation({ address, postalCode, city }))) {
                return i18n.t('components.addressSelect.address.rules.invalid')
              }

              return true
            }, 500),
          })}
          placeholder={i18n.t(
            'components.addressSelect.postalCode.rules.placeholder'
          )}
        />
        {errors.postalCode && (
          <p className="hl-input-error">{errors.postalCode.message}</p>
        )}
      </div>

      <div className="my-4">
        <IconInput
          leftIcon={<FaCity size={24} color={Blue300} />}
          disabled={disabled}
          {...register('city', {
            // required: i18n.t('components.addressSelect.city.rules.required'),
            validate: debounce(async (city, { address, postalCode }) => {
              if (!(await resolveLocation({ address, postalCode, city }))) {
                return i18n.t('components.addressSelect.address.rules.invalid')
              }

              return true
            }, 500),
          })}
          placeholder={i18n.t(
            'components.addressSelect.city.rules.placeholder'
          )}
        />
        {errors.city && <p className="hl-input-error">{errors.city.message}</p>}
      </div>
    </div>
  )
}
