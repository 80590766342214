import { Link, useLocation } from 'react-router-dom'
import { laboratoryTestsToPriceString } from '../lib/test'
import React from 'react'
import { Test } from '../generated/urql.anonymous'
import { useI18n } from '../provider/i18n'

const Variant = {
  green: {
    filter: 'hue-rotate(280deg) brightness(0.95)',
    bgColorClass: 'bg-green-100',
  },
  blue: { filter: '', bgColorClass: 'bg-blue-200' },
}

const AddButtonSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <circle cx="18" cy="18" r="18" fill="#71C09D" />
    <rect x="16" y="9" width="4" height="18" fill="white" />
    <rect
      x="9"
      y="20"
      width="4"
      height="18"
      transform="rotate(-90 9 20)"
      fill="white"
    />
  </svg>
)

export const TestItem: React.FC<{
  test: Pick<Test, 'id' | 'svgIcon' | 'shortName' | 'name' | 'description'> & {
    availableLaboratoryTests?: Array<{
      price: number
    }> | null
  }
  onTestAddClick?: () => void
  variant?: 'green' | 'blue'
  showAddButton?: boolean
}> = ({ test, onTestAddClick, variant = 'blue', showAddButton }) => {
  const location = useLocation()
  const i18n = useI18n()

  return (
    <>
      <div key={test.id} className="hl-panel md:h-28 flex overflow-hidden">
        <div
          className={`p-4 ${Variant[variant].bgColorClass} flex min-w-28 items-center justify-center`}
        >
          {!!test.svgIcon && (
            <img
              className="w-20 h-20 max-w-none"
              alt={test.name || test.shortName || ''}
              style={{
                filter: Variant[variant].filter,
              }}
              src={`data:image/svg+xml,${encodeURIComponent(
                test.svgIcon.replace(/fill="#ffffff"/gi, 'fill="transparent"')
              )}`}
            />
          )}
        </div>
        <div className="pl-5 py-4 flex-1">
          <Link
            to={`/test/${test.id}`}
            state={
              location.pathname.match(/^\/tag\//)
                ? {
                    tagId: decodeURIComponent(
                      location.pathname.replace(/^\/tag\//, '')
                    ),
                  }
                : undefined
            }
            className="no-underline text-blue-900 hover:text-blue-700 flex flex-col h-full"
          >
            <p className="font-bold mb-2 leading-tight">
              {test.shortName || test.name}
            </p>
            <p className="text-xs font-normal text-justify hidden md:block xl:hidden">
              {(test.description || '').length > 150
                ? test.description?.substring(0, 150) + '...'
                : test.description}
            </p>
            <p className="text-xs font-normal text-justify hidden xl:block">
              {(test.description || '').length > 280
                ? test.description?.substring(0, 280) + '...'
                : test.description}
            </p>
            <p className="font-bold text-xs md:hidden mt-auto">
              {i18n.t('components.testItem.price', {
                price: test.availableLaboratoryTests
                  ? laboratoryTestsToPriceString(test.availableLaboratoryTests)
                  : '-',
              })}
            </p>
          </Link>
        </div>
        <div className="basis-24 flex-none items-center justify-end hidden md:flex ml-4">
          <p className="font-bold whitespace-nowrap">
            {i18n.t('components.testItem.price', {
              price: test.availableLaboratoryTests
                ? laboratoryTestsToPriceString(test.availableLaboratoryTests)
                : '-',
            })}
          </p>
        </div>
        <div className="flex items-center mx-4 xl:ml-5 zl:mr-7">
          <button
            onClick={onTestAddClick}
            disabled={!showAddButton}
            className={showAddButton ? '' : 'opacity-20'}
          >
            {AddButtonSvg}
          </button>
        </div>
      </div>
    </>
  )
}
