import React, { useContext } from 'react'
import { AuthContext } from '../provider/auth'
import { Outlet, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router'

export const ProtectedRoute = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()

  if (!auth.isLogged) {
    return <Navigate to={`/login`} state={{ backTo: location.pathname }} />
  }

  return <Outlet />
}
