import React, { FormEvent, useCallback, useContext, useState } from "react";
import { useNavigate } from 'react-router'
import { BasketContext } from '../provider/basket'
import { WelcomeFrame } from '../components/WelcomeFrame'
import PatientLogo from '../assets/patient-logo.svg'
import AddressSelectorIcon from '../assets/address-selector.svg'
import { Address, AddressSelect } from '../components/AddressSelect'
import { useI18n } from '../provider/i18n'
import { Link } from 'react-router-dom'
import {
  useGetProfilesSubscription,
  useUpdateProfileMutation,
} from '../generated/urql.client'
import { isProfileAddressComplete } from '../lib/profile'

export const AddressSelectorPage: React.FC = () => {
  const basket = useContext(BasketContext)
  const i18n = useI18n()
  const navigate = useNavigate()
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const [, updateProfile] = useUpdateProfileMutation()
  const [address, setAddress] = useState<Address>()

  const doSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      try {
        if (!address) {
          basket.setOverrideAddress(undefined)
        } else if (
          profilesData?.client_profile.length === 1 &&
          !isProfileAddressComplete(profilesData.client_profile[0])
        ) {
          const profile = {
            id: profilesData.client_profile[0].id,
            firstName: profilesData.client_profile[0].firstName,
            lastName: profilesData.client_profile[0].lastName,
            phoneNumber: profilesData.client_profile[0].phoneNumber,
            identity: profilesData.client_profile[0].identity,
            gender: profilesData.client_profile[0].gender,
            ...address,
          }

          await updateProfile(profile)
          basket.setProfile(profile)
        } else {
          basket.setOverrideAddress(address)
        }

        navigate('/')
      } catch (e) {
        console.error(e)
      }
    },
    [address, profilesData?.client_profile, navigate, basket, updateProfile]
  )

  return (
    <WelcomeFrame className="flex flex-col items-center justify-around pt-4">
      <div className="flex-2 flex flex-col items-center">
        <div className="my-5">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <p className="text-center text-xl mb-5 whitespace-pre">
          {i18n.t('screens.addressSelector.instructions')}
        </p>
        <div className="mb-5">
          <img src={AddressSelectorIcon} alt="Address selector icon" />
        </div>
      </div>
      <div className="flex-2 my-0 mx-auto w-[340px] bg-blue-100 bg-opacity-50 flex flex-col items-center">
        <form onSubmit={doSubmit}>
          <div className="mx-4">
            <p>{i18n.t('screens.addressSelector.pickUpAddress')}</p>
            <div className="my-4">
              <AddressSelect onChange={setAddress} value={address} />
            </div>
          </div>
          <div className="my-4">
            <button className="hl-button-lg" type="submit" disabled={!address}>
              {i18n.t('screens.addressSelector.submitButton')}
            </button>
          </div>
        </form>
        <p className="m-4 text-center">
          <Link to="/login">
            {i18n.t('screens.addressSelector.links.login')}
          </Link>{' '}
          |{' '}
          <Link to={`/register`}>
            {i18n.t('screens.addressSelector.links.register')}
          </Link>
        </p>
      </div>
      <div className="flex-1" />
    </WelcomeFrame>
  )
}
