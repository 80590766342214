import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { GoogleRecaptchaSiteKey } from '../const'
import { usePasswordResetInitMutation } from '../generated/urql.anonymous'
import { useRecaptcha } from '../lib/recaptcha'
import { useI18n } from '../provider/i18n'
import { WelcomeFrame } from '../components/WelcomeFrame'
import PatientLogo from '../assets/patient-logo.svg'
import IconInput from '../components/IconInput'
import PersonIcon from '../assets/person.svg'
import { MessageBox } from '../components/MessageBox'
import { Link } from 'react-router-dom'

export const ResetPasswordInitPage = () => {
  const [{ fetching }, passwordResetInit] = usePasswordResetInitMutation()
  const [generalError, setGeneralError] = useState<string>()
  const i18n = useI18n()
  const getRecaptchaToken = useRecaptcha()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  async function doPasswordResetInit(params: { email: string }) {
    setGeneralError(undefined)

    const recaptchaToken = await getRecaptchaToken({
      action: 'password_reset/init',
    })

    if (!recaptchaToken) {
      setGeneralError(i18n.t('screens.resetPasswordInit.error.recaptcha-error'))
      return
    }

    const { data, error } = await passwordResetInit({
      ...params,
      recaptchaToken,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    if (data?.client_password_reset_init) {
      navigate(`/resetpassword/finish`, {
        state: {
          email: params.email,
        },
      })
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${GoogleRecaptchaSiteKey}`}
        ></script>
      </Helmet>
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4 px-5">
        <div className="flex-1 flex flex-col justify-center items-center">
          <div className="my-5">
            <img src={PatientLogo} alt="HomeLab Patient app logo" />
          </div>
          <p className="text-center">
            {i18n.t('screens.resetPasswordInit.form.instructions')}
          </p>
        </div>
        <div className="flex-1 my-0 mx-auto w-[320px] bg-blue-100 bg-opacity-50">
          {!!generalError && <MessageBox>{generalError}</MessageBox>}
          <form onSubmit={handleSubmit(doPasswordResetInit)}>
            <div className="mx-4">
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={PersonIcon}
                      width={24}
                      height={24}
                      alt="Person icon"
                    />
                  }
                  {...register('email', {
                    required: i18n.t(
                      'screens.resetPasswordInit.form.email.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.resetPasswordInit.form.email.placeholder'
                  )}
                  type="text"
                />
                {errors.email && (
                  <p className="hl-input-error">{errors.email.message}</p>
                )}
              </div>
            </div>
            <div className="my-4">
              <button
                className="hl-button-lg"
                type="submit"
                disabled={fetching}
              >
                {i18n.t('screens.resetPasswordInit.form.submit.button')}
              </button>
            </div>
          </form>
          <p className="m-4 text-center">
            <Link to="/login">
              {i18n.t('screens.resetPasswordInit.links.login')}
            </Link>{' '}
            |{' '}
            <Link to={`/register`}>
              {i18n.t('screens.resetPasswordInit.links.register')}
            </Link>
          </p>
        </div>
      </WelcomeFrame>
    </>
  )
}
