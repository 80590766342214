import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { GoogleRecaptchaSiteKey } from '../const'
import { useLoginMutation } from '../generated/urql.anonymous'
import { useRecaptcha } from '../lib/recaptcha'
import { AuthContext } from '../provider/auth'
import { useI18n } from '../provider/i18n'
import PatientLogo from '../assets/patient-logo.svg'
import { WelcomeFrame } from '../components/WelcomeFrame'
import IconInput from '../components/IconInput'
import { LuFormInput } from 'react-icons/lu'
import { Blue300 } from '../lib/colors'
import PersonIcon from '../assets/person.svg'
import LockIcon from '../assets/lock.svg'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useLocation, useSearchParams } from 'react-router-dom'

export const LoginRecoverPage = () => {
  const { setAccessToken } = useContext(AuthContext)
  const getRecaptchaToken = useRecaptcha()
  const i18n = useI18n()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ fetching }, login] = useLoginMutation()
  const [generalError, setGeneralError] = useState<string>()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailCode: searchParams.get('code') || '',
      email:
        searchParams.get('email') || (location.state?.email as string) || '',
      password: '',
    },
  })
  const [revealPassword, setRevealPassword] = useState(false)

  useEffect(() => {
    setValue('emailCode', searchParams.get('code') || '')
    setValue('email', searchParams.get('email') || location.state?.email || '')
  }, [setValue, searchParams])

  const doLogin = useCallback(
    async ({
      email,
      password,
      emailCode,
    }: {
      email: string
      password: string
      emailCode: string
    }) => {
      setGeneralError(undefined)

      const recaptchaToken = await getRecaptchaToken({ action: 'login' })

      if (!recaptchaToken) {
        setGeneralError(i18n.t('screens.login.loginError.recaptcha-error'))
        return
      }

      const { data, error } = await login({
        email,
        password,
        emailCode,
        recaptchaToken,
      })

      if (error) {
        switch (error.graphQLErrors[0]?.extensions.code) {
          case 'invalid-credentials':
            setGeneralError(
              i18n.t('screens.loginRecover.loginError.invalid-credentials')
            )
            return
          case 'unconfirmed-email':
            navigate(`/register/finish`, {
              state: {
                email,
                backTo: `/login/recover?email=${encodeURIComponent(
                  email
                )}&code=${encodeURIComponent(emailCode)}`,
              },
            })
            return
          case 'locked':
            setGeneralError(i18n.t('screens.loginRecover.loginError.locked'))
            return
          case 'missing-sms-code':
            navigate(`/login`, {
              state: { email, showSMSCode: true },
            })
            return
          case 'invalid-recaptcha':
            setGeneralError(
              i18n.t('screens.loginRecover.loginError.invalid-recaptcha')
            )
            return
          default:
            setGeneralError(error.message)
        }
        return
      }

      if (data?.client_login) {
        await setAccessToken(data.client_login.accessToken)

        if (location.state?.backTo) {
          navigate(location.state.backTo)
        } else {
          navigate('/')
        }
      }
    },
    [
      getRecaptchaToken,
      i18n,
      login,
      navigate,
      location.state?.backTo,
      setAccessToken,
    ]
  )

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${GoogleRecaptchaSiteKey}`}
        ></script>
      </Helmet>
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4">
        <div className="flex-1 flex flex-col justify-center items-center">
          <div className="my-5">
            <img src={PatientLogo} alt="HomeLab Patient app logo" />
          </div>
          <p>{i18n.t('screens.loginRecover.instructionText')}</p>
        </div>
        <div className="flex-1 my-0 mx-auto w-[320px] bg-blue-100 bg-opacity-50">
          {!!generalError && <p>{generalError}</p>}
          <form onSubmit={handleSubmit(doLogin)}>
            <div className="mx-4">
              <div className="my-4">
                <IconInput
                  leftIcon={<LuFormInput size={24} color={Blue300} />}
                  {...register('emailCode', {
                    required: i18n.t(
                      'screens.loginRecover.form.emailCode.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.loginRecover.form.emailCode.placeholder'
                  )}
                />
                {errors.emailCode && (
                  <p className="hl-input-error">{errors.emailCode.message}</p>
                )}
              </div>

              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={PersonIcon}
                      width={24}
                      height={24}
                      alt="Person icon"
                    />
                  }
                  {...register('email', {
                    required: i18n.t(
                      'screens.loginRecover.form.login.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.loginRecover.form.login.placeholder'
                  )}
                  type="text"
                />
                {errors.email && (
                  <p className="hl-input-error">{errors.email.message}</p>
                )}
              </div>

              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={LockIcon}
                      width={24}
                      height={24}
                      alt="Lock icon"
                    />
                  }
                  rightIcon={
                    revealPassword ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    )
                  }
                  {...register('password', {
                    required: i18n.t(
                      'screens.loginRecover.form.password.rules.required'
                    ),
                  })}
                  type="password"
                  placeholder={i18n.t(
                    'screens.loginRecover.form.password.placeholder'
                  )}
                />
                {errors.password && (
                  <p className="hl-input-error">{errors.password.message}</p>
                )}
              </div>
            </div>

            <div className="my-4">
              <button
                className="hl-button-lg"
                type="submit"
                disabled={fetching}
              >
                {i18n.t('screens.loginRecover.form.submit')}
              </button>
            </div>
          </form>
        </div>
      </WelcomeFrame>
    </>
  )
}
