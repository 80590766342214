import React from 'react'
import AppBg from '../assets/app-bg.svg'
import CarBg from '../assets/car-bg.svg'
import HomeLabLogo from '../assets/homelab-logo.svg'

export const WelcomeFrame: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div
      className={`min-h-screen w-screen hl-strip ${
        className || ''
      } pb-40 hl-welcome-background`}
      style={{
        backgroundImage: `url(${HomeLabLogo}), url(${AppBg}), url(${CarBg})`,
      }}
    >
      {children}
    </div>
  )
}
