import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useI18n } from '../provider/i18n'

export type ConfirmDialogType = {
  show: (args: {
    title?: string
    message: string
    successText?: string
    cancelText?: string
    onSuccess?: () => void
    onCancel?: () => void
  }) => void
}

export const ConfirmDialog = forwardRef<ConfirmDialogType>((_, ref) => {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [successText, setSuccessText] = useState<string>()
  const [cancelText, setCancelText] = useState<string>()
  const [onSuccess, setOnSuccess] = useState<() => void>()
  const [onCancel, setOnCancel] = useState<() => void>()
  const i18n = useI18n()

  useImperativeHandle(ref, () => ({
    show: ({
      title,
      successText,
      cancelText,
      message,
      onSuccess,
      onCancel,
    }) => {
      setShow(true)
      setTitle(title)
      setMessage(message)
      setSuccessText(successText || i18n.t('common.yes'))
      setCancelText(cancelText || i18n.t('common.cancel'))
      setOnSuccess(() => onSuccess)
      setOnCancel(() => onCancel)
    },
  }))

  const doSubmit = useCallback(() => {
    if (onSuccess) {
      onSuccess()
    }
    setShow(false)
  }, [setShow, onSuccess])

  const doCancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    setShow(false)
  }, [setShow, onCancel])

  if (!show) return null

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        open={show}
        onClose={() => setShow(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {!!title && (
                  <Dialog.Title className="font-bold text-lg mb-2.5 text-blue-700">
                    {title}
                  </Dialog.Title>
                )}
                <Dialog.Description className="text-base mb-5 whitespace-pre-wrap">
                  {message}
                </Dialog.Description>
                <div className="flex justify-end gap-2.5">
                  <button className="hl-button" onClick={doSubmit}>
                    {successText}
                  </button>
                  <button
                    className="hl-button hl-gradient-red"
                    onClick={doCancel}
                  >
                    {cancelText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
})
