export const GoogleRecaptchaSiteKey = '6Lfw6CcpAAAAAGDH4a5PqNsHKEyK0H86VM9-UPfk'
if (!process.env.REACT_APP_GOOGLE_API_KEY)
  throw new Error(
    `You need to provide REACT_APP_GOOGLE_API_KEY environment variable`
  )
export const GoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
export const BloodlabInterpretationLink =
  'https://bloodlab.pl/interpretacja?pid=homelab'
export const HolterDoDomuLink = 'https://holterdodomu.pl/?pid=homelab'

export const P24PaymentSandbox =
  process.env.REACT_APP_P24_PAYMENT_SANDBOX === 'true'
if (!process.env.REACT_APP_P24_TRANSACTION_PANEL_URL)
  throw new Error(
    `You need to provide P24_TRANSACTION_PANEL_URL environment variable`
  )
export const P24TransactionPanelUrl =
  process.env.REACT_APP_P24_TRANSACTION_PANEL_URL

export const MobileAppDownloadLink = 'https://onelink.to/374g77'
export const MobileAppiOSDownloadLink =
  'https://apps.apple.com/us/app/homelab/id1633329234'
export const MobileAppAndroidDownloadLink =
  'https://play.google.com/store/apps/details?id=com.homelab24.clientapp&hl=pl&gl=US'
export const FacebookUrl = 'https://www.facebook.com/HomeLab.pobrania.w.domu'
export const InstagramUrl =
  'https://www.instagram.com/homelab_pobrania_krwi_w_domu/'
