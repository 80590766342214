import 'moment/locale/pl'
import 'moment/locale/en-gb'
import 'moment/locale/uk'
import { GraphQLError } from 'graphql/index'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import {
  AddressSelectorModal,
  AddressSelectorModalType,
} from '../components/AddressSelectorModal'
import { PromptModal, PromptModalType } from '../components/PromptModal'
import { useGetOrderCostQuery } from '../generated/urql.anonymous'
import {
  GetProfilesSubscription,
  useAddOrderMutation,
  useVerifyKdrMutation,
} from '../generated/urql.client'
import { getP24TransactionPanelUrl } from '../lib/p24'
import { laboratoryTestsToPriceString } from '../lib/test'
import * as Time from '../lib/time'
import { AuthContext } from '../provider/auth'
import {
  BasketContext,
  ConsultationBasketItem,
  isConsultationBasketItem,
  isTestBasketItem,
  isTrainingBasketItem,
  TestBasketItem,
} from '../provider/basket'
import { useI18n } from '../provider/i18n'
import { Frame } from '../components/Frame'
import { Link } from 'react-router-dom'
import {
  MdCardGiftcard,
  MdClose,
  MdEdit,
  MdFamilyRestroom,
  MdInfoOutline,
  MdKeyboardArrowRight,
  MdLocationPin,
  MdMasks,
  MdOutlineChatBubbleOutline,
  MdRemoveShoppingCart,
  MdShoppingBasket,
} from 'react-icons/md'
import { Blue300, Blue600, Green600, Red800 } from '../lib/colors'
import { BsCalendarHeartFill } from 'react-icons/bs'
import Switch from 'react-switch'
import { RegulationLinks } from '../lib/regulations'
import { TailSpin } from 'react-loader-spinner'
import ProfileSelect from '../components/ProfileSelect'
import { ConfirmDialog, ConfirmDialogType } from '../components/ConfirmDialog'
import { MessageBox } from '../components/MessageBox'
import {
  isProfileGenderComplete,
  isProfileIdentityComplete,
} from '../lib/profile'

type BasketForm = {
  collectionDate: string
  clientNotes: string
  clientQuarantine: boolean
  consent1: boolean
  consent2: boolean
  consent3: boolean
}

type TestOpeningHours = [number, Time.Interval | null]

function isOutsideOpeningHoursError(
  error: GraphQLError
): error is GraphQLError & {
  extensions: {
    code: 'outside-opening-hours'
    testsOpeningHours: TestOpeningHours[]
  }
} {
  return error.extensions?.code === 'outside-opening-hours'
}

function isConsultationOutsideSlotError(
  error: GraphQLError
): error is GraphQLError & {
  extensions: {
    code: 'consultation-outside-slot'
    consultationId: number
    prevSlot?: {
      dateFrom: string
      dateTo: string
    }
    nextSlot?: {
      dateFrom: string
      dateTo: string
    }
  }
} {
  return error.extensions?.code === 'consultation-outside-slot'
}

function isTestsNotAvailableInLocationError(
  error: GraphQLError
): error is GraphQLError & {
  extensions: {
    code: 'tests-not-available-in-location'
    testIds: number[]
  }
} {
  return error.extensions?.code === 'tests-not-available-in-location'
}

export const BasketPage = () => {
  const i18n = useI18n()
  const [generalError, setGeneralError] = useState<string>()
  const [, addOrder] = useAddOrderMutation()
  const basket = useContext(BasketContext)
  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<BasketForm>({
    defaultValues: {
      collectionDate: undefined,
      clientNotes: undefined,
      clientQuarantine: false,
      consent1: false,
      consent2: false,
      consent3: false,
    },
  })
  const collectionDate = watch('collectionDate')
  const auth = useContext(AuthContext)

  const [
    { data: orderCostData, error: orderCostError, fetching: orderCostFetching },
    refreshOrderCost,
  ] = useGetOrderCostQuery({
    variables: {
      consultations: basket.items
        .filter(isConsultationBasketItem)
        .map(({ consultation, preferredDate, profile }) => ({
          consultationId: consultation.id,
          preferredDate: preferredDate,
          profile: {
            firstName: profile?.firstName || '-',
            lastName: profile?.lastName || '-',
            identity: profile?.identity || '-',
            gender: profile?.gender || '-',
          },
        })),

      trainings: basket.items
        .filter(isTrainingBasketItem)
        .map(({ training }) => ({
          trainingId: training.id,
        })),

      collections: basket.items.filter(isTestBasketItem).length
        ? [
            {
              location: basket.address?.location?.coordinates || [0, 0],
              tests: basket.items
                .filter(isTestBasketItem)
                .map(({ test, profile }) => ({
                  testId: test.id,
                  profile: {
                    firstName: profile?.firstName || '-',
                    lastName: profile?.lastName || '-',
                    identity: profile?.identity || '-',
                    gender: profile?.gender || '-',
                  },
                })),
              collectionDate:
                collectionDate && new Date(collectionDate).toISOString(),
            },
          ]
        : [],

      discountCodes: basket.discountCodes,
    },
  })
  const addressSelectorModalRef = useRef<AddressSelectorModalType>(null)
  const navigate = useNavigate()
  const promptModalRef = useRef<PromptModalType>(null)
  const confirmDialogRef = useRef<ConfirmDialogType>(null)
  const [, verifyKDR] = useVerifyKdrMutation()

  moment.locale(i18n.locale)

  const orderCost = orderCostData?.client_order_cost
  const isProfileComplete = !!basket.profile?.address
  const allOrderItemsAssignedToProfiles = basket.items
    .filter(
      (basketItem): basketItem is TestBasketItem | ConsultationBasketItem =>
        isTestBasketItem(basketItem) || isConsultationBasketItem(basketItem)
    )
    .every((item) => !!item.profile)
  const allProfilesComplete = useMemo(
    () =>
      basket.items
        .filter(
          (basketItem): basketItem is TestBasketItem | ConsultationBasketItem =>
            isTestBasketItem(basketItem) || isConsultationBasketItem(basketItem)
        )
        .every(
          ({ profile }) =>
            profile &&
            isProfileIdentityComplete(profile) &&
            isProfileGenderComplete(profile)
        ),
    [basket.items]
  )
  const isPayAndOrderDisabled =
    !isProfileComplete ||
    !allProfilesComplete ||
    !orderCost ||
    orderCostFetching ||
    !allOrderItemsAssignedToProfiles

  const scrollToFirstError = useCallback(
    (errors: Omit<FieldErrors<BasketForm>, 'root'>) => {
      const errorValues = Object.values(errors)
      if (errorValues.length > 0) {
        // @ts-ignore
        errorValues[0]?.ref?.focus()
      }
    },
    []
  )

  useEffect(refreshOrderCost, [
    basket.address,
    basket.items,
    basket.discountCodes,
    collectionDate,
    refreshOrderCost,
  ])

  const doAddOrder = useCallback(
    async (form: BasketForm) => {
      setGeneralError(undefined)

      if (!basket.address) {
        return
      }

      if (
        basket.items
          .filter(
            (item): item is TestBasketItem | ConsultationBasketItem =>
              isTestBasketItem(item) || isConsultationBasketItem(item)
          )
          .find((item) => !item.profile)
      ) {
        setGeneralError(
          i18n.t('screens.basket.generalError.profileNotAssigned')
        )
        return
      }

      if (!allProfilesComplete) {
        setGeneralError(
          i18n.t('screens.basket.generalError.profileIdentityIncomplete')
        )
        return
      }

      if (
        !basket.address.address ||
        !basket.address.postalCode ||
        !basket.address.city ||
        !basket.address.phoneNumber ||
        !basket.address.location
      ) {
        setGeneralError('Missing address data')
        return
      }

      const { data: orderInsertOutput, error: orderInsertError } =
        await addOrder({
          collections: basket.items.filter(isTestBasketItem).length
            ? [
                {
                  tests: basket.items
                    .filter(isTestBasketItem)
                    .map(({ test, profile }) => ({
                      testId: test.id,
                      profile: {
                        firstName: profile?.firstName || '',
                        lastName: profile?.lastName || '',
                        identity: profile?.identity || '',
                        gender: profile?.gender || '-',
                      },
                    })),
                  collectionDate: new Date(form.collectionDate).toISOString(),
                  address: basket.address.address,
                  postalCode: basket.address.postalCode,
                  city: basket.address.city,
                  phoneNumber: basket.address.phoneNumber,
                  clientQuarantine: form.clientQuarantine,
                  clientNotes: form.clientNotes,
                  location: basket.address.location.coordinates,
                },
              ]
            : [],
          consultations: basket.items
            .filter(isConsultationBasketItem)
            .map(({ consultation, preferredDate, profile }) => ({
              consultationId: consultation.id,
              preferredDate: preferredDate || new Date().toISOString(),
              phoneNumber: profile?.phoneNumber || '-',
              profile: {
                firstName: profile?.firstName || '-',
                lastName: profile?.lastName || '-',
                identity: profile?.identity || '-',
                gender: profile?.gender || '-',
              },
            })),

          trainings: basket.items
            .filter(isTrainingBasketItem)
            .map(({ training }) => ({
              trainingId: training.id,
            })),

          discountCodes: basket.discountCodes,
          clientConsent1: form.consent1,
          clientConsent2: form.consent2,
          clientConsent3: form.consent3,
        })

      if (orderInsertError) {
        setGeneralError(orderInsertError.message)
        return
      }

      if (!orderInsertOutput?.client_order_add) {
        setGeneralError(
          i18n.t('screens.basket.generalError.emptyOrderInsertResult')
        )
        return
      }

      try {
        basket.clear()

        window.location.href = getP24TransactionPanelUrl(
          orderInsertOutput.client_order_add.paymentToken
        )
      } catch (error: any) {
        setGeneralError(error.message)
      }
    },
    [addOrder, allProfilesComplete, basket, i18n]
  )

  const doEditClientNotes = useCallback(() => {
    promptModalRef.current?.show({
      message: i18n.t('screens.basket.form.notes.prompt.message'),
      onSuccess: (value) => {
        setValue('clientNotes', value)
      },
    })
  }, [i18n, setValue])

  const renderRight = useCallback(
    (className?: string) => {
      return (
        !!basket.items.length &&
        !basket.discountCodes.length && (
          <div className={className}>
            <button
              className="flex items-center gap-2.5 md:gap-5 mb-5"
              onClick={() =>
                promptModalRef.current?.show({
                  message: i18n.t(
                    'screens.basket.form.discountCode.prompt.message'
                  ),
                  onSuccess: (value) =>
                    basket.setDiscountCodes(value ? [value] : []),
                })
              }
            >
              <MdCardGiftcard size={20} color={Blue300} />
              <div className="text-left leading-tight text-blue-700">
                <p>{i18n.t('screens.basket.form.discountCode.link.label')}</p>
                <p className="font-bold underline">
                  {i18n.t('screens.basket.form.discountCode.link.clickHere')}
                </p>
              </div>
            </button>
            <p>
              <button
                className="flex items-center gap-2.5 md:gap-5 mb-5"
                onClick={() =>
                  promptModalRef.current?.show({
                    message: i18n.t('screens.basket.kdrPrompt.message'),
                    validate: {
                      validate: async (value) => {
                        const { data } = await verifyKDR({ cardNumber: value })
                        return data?.client_verify_kdr?.valid
                          ? undefined
                          : i18n.t(
                              'screens.basket.kdrPrompt.validator.invalidCode'
                            )
                      },
                    },
                    onSuccess: (value) =>
                      basket.setDiscountCodes(value ? [`KDR:${value}`] : []),
                  })
                }
              >
                <MdFamilyRestroom size={20} color={Blue300} />
                <div className="text-left leading-tight text-blue-700">
                  <p>{i18n.t('screens.basket.form.kdr.link.label')}</p>
                  <p className="font-bold underline">
                    {i18n.t('screens.basket.form.kdr.link.clickHere')}
                  </p>
                </div>
              </button>
            </p>
          </div>
        )
      )
    },
    [basket, i18n, verifyKDR]
  )

  return (
    <Frame
      renderRight={renderRight('hidden md:block md:mt-28')}
      leftClassName="w-[210px] hidden xl:block"
      rightClassName="w-[210px] hidden md:block"
    >
      <p className="text-blue-700 mb-5">
        <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
        {' > '}
        {i18n.t('screens.basket.breadcrumbs.basket')}
      </p>

      <h1 className="mb-8 mt-5 text-2xl md:text-3xl font-bold flex items-center gap-2.5">
        {i18n.t('screens.basket.title')}
        {orderCostFetching && (
          <TailSpin color={Blue600} width={20} height={20} />
        )}
      </h1>

      {!!generalError && <MessageBox>{generalError}</MessageBox>}

      {!basket.items.length && (
        <p className="my-5 text-blue-700 text-center">
          {i18n.t('screens.basket.basket.empty')}
        </p>
      )}

      {!!basket.items.length && (
        <div className="xl:mr-16">
          {basket.items.filter(isTestBasketItem).length > 0 && (
            <div className="hl-panel mb-5">
              <div className="p-5 flex gap-5 items-center border-b border-b-border border-opacity-20">
                <MdLocationPin size={24} color={Blue300} className="min-w-6" />
                <div>
                  <p className="text-blue-700 font-bold text-sm md:hidden">
                    {i18n.t('screens.basket.form.clientAddress.label')}
                  </p>
                  <p className="text-lg">
                    <strong>{basket.address?.address}</strong>{' '}
                    <span className="text-blue-700">
                      {basket.address?.postalCode} {basket.address?.city}
                    </span>
                  </p>
                </div>
                <MdEdit
                  className="ml-auto md:ml-0 min-w-5"
                  size={18}
                  color={Blue600}
                  onClick={() => addressSelectorModalRef.current?.show()}
                />
                <p className="ml-auto text-blue-700 font-bold text-sm hidden md:block">
                  {i18n.t('screens.basket.form.clientAddress.label')}
                </p>
              </div>
              <div className="p-5 flex gap-5 items-center border-b border-b-border border-opacity-20">
                <BsCalendarHeartFill size={24} color={Blue300} />
                <div className="flex-col-reverse flex md:flex-row w-full">
                  <input
                    className="text-lg font-bold focus:outline-none"
                    type="datetime-local"
                    min={moment().format('YYYY-MM-DDTHH:mm')}
                    {...register('collectionDate', {
                      required: i18n.t(
                        'screens.basket.form.date.rules.required'
                      ),
                      validate: (value) =>
                        new Date(value) > new Date()
                          ? undefined
                          : i18n.t('screens.basket.form.date.rules.minDate'),
                    })}
                  />
                  <div className="md:ml-auto md:text-right leading-tight text-sm text-blue-700">
                    <p className="font-bold">
                      {i18n.t('screens.basket.form.date.label')}
                    </p>
                    <p>
                      {i18n.t(
                        'screens.basket.form.date.possibleTimeDifference'
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-5 flex gap-5 items-center">
                <MdOutlineChatBubbleOutline size={24} color={Blue300} />
                <div className="mr-auto md:mr-0">
                  <p className="md:hidden text-blue-700 font-bold text-sm">
                    {i18n.t('screens.basket.form.notes.label')}
                  </p>
                  <p className="text-lg font-bold">
                    {watch('clientNotes') ||
                      i18n.t('screens.basket.form.notes.empty')}
                  </p>
                </div>
                <MdEdit size={18} color={Blue600} onClick={doEditClientNotes} />
                <p className="hidden md:block md:ml-auto text-blue-700 font-bold text-sm">
                  {i18n.t('screens.basket.form.notes.label')}
                </p>
              </div>
            </div>
          )}

          <ul className="border border-border border-opacity-20 rounded-b-2xl">
            {basket.items.map((item) => {
              if (isTestBasketItem(item)) {
                const testCost = orderCost?.collections?.[0].tests.find(
                  ({ id }: any) => id === item.test.id
                )

                const testOpeningHours = (
                  orderCostError &&
                  isOutsideOpeningHoursError(orderCostError?.graphQLErrors[0])
                    ? orderCostError?.graphQLErrors[0].extensions
                        .testsOpeningHours
                    : []
                ).find(([testId]) => item.test.id === testId)

                const testIdsNotAvailableInLocation =
                  orderCostError &&
                  isTestsNotAvailableInLocationError(
                    orderCostError?.graphQLErrors[0]
                  )
                    ? orderCostError?.graphQLErrors[0].extensions.testIds
                    : []

                return (
                  <li
                    key={`test-${item.test.id}`}
                    className="border-b border-b-border border-opacity-20 py-4 px-4 md:px-8 bg-white flex flex-col md:flex-row gap-5 items-center"
                  >
                    <div className="flex gap-2.5 w-full">
                      <div>
                        <p className="text-lg font-bold leading-tight">
                          <Link
                            to={`/test/${item.test.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <span className="text-black">
                              {item.test.shortName || item.test.name}
                            </span>
                            <MdKeyboardArrowRight
                              size={20}
                              color={Blue600}
                              className="inline"
                            />
                          </Link>
                        </p>
                        {item.test.shortName &&
                          item.test.shortName !== item.test.name && (
                            <p className="text-blue-700 text-xs">
                              {item.test.name}
                            </p>
                          )}
                        {testOpeningHours?.[1] && (
                          <p className="text-red-700 text-xs">
                            {i18n.t(
                              'screens.basket.form.orderTests.testOpeningHours'
                            )}{' '}
                            {Time.toShortFormat(testOpeningHours[1][0])}-
                            {Time.toShortFormat(testOpeningHours[1][1])}
                          </p>
                        )}
                        {testOpeningHours?.[1] === null && (
                          <p className="text-red-700 text-xs">
                            {i18n.t(
                              'screens.basket.form.orderTests.noOpenLaboratories'
                            )}
                          </p>
                        )}
                        {testIdsNotAvailableInLocation?.includes(
                          item.test.id
                        ) && (
                          <p className="text-red-700 text-xs">
                            {i18n.t(
                              'screens.basket.form.orderTests.notAvailableInLocation'
                            )}
                          </p>
                        )}
                        {!item.profile && (
                          <p className="text-red-700 text-xs">
                            {i18n.t(
                              'screens.basket.form.orderTests.assignToProfile'
                            )}
                          </p>
                        )}
                        {item.profile &&
                          (!isProfileIdentityComplete(item.profile) ||
                            !isProfileGenderComplete(item.profile)) && (
                            <p className="text-red-700 text-xs">
                              <Link to={`/profiles/${item.profile.id}`}>
                                {i18n.t(
                                  'screens.basket.form.orderTests.identityIncomplete.clickHere'
                                )}
                              </Link>{' '}
                              {i18n.t(
                                'screens.basket.form.orderTests.identityIncomplete.toFillProfile'
                              )}
                            </p>
                          )}
                      </div>
                      <div className="ml-auto flex flex-row-reverse md:flex-row items-start md:items-center gap-2.5">
                        <div>
                          {testCost && (
                            <div className="min-w-10">
                              <p className="text-base font-bold text-blue-700 whitespace-nowrap">
                                {i18n.t('screens.basket.form.orderTests.cost', {
                                  cost: Number(testCost.cost).toFixed(2),
                                })}
                              </p>
                              {testCost.costBeforeDiscount && (
                                <p className="text-red-700 line-through text-right font-bold">
                                  {i18n.t(
                                    'screens.basket.form.orderTests.costBeforeDiscount',
                                    {
                                      cost: Number(
                                        testCost.costBeforeDiscount
                                      ).toFixed(2),
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                          )}
                          {!testCost && item.test.availableLaboratoryTests && (
                            <p className="text-base font-bold text-blue-700 min-w-10 text-right whitespace-nowrap">
                              {i18n.t('screens.basket.form.orderTests.cost', {
                                cost: laboratoryTestsToPriceString(
                                  item.test.availableLaboratoryTests
                                ),
                              })}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            confirmDialogRef.current?.show({
                              message: i18n.t(
                                'screens.basket.form.orderTests.remove.alert.title',
                                {
                                  testName:
                                    item.test.shortName || item.test.name,
                                }
                              ),
                              onSuccess: () => basket.removeItem(item),
                            })
                          }}
                        >
                          <MdRemoveShoppingCart size={24} color={Red800} />
                        </button>
                      </div>
                    </div>
                    <ProfileSelect
                      className="ml-auto md:ml-0"
                      onChange={(profile) =>
                        basket.changeItem(item, {
                          ...item,
                          profile,
                        })
                      }
                      value={
                        item.profile as GetProfilesSubscription['client_profile'][number]
                      }
                    />
                  </li>
                )
              } else if (isConsultationBasketItem(item)) {
                const consultationCost = orderCost?.consultations?.find(
                  ({ id }) => id === item.consultation.id
                )

                const consultationOutsideSlotError =
                  orderCostError?.graphQLErrors
                    .filter(isConsultationOutsideSlotError)
                    .find(
                      (graphQlError) =>
                        graphQlError.extensions.consultationId ===
                        item.consultation.id
                    )?.extensions

                return (
                  <li
                    key={`consultation-${item.consultation.id}`}
                    className="border-b border-b-border border-opacity-20 py-4 px-4 md:px-8 bg-white flex flex-col md:flex-row gap-5 items-center"
                  >
                    <div className="flex gap-2.5 w-full">
                      <div>
                        <p className="text-blue-700 text-xs">
                          {i18n.t(
                            'screens.basket.form.orderConsultations.header',
                            {
                              phoneNumber: item.profile?.phoneNumber || '-',
                            }
                          )}
                        </p>
                        <p className="text-lg font-bold leading-tight">
                          <Link
                            to={`/training/${item.consultation.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <span className="text-black">
                              {item.consultation.name}
                            </span>
                            <MdKeyboardArrowRight
                              size={20}
                              color={Blue600}
                              className="inline"
                            />
                          </Link>
                        </p>
                        <p
                          className="text-blue-700 text-xs"
                          dangerouslySetInnerHTML={{
                            __html: i18n.t(
                              'screens.basket.form.orderConsultations.phoneNumber',
                              {
                                phoneNumber: item.profile?.phoneNumber || '-',
                              }
                            ),
                          }}
                        />
                        {!!item.profile &&
                          (!isProfileIdentityComplete(item.profile) ||
                            !isProfileGenderComplete(item.profile)) && (
                            <p className="text-red-700 text-xs">
                              <Link to={`/profiles/${item.profile.id}`}>
                                {i18n.t(
                                  'screens.basket.form.orderConsultations.identityIncomplete.clickHere'
                                )}
                              </Link>{' '}
                              {i18n.t(
                                'screens.basket.form.orderConsultations.identityIncomplete.toFillProfile'
                              )}
                            </p>
                          )}
                        {!item.preferredDate && (
                          <p className="text-red-700 text-xs">
                            <button
                              onClick={() => {
                                promptModalRef.current?.show({
                                  message: i18n.t(
                                    'screens.basket.form.orderConsultations.preferredDatePrompt'
                                  ),
                                  onSuccess: (date) =>
                                    basket.changeItem(item, {
                                      ...item,
                                      preferredDate: new Date(
                                        date
                                      ).toISOString(),
                                    }),
                                  type: 'datetime-local',
                                  value: item.preferredDate,
                                  min: moment().format('YYYY-MM-DDTHH:mm'),
                                })
                              }}
                            >
                              {i18n.t(
                                'screens.basket.form.orderConsultations.fillPreferredDate'
                              )}
                              <MdKeyboardArrowRight
                                size={12}
                                color={Red800}
                                className="inline"
                              />
                            </button>
                          </p>
                        )}
                        {!!item.preferredDate && (
                          <p className="text-blue-600 text-xs">
                            <button
                              onClick={() => {
                                promptModalRef.current?.show({
                                  message: i18n.t(
                                    'screens.basket.form.orderConsultations.preferredDatePrompt'
                                  ),
                                  onSuccess: (date) =>
                                    basket.changeItem(item, {
                                      ...item,
                                      preferredDate: new Date(
                                        date
                                      ).toISOString(),
                                    }),
                                  type: 'datetime-local',
                                  value: moment(item.preferredDate).format(
                                    'YYYY-MM-DDTHH:mm'
                                  ),
                                  min: moment().format('YYYY-MM-DDTHH:mm'),
                                })
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: i18n.t(
                                    'screens.basket.form.orderConsultations.preferredDate',
                                    {
                                      preferredDate: moment(
                                        item.preferredDate
                                      ).format('YYYY-MM-DD HH:mm'),
                                    }
                                  ),
                                }}
                              />
                              <MdKeyboardArrowRight
                                size={12}
                                color={Blue600}
                                className="inline"
                              />
                            </button>
                          </p>
                        )}
                        {!!consultationOutsideSlotError && (
                          <p className="text-red-700 text-xs">
                            {i18n.t(
                              'screens.basket.form.orderConsultation.outsideSlot'
                            )}
                            <br />
                            {!!consultationOutsideSlotError.prevSlot && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: i18n.t(
                                    'screens.basket.form.orderConsultation.prevSlot',
                                    {
                                      prevDateTime: moment(
                                        consultationOutsideSlotError.prevSlot
                                          .dateFrom
                                      ).format('YYYY-MM-DD HH:mm'),
                                      prevTime: moment(
                                        consultationOutsideSlotError.prevSlot
                                          .dateTo
                                      ).format('HH:mm'),
                                    }
                                  ),
                                }}
                              />
                            )}
                            {!!consultationOutsideSlotError.nextSlot && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: i18n.t(
                                    'screens.basket.form.orderConsultation.nextSlot',
                                    {
                                      nextDateTime: moment(
                                        consultationOutsideSlotError.nextSlot
                                          .dateFrom
                                      ).format('YYYY-MM-DD HH:mm'),
                                      nextTime: moment(
                                        consultationOutsideSlotError.nextSlot
                                          .dateTo
                                      ).format('HH:mm'),
                                    }
                                  ),
                                }}
                              />
                            )}
                          </p>
                        )}
                      </div>
                      <div className="ml-auto flex flex-row-reverse md:flex-row items-start md:items-center gap-2.5">
                        <div>
                          {consultationCost && (
                            <div className="min-w-10">
                              <p className="text-base font-bold text-blue-700 whitespace-nowrap">
                                {i18n.t(
                                  'screens.basket.form.orderTrainings.cost',
                                  {
                                    cost: Number(consultationCost.cost).toFixed(
                                      2
                                    ),
                                  }
                                )}
                              </p>
                              {consultationCost.costBeforeDiscount && (
                                <p className="text-red-700 line-through text-right font-bold">
                                  {i18n.t(
                                    'screens.basket.form.orderTrainings.costBeforeDiscount',
                                    {
                                      cost: Number(
                                        consultationCost.costBeforeDiscount
                                      ).toFixed(2),
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                          )}
                          {!consultationCost && (
                            <p className="text-base font-bold text-blue-700 min-w-10 text-right whitespace-nowrap">
                              {i18n.t(
                                'screens.basket.form.orderTrainings.cost',
                                {
                                  cost: item.consultation.price,
                                }
                              )}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            confirmDialogRef.current?.show({
                              message: i18n.t(
                                'screens.basket.form.orderTrainings.remove.alert.title',
                                {
                                  trainingName: item.consultation.name,
                                }
                              ),
                              onSuccess: () => basket.removeItem(item),
                            })
                          }}
                        >
                          <MdRemoveShoppingCart size={24} color={Red800} />
                        </button>
                      </div>
                    </div>
                    <ProfileSelect
                      className="ml-auto md:ml-0"
                      onChange={(profile) =>
                        basket.changeItem(item, {
                          ...item,
                          profile,
                        })
                      }
                      value={
                        item.profile as GetProfilesSubscription['client_profile'][number]
                      }
                    />
                  </li>
                )
              } else if (isTrainingBasketItem(item)) {
                const trainingCost = orderCost?.trainings?.find(
                  ({ id }) => id === item.training.id
                )

                return (
                  <li
                    key={`training-${item.training.id}`}
                    className="border-b border-b-border border-opacity-20 py-4 px-4 md:px-8 bg-white flex flex-col md:flex-row gap-5 items-center"
                  >
                    <div className="flex gap-2.5 w-full">
                      <div>
                        <p className="text-blue-700 text-xs">
                          {i18n.t('screens.basket.form.orderTrainings.header')}
                        </p>
                        <p className="text-lg font-bold leading-tight">
                          <Link
                            to={`/training/${item.training.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <span className="text-black">
                              {item.training.name}
                            </span>
                            <MdKeyboardArrowRight
                              size={20}
                              color={Blue600}
                              className="inline"
                            />
                          </Link>
                        </p>
                      </div>
                      <div className="ml-auto flex flex-row-reverse md:flex-row items-start md:items-center gap-2.5">
                        <div>
                          {trainingCost && (
                            <div className="min-w-10">
                              <p className="text-base font-bold text-blue-700 whitespace-nowrap">
                                {i18n.t(
                                  'screens.basket.form.orderTrainings.cost',
                                  {
                                    cost: Number(trainingCost.cost).toFixed(2),
                                  }
                                )}
                              </p>
                              {trainingCost.costBeforeDiscount && (
                                <p className="text-red-700 line-through text-right font-bold">
                                  {i18n.t(
                                    'screens.basket.form.orderTrainings.costBeforeDiscount',
                                    {
                                      cost: Number(
                                        trainingCost.costBeforeDiscount
                                      ).toFixed(2),
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                          )}
                          {!trainingCost && (
                            <p className="text-base font-bold text-blue-700 min-w-10 text-right whitespace-nowrap">
                              {i18n.t(
                                'screens.basket.form.orderTrainings.cost',
                                {
                                  cost: item.training.price,
                                }
                              )}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            confirmDialogRef.current?.show({
                              message: i18n.t(
                                'screens.basket.form.orderTrainings.remove.alert.title',
                                {
                                  trainingName: item.training.name,
                                }
                              ),
                              onSuccess: () => basket.removeItem(item),
                            })
                          }}
                        >
                          <MdRemoveShoppingCart size={24} color={Red800} />
                        </button>
                      </div>
                    </div>
                  </li>
                )
              } else {
                return <span>Unknown basket item</span>
              }
            })}
            {!!orderCost?.collections?.[0] && (
              <li className="border-b border-b-border border-opacity-20 py-4 px-4 md:px-8 bg-white flex gap-2.5 md:gap-5 md:items-center">
                <div>
                  <p className="text-lg font-bold leading-tight">
                    {i18n.t('screens.basket.form.deliveryCollectionCost.label')}
                  </p>
                  <p className="leading-tight text-blue-700">
                    {i18n.t(
                      'screens.basket.form.deliveryCollectionCost.collection',
                      {
                        collectionCost: Number(
                          orderCost.collections[0]
                            .collectionCostBeforeDiscount ||
                            orderCost.collections[0].collectionCost
                        ).toFixed(2),
                      }
                    )}
                  </p>
                  <p className="leading-tight text-blue-700">
                    {i18n.t(
                      'screens.basket.form.deliveryCollectionCost.delivery',
                      {
                        cost: Number(
                          orderCost.collections[0].deliveryCostBeforeDiscount ||
                            orderCost.collections[0].deliveryCost
                        ).toFixed(2),
                        costPerKm: orderCost.collections[0].deliveryCostPerKm,
                      }
                    )}
                  </p>
                  <p className="leading-tight text-blue-700">
                    {i18n.t(
                      'screens.basket.form.deliveryCollectionCost.collectionKit',
                      {
                        collectionKitCost: Number(
                          orderCost.collections[0]
                            .collectionKitCostBeforeDiscount ||
                            orderCost.collections[0].collectionKitCost
                        ).toFixed(2),
                      }
                    )}
                  </p>
                </div>
                <div className="ml-auto md:pr-11 min-w-10">
                  <p className="text-base font-bold text-blue-700 text-right whitespace-nowrap">
                    {i18n.t(
                      'screens.basket.form.deliveryCollectionCost.total',
                      {
                        total: Number(
                          orderCost.collections[0].deliveryCost +
                            orderCost.collections[0].collectionCost +
                            orderCost.collections[0].collectionKitCost
                        ).toFixed(2),
                      }
                    )}
                  </p>
                  {(orderCost.collections[0].deliveryCostBeforeDiscount ||
                    orderCost.collections[0].collectionCostBeforeDiscount ||
                    orderCost.collections[0]
                      .collectionKitCostBeforeDiscount) && (
                    <p className="font-bold text-right text-red-700 line-through">
                      {i18n.t(
                        'screens.basket.form.deliveryCollectionCostBeforeDiscount.total',
                        {
                          total: Number(
                            (orderCost.collections[0]
                              .deliveryCostBeforeDiscount ||
                              orderCost.collections[0].deliveryCost) +
                              (orderCost.collections[0]
                                .collectionCostBeforeDiscount ||
                                orderCost.collections[0].collectionCost) +
                              (orderCost.collections[0]
                                .collectionKitCostBeforeDiscount ||
                                orderCost.collections[0].collectionKitCost)
                          ).toFixed(2),
                        }
                      )}
                    </p>
                  )}
                </div>
              </li>
            )}
            {basket.discountCodes.map((discountCode) => {
              const isCodeValid =
                !orderCost ||
                orderCost.discounts.find(
                  ({ code }: any) =>
                    discountCode.toUpperCase() === code.toUpperCase()
                )
              return (
                <li
                  key={discountCode}
                  className="border-b border-b-border border-opacity-20 py-4 px-4 md:px-8 bg-white flex gap-5 items-center"
                >
                  <div>
                    {!discountCode.startsWith('KDR:') && (
                      <>
                        <p className="text-lg font-bold leading-tight">
                          {i18n.t('screens.basket.form.discountCode.label')}
                        </p>
                        <p className="leading-tight text-blue-700">
                          {discountCode.toUpperCase()}
                        </p>
                      </>
                    )}
                    {discountCode.startsWith('KDR:') && (
                      <>
                        <p className="text-lg font-bold leading-tight">
                          {i18n.t('screens.basket.form.kdr.label')}
                        </p>
                        <p className="leading-tight text-blue-700">
                          {discountCode.substring(4)}
                        </p>
                      </>
                    )}
                    {!isCodeValid && (
                      <p className="text-red-700 text-xs">
                        {i18n.t('screens.basket.form.discountCode.invalid')}
                      </p>
                    )}
                  </div>
                  <button
                    className="ml-auto"
                    onClick={() => basket.setDiscountCodes([])}
                  >
                    <MdClose size={24} color={Red800} />
                  </button>
                </li>
              )
            })}
            <li className="py-8 md:py-16 px-4 md:px-8 bg-blue-200 rounded-b-2xl flex items-center justify-between md:justify-end gap-2.5 md:gap-5">
              <div>
                {!!orderCost && (
                  <>
                    {' '}
                    <div className="flex items-center gap-2">
                      <MdShoppingBasket color={Blue600} size={24} />
                      <p className="font-bold text-base md:text-lg leading-tight">
                        {i18n.t('screens.basket.form.summary.cost', {
                          cost: Number(orderCost.cost).toFixed(2),
                        })}
                      </p>
                    </div>
                    {orderCost.costBeforeDiscount && (
                      <p className="text-right font-bold text-red-700 line-through leading-tight">
                        {i18n.t(
                          'screens.basket.form.summary.costBeforeDiscount',
                          {
                            cost: Number(orderCost.costBeforeDiscount).toFixed(
                              2
                            ),
                          }
                        )}
                      </p>
                    )}
                  </>
                )}
                {orderCostFetching && (
                  <TailSpin color={Blue600} width={20} height={20} />
                )}
              </div>
              <div className="flex gap-5 items-center">
                {auth.accessToken && (
                  <button
                    className="hl-button"
                    disabled={isPayAndOrderDisabled}
                    onClick={handleSubmit(doAddOrder, scrollToFirstError)}
                  >
                    {i18n.t('screens.basket.form.submit')}
                  </button>
                )}

                {!auth.accessToken && (
                  <button
                    className="hl-button"
                    onClick={() => {
                      navigate(`/login?backTo=${encodeURIComponent('/basket')}`)
                    }}
                  >
                    {i18n.t('screens.basket.form.loginRegister')}
                  </button>
                )}
              </div>
            </li>
          </ul>

          {basket.items.filter(isTestBasketItem).length > 0 && (
            <div className="mx-4 md:mx-10 my-5 flex flex-col md:flex-row gap-6 md:gap-16">
              <div className="flex gap-2.5 flex-1">
                <MdInfoOutline color={Red800} size={24} />
                <p>
                  <span className="text-blue-700">
                    {i18n.t('screens.basket.form.preparation.header')}
                  </span>
                  <br />
                  <span>
                    {i18n.t('screens.basket.form.preparation.description')}
                  </span>
                </p>
              </div>
              <div className="flex gap-2.5 flex-1">
                <MdMasks color={Red800} size={24} />
                <p>
                  <span className="text-blue-700">
                    {i18n.t('screens.basket.form.faceMask.header')}
                  </span>
                  <br />
                  <span>
                    {i18n.t('screens.basket.form.faceMask.description')}
                  </span>
                </p>
              </div>
            </div>
          )}

          {renderRight('md:hidden mx-4')}

          {basket.items.filter(isTestBasketItem).length > 0 && (
            <div className="mb-5 md:mx-5 mt-10">
              <Controller
                control={control}
                name="clientQuarantine"
                render={({ field: { value, onChange } }) => (
                  <label className="flex">
                    <Switch
                      checked={value}
                      onChange={onChange}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      width={40}
                      height={24}
                      onColor={Green600}
                      className="mr-6"
                    />
                    <span className="text-xs font-bold text-blue-700 pt-1">
                      {i18n.t('screens.basket.form.quarantine')}
                    </span>
                  </label>
                )}
              />
              {errors.clientQuarantine && (
                <p className="hl-input-error">
                  {errors.clientQuarantine.message}
                </p>
              )}
            </div>
          )}

          <div className="my-5 md:mx-5">
            <Controller
              control={control}
              rules={{
                required: i18n.t('screens.basket.form.consent.rules.required'),
              }}
              name="consent1"
              render={({ field: { value, onChange, ref } }) => (
                <label className="flex" ref={ref}>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={24}
                    onColor={Green600}
                    className="mr-6"
                  />
                  <span className="text-xs text-blue-700 pt-1">
                    {i18n.t('common.order.consent.text1')}
                    {errors.consent1 && (
                      <p className="hl-input-error">
                        {errors.consent1.message}
                      </p>
                    )}
                  </span>
                </label>
              )}
            />
          </div>

          <div className="my-5 md:mx-5">
            <Controller
              control={control}
              rules={{
                required: i18n.t('screens.basket.form.consent.rules.required'),
              }}
              name="consent2"
              render={({ field: { value, onChange, ref } }) => (
                <label className="flex" ref={ref}>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={24}
                    onColor={Green600}
                    className="mr-6"
                  />
                  <span className="text-xs text-blue-700 pt-1">
                    {i18n.t('common.order.consent.text2.part1')}{' '}
                    <a
                      href={
                        RegulationLinks.regulation[i18n.locale] ||
                        RegulationLinks.regulation.pl
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.t('common.order.consent.text2.part2')}
                    </a>{' '}
                    {i18n.t('common.order.consent.text2.part3')}{' '}
                    <a
                      href={
                        RegulationLinks.privacyPolicy[i18n.locale] ||
                        RegulationLinks.privacyPolicy.pl
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.t('common.order.consent.text2.part4')}
                    </a>{' '}
                    {i18n.t('common.order.consent.text2.part5')}
                    {errors.consent2 && (
                      <p className="hl-input-error">
                        {errors.consent2.message}
                      </p>
                    )}
                  </span>
                </label>
              )}
            />
          </div>

          <div className="my-5 md:mx-5">
            <Controller
              control={control}
              rules={{
                required: i18n.t('screens.basket.form.consent.rules.required'),
              }}
              name="consent3"
              render={({ field: { value, onChange, ref } }) => (
                <label className="flex" ref={ref}>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={24}
                    onColor={Green600}
                    className="mr-6"
                  />
                  <div className="text-xs text-blue-700 pt-1">
                    <span className="leading-tight">
                      {i18n.t('common.order.consent.text3.main')}
                    </span>
                    <br />
                    <span className="leading-tight whitespace-pre-wrap">
                      {i18n.t('common.order.consent.text3.bullets')}
                    </span>
                    {errors.consent3 && (
                      <p className="hl-input-error">
                        {errors.consent3.message}
                      </p>
                    )}
                  </div>
                </label>
              )}
            />
          </div>
          <PromptModal ref={promptModalRef} />
          <ConfirmDialog ref={confirmDialogRef} />
          <AddressSelectorModal ref={addressSelectorModalRef} />
        </div>
      )}
    </Frame>
  )
}
