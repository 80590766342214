import React, { InputHTMLAttributes, forwardRef } from 'react'

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      className={`hl-input ${props.className || ''}`}
    />
  )
})

export default Input
