import { Link } from 'react-router-dom'
import React from 'react'
import { useI18n } from '../provider/i18n'
import { Training } from '../generated/urql.client'
import { guessStorageAddress } from '../lib/storage'

const AddButtonSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <circle cx="18" cy="18" r="18" fill="#71C09D" />
    <rect x="16" y="9" width="4" height="18" fill="white" />
    <rect
      x="9"
      y="20"
      width="4"
      height="18"
      transform="rotate(-90 9 20)"
      fill="white"
    />
  </svg>
)

export const TrainingItem: React.FC<{
  training: Pick<
    Training,
    'id' | 'previewFileId' | 'name' | 'description' | 'price'
  >
  onTrainingAddClick?: () => void
  showAddButton?: boolean
}> = ({ training, onTrainingAddClick, showAddButton }) => {
  const i18n = useI18n()

  return (
    <>
      <div key={training.id} className="hl-panel md:h-28 flex overflow-hidden">
        <div
          className={`p-4 bg-blue-200 flex min-w-28 items-center justify-center bg-cover`}
          style={{
            backgroundImage: `url(${guessStorageAddress()}/files/${
              training.previewFileId
            })`,
          }}
        />
        <div className="pl-5 py-4 flex-1">
          <Link
            to={`/training/${training.id}`}
            className="no-underline text-blue-900 hover:text-blue-700 flex flex-col h-full"
          >
            <p className="font-bold mb-2 leading-tight">{training.name}</p>
            <p className="text-xs font-normal text-justify hidden md:block xl:hidden">
              {(training.description || '').length > 150
                ? training.description?.substring(0, 150) + '...'
                : training.description}
            </p>
            <p className="text-xs font-normal text-justify hidden xl:block">
              {(training.description || '').length > 280
                ? training.description?.substring(0, 280) + '...'
                : training.description}
            </p>
            <p className="font-bold text-xs md:hidden mt-auto">
              {i18n.t('components.trainingItem.price', {
                price: training.price,
              })}
            </p>
          </Link>
        </div>
        <div className="basis-24 flex-none items-center justify-end hidden md:flex ml-4">
          <p className="font-bold whitespace-nowrap">
            {i18n.t('components.trainingItem.price', {
              price: training.price,
            })}
          </p>
        </div>
        <div className="flex items-center mx-4 xl:ml-5 zl:mr-7">
          <button
            onClick={onTrainingAddClick}
            disabled={!showAddButton}
            className={showAddButton ? '' : 'opacity-20'}
          >
            {AddButtonSvg}
          </button>
        </div>
      </div>
    </>
  )
}
