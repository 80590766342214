import React from 'react'
import { Frame } from '../components/Frame'
import { Outlet } from 'react-router-dom'

export const OrdersPage = () => {
  return (
    <Frame
      leftClassName="w-[210px] hidden xl:block"
      rightClassName="w-[210px] hidden xl:block"
    >
      <Outlet />
    </Frame>
  )
}
