import { authExchange } from '@urql/exchange-auth'
import { I18n } from 'i18n-js'
import {
  cacheExchange,
  createClient,
  fetchExchange,
  makeOperation,
  Operation,
  subscriptionExchange,
} from 'urql'
import _package from '../../package.json'
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '../generated/urql.anonymous'
import { Auth, isTokenExpired } from '../provider/auth'
import { createClient as createWSClient } from 'graphql-ws'

function guessApiAddress() {
  const host = window.location.host
  if (host.toLowerCase().indexOf('localhost') > -1) {
    return 'http://localhost:4020/v1/graphql'
  } else {
    return 'https://' + host.replace(/^[a-zA-Z0-9-]+\./, 'api.') + '/v1/graphql'
  }
}

export function guessWsApiAddress() {
  return guessApiAddress().replace(/^http/, 'ws')
}

function addHeadersToOperation(
  operation: Operation,
  headers: RequestInit['headers']
) {
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {}

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        ...headers,
      },
    },
  })
}

export const getClient = (auth: Auth, i18n: I18n) => {
  function getSubscriptionClient(accessToken?: string | null) {
    return createWSClient({
      url: guessWsApiAddress(),
      connectionParams: async () => ({
        headers: {
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          'x-hasura-preferred-locale': i18n.locale,
          'x-app-version': _package.version,
          'x-app-platform-os': 'web',
        },
      }),
    })
  }

  const subscriptionClient = {
    client: getSubscriptionClient(auth.accessToken),
    accessToken: auth.accessToken || undefined,
  }

  return createClient({
    url: guessApiAddress(),
    fetchOptions: () => ({
      mode: 'cors',
      credentials: 'include',
      referrerPolicy: 'no-referrer-when-downgrade',
    }),
    exchanges: [
      cacheExchange,
      // @ts-ignore
      authExchange<string>({
        // @ts-ignore
        addAuthToOperation: ({ authState, operation }) => {
          // the token isn't in the auth state, return the operation without changes
          if (!authState) {
            // @ts-ignore
            return addHeadersToOperation(operation, {
              'x-hasura-preferred-locale': i18n.locale,
              'x-app-version': _package.version,
              'x-app-platform-os': 'web',
            })
          }

          const queryName = (operation as any).query.definitions[0].name.value

          if (
            [
              'Login',
              'RefreshToken',
              'PasswordResetInit',
              'PasswordResetFinish',
            ].includes(queryName)
          ) {
            // @ts-ignore
            return addHeadersToOperation(operation, {
              'x-hasura-preferred-locale': i18n.locale,
              'x-app-version': _package.version,
              'x-app-platform-os': 'web',
            })
          }

          // @ts-ignore
          return addHeadersToOperation(operation, {
            'x-hasura-preferred-locale': i18n.locale,
            'x-app-version': _package.version,
            'x-app-platform-os': 'web',
            Authorization: `Bearer ${authState}`,
          })
        },
        willAuthError: () => {
          if (!auth.accessToken) {
            return true
          }

          return isTokenExpired(auth.accessToken, 15000)
        },
        didAuthError: ({ error }) => {
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === 'invalid-jwt'
          )
        },
        getAuth: async ({ mutate }) => {
          const result = await mutate<
            RefreshTokenMutation,
            RefreshTokenMutationVariables
          >(RefreshTokenDocument)

          if (result.data?.client_refresh_token) {
            await auth.setAccessToken(
              result.data.client_refresh_token.accessToken
            )

            return result.data.client_refresh_token.accessToken
          }

          return null
        },
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const accessToken =
            // @ts-ignore
            request.context.fetchOptions.headers.Authorization?.replace(
              'Bearer ',
              ''
            ) || undefined
          if (accessToken !== subscriptionClient.accessToken) {
            subscriptionClient.accessToken = accessToken
            subscriptionClient.client.dispose()
            subscriptionClient.client = getSubscriptionClient(accessToken)
          }
          const input = { ...request, query: request.query || '' }
          return {
            subscribe: (sink) => {
              const unsubscribe = subscriptionClient.client.subscribe(
                input,
                sink
              )
              return { unsubscribe }
            },
          }
        },
      }),
    ],
  })
}
