import * as Geocoder from 'react-geocode'
import { GoogleApiKey } from '../const'
import { Suggestion } from 'use-places-autocomplete'

export type Point = { type: 'Point'; coordinates: [number, number] }
export type Polygon = { type: 'Polygon'; coordinates: [number, number][][] }
export type Geography = Point | Polygon

export function isPoint(geography: Geography): geography is Point {
  return geography.type === 'Point'
}

export function isPolygon(geography: Geography): geography is Polygon {
  return geography.type === 'Polygon'
}

export function assertPoint(geography: Geography): asserts geography is Point {
  if (!isPoint(geography)) {
    throw new Error('Expected Geography(Point)')
  }
}

export function assertPolygon(
  geography: Geography
): asserts geography is Polygon {
  if (!isPolygon(geography)) {
    throw new Error('Expected Geography(Polygon)')
  }
}

export function calculateInitialRegion(geography: Geography, offset = 0.05) {
  if (isPolygon(geography)) {
    const minMax = geography.coordinates.reduce(
      (r, innerPolygon) => {
        for (const [lat, lng] of innerPolygon) {
          r.latMin = r.latMin > lat ? lat : r.latMin
          r.latMax = r.latMax < lat ? lat : r.latMax
          r.lngMin = r.lngMin > lng ? lng : r.lngMin
          r.lngMax = r.lngMax < lng ? lng : r.lngMax
        }
        return r
      },
      {
        latMin: Number.MAX_VALUE,
        latMax: Number.MIN_VALUE,
        lngMin: Number.MAX_VALUE,
        lngMax: Number.MIN_VALUE,
      }
    )

    return {
      latitude: (minMax.latMin + minMax.latMax) / 2,
      longitude: (minMax.lngMin + minMax.lngMax) / 2,
      latitudeDelta: Math.abs(minMax.latMin - minMax.latMax) + offset,
      longitudeDelta: Math.abs(minMax.lngMin - minMax.lngMax) + offset,
    }
  }

  if (isPoint(geography)) {
    return {
      latitude: geography.coordinates[0],
      longitude: geography.coordinates[1],
      latitudeDelta: offset,
      longitudeDelta: offset,
    }
  }
}

function distance([x1, y1]: [number, number], [x2, y2]: [number, number]) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

export function getNearestPoint(
  polygons: Polygon['coordinates'],
  point: Point['coordinates']
) {
  return polygons.reduce<[number, number] | undefined>(
    (ref, coordinates, pi) => {
      let newRef: [number, number] | undefined = ref ? [...ref] : undefined

      for (let ci = 0; ci < coordinates.length; ci++) {
        if (
          !newRef ||
          distance(point, coordinates[ci]) <
            distance(point, polygons[newRef[0]][newRef[1]])
        ) {
          newRef = [pi, ci]
        }
      }

      return newRef
    },
    undefined
  )
}

export type GeocoderLocation = {
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

export async function findLocations(
  address: string,
  locale = 'pl'
): Promise<GeocoderLocation[]> {
  Geocoder.setKey(GoogleApiKey)
  Geocoder.setLanguage(locale)
  const response = await Geocoder.fromAddress(address)
  // @ts-ignore
  return response.results
}

export async function findLocation(
  address: {
    address: string
    city: string
    postalCode: string
  },
  locale = 'pl'
): Promise<Point | undefined> {
  const locations = await findLocations(
    `${address.address}, ${address.postalCode} ${address.city}, Polska`,
    locale
  )

  if (!locations.length) {
    return
  }

  return {
    type: 'Point',
    coordinates: [
      locations[0].geometry.location.lat,
      locations[0].geometry.location.lng,
    ],
  }
}

type AddressComponentType =
  | 'street_address'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'administrative_area_level_6'
  | 'administrative_area_level_7'
  | 'colloquial_area'
  | 'locality'
  | 'sublocality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'plus_code'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest'

export type GeocoderResponse = {
  results: Array<{
    address_components: Array<{
      long_name: string
      short_name: string
      types: AddressComponentType[]
    }>
    formatted_address: string
    geometry: {
      bounds?: {
        northeast: {
          lat: number
          lng: number
        }
        southwest: {
          lat: number
          lng: number
        }
      }
      location: {
        lat: number
        lng: number
      }
      location_type:
        | 'ROOFTOP'
        | 'RANGE_INTERPOLATED'
        | 'GEOMETRIC_CENTER'
        | 'APPROXIMATE'
      viewport: {
        northeast: {
          lat: number
          lng: number
        }
        southwest: {
          lat: number
          lng: number
        }
      }
    }
    place_id: string
    types: AddressComponentType[]
  }>
  status:
    | 'OK'
    | 'ZERO_RESULTS'
    | 'OVER_DAILY_LIMIT'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'INVALID_REQUEST'
    | 'UNKNOWN_ERROR'
}

export async function getLocationByPlaceId(
  placeId: string,
  locale = 'pl'
): Promise<GeocoderResponse> {
  Geocoder.setKey(GoogleApiKey)
  Geocoder.setLanguage(locale)
  return Geocoder.fromPlaceId(placeId)
}

export async function getProfileAddress(
  suggestion: Suggestion,
  locale = 'pl'
): Promise<
  | {
      address: string
      postalCode: string
      city: string
      location: Point
    }
  | undefined
> {
  const geocoded = await getLocationByPlaceId(suggestion.place_id, locale)
  if (!geocoded.results.length) {
    console.error('Cannot find address')
    return
  }

  const postalCode = geocoded.results[0].address_components.find(({ types }) =>
    types.includes('postal_code')
  )
  if (!postalCode) {
    console.error('Cannot find postal code')
    return
  }

  const city = geocoded.results[0].address_components.find(({ types }) =>
    types.includes('locality')
  )
  if (!city) {
    console.error('Cannot find city')
    return
  }

  return {
    address: suggestion.description.split(',')[0],
    postalCode: postalCode.long_name,
    city: city.long_name,
    location: {
      type: 'Point',
      coordinates: [
        geocoded.results[0].geometry.location.lat,
        geocoded.results[0].geometry.location.lng,
      ],
    },
  }
}
