import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../../lib/colors'
import { TestItem } from '../../components/TestItem'
import React, { useCallback, useContext, useRef } from 'react'
import {
  SearchTestFragment,
  useGetAvailableTestsByTagSubscription,
  useGetProfilesSubscription,
} from '../../generated/urql.client'
import { BasketContext, isTestBasketItem } from '../../provider/basket'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useGetTagSubscription } from '../../generated/urql.anonymous'
import { useI18n } from '../../provider/i18n'
import { AuthContext } from '../../provider/auth'
import { ProfileSelectorModalType } from '../../components/ProfileSelectorModal'

export const CatalogTagPage = () => {
  const params = useParams()
  const i18n = useI18n()
  const auth = useContext(AuthContext)
  const basket = useContext(BasketContext)
  const [{ data: testsData, fetching: testsFetching }] =
    useGetAvailableTestsByTagSubscription({
      pause: !basket.address?.location || !params.tagId,
      variables: {
        point: basket.address?.location || {
          type: 'Point',
          coordinates: [0, 0],
        },
        limit: 1000,
        tag: params.tagId || '',
      },
    })
  const [{ data: tagData }] = useGetTagSubscription({
    pause: !params.tagId,
    variables: {
      id: params.tagId || '',
    },
  })
  const [{ data: profilesData }] = useGetProfilesSubscription({
    pause: !auth.isLogged,
  })
  const profileSelectorModalRef = useRef<ProfileSelectorModalType>(null)

  const onTestAddClick = useCallback(
    (test: SearchTestFragment) => {
      const profilesCount = profilesData?.client_profile.length || 0

      if (!auth.isLogged || profilesCount === 0) {
        basket.addItem({ test })
      } else if (profilesCount === 1) {
        basket.addItem({ test, profile: profilesData?.client_profile[0] })
      } else {
        profileSelectorModalRef.current?.show({
          onChange: (profile) => {
            basket.addItem({ test, profile })
          },
          filter: (profile) =>
            !basket.items.find(
              (item) =>
                isTestBasketItem(item) &&
                item.test.id === test.id &&
                item.profile?.id === profile.id
            ),
        })
      }
    },
    [auth.isLogged, basket, profilesData?.client_profile]
  )

  const tests = testsData?.search_test_tag || []
  const tag = tagData?.tag_by_pk || { id: '', tag: '' }

  return (
    <div className="mb-10">
      <hr className="md:hidden border-t border-t-border border-opacity-50 my-5" />

      <div className="text-blue-700 md:mx-5 my-5 gap-1 items-center">
        <span>
          <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
          {' > '}
          {tag.tag}
        </span>
        {testsFetching && <TailSpin color={Blue300} height={16} width={16} />}
      </div>

      {basket.address?.location && !!tests.length && (
        <ul className="md:mx-5 flex flex-col gap-5">
          {tests.map((test) => (
            <li key={test.id}>
              <TestItem
                test={test}
                variant="blue"
                onTestAddClick={() => onTestAddClick(test)}
                showAddButton={
                  auth.isLogged
                    ? basket.items
                        .filter(isTestBasketItem)
                        .filter(({ test: { id } }) => test.id === id).length <
                      (profilesData?.client_profile.length || 0)
                    : !basket.items.find(
                        (item) =>
                          isTestBasketItem(item) && test.id === item.test.id
                      )
                }
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
