import React, { ReactNode } from 'react'

export const MessageBox: React.FC<{
  variant?: 'error' | 'success' | 'warning'
  children: ReactNode
}> = ({ children, variant = 'error' }) => (
  <div className="flex justify-center my-4">
    <p
      className={`rounded-2xl py-2 px-6 text-center ${
        variant === 'error'
          ? 'bg-red-600'
          : variant === 'success'
          ? 'bg-green-500'
          : 'bg-yellow-600'
      } text-white font-bold`}
    >
      {children}
    </p>
  </div>
)
