import { Order, Order_State_Enum_Enum } from '../generated/urql.client'
import { OrderState2Label } from '../types'

export function ellipsis(text: string) {
  return text.length > 30 ? text.substr(0, 30) + '...' : text
}

export function getOrderStateLabel(
  order: Pick<Order, 'currentState'> & {
    orderCollections: unknown[]
    orderConsultations: unknown[]
    orderTrainings: unknown[]
  }
) {
  if (!order.currentState) {
    return ''
  }
  if (!(order.currentState in OrderState2Label)) {
    return ''
  }

  if (
    order.currentState === Order_State_Enum_Enum.AwaitingConfirmation &&
    order.orderCollections.length === 0 &&
    (order.orderConsultations.length > 0 || order.orderTrainings.length > 0)
  ) {
    return 'order.state.awaiting'
  }

  return OrderState2Label[order.currentState as Order_State_Enum_Enum]
}

export function getStateColor(state?: string | null) {
  switch (state) {
    case Order_State_Enum_Enum.DifferentDateProposed:
    case Order_State_Enum_Enum.DifferentDateRejected:
    case Order_State_Enum_Enum.Unpaid:
      return '#FFD028'
    case Order_State_Enum_Enum.Finished:
      return '#71C09D'
    case Order_State_Enum_Enum.Cancelled:
      return '#ED6581'
    case Order_State_Enum_Enum.AwaitingClientConfirmation:
    case Order_State_Enum_Enum.AwaitingConfirmation:
    case Order_State_Enum_Enum.Confirmed:
    case Order_State_Enum_Enum.DifferentDateAccepted:
    case Order_State_Enum_Enum.CollectingSamples:
    case Order_State_Enum_Enum.ExaminingSamples:
    case Order_State_Enum_Enum.OnTheWayToClient:
    case Order_State_Enum_Enum.OnTheWayToLab:
    default:
      return '#8CD1F5'
  }
}
