import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import BloodlabLogo from '../assets/bloodlab-logo.svg'
import HolterDoDomuLogo from '../assets/holter-do-domu-logo.png'
import { BloodlabInterpretationLink, HolterDoDomuLink } from '../const'
import {
  GetAvailableLaboratoriesSubscription,
  useGetAvailableLaboratoriesSubscription,
  useGetAvailableTestTagsSubscription,
  useGetProfilesSubscription,
} from '../generated/urql.client'
import { guessStorageAddress } from '../lib/storage'
import { AuthContext } from '../provider/auth'
import { BasketContext } from '../provider/basket'
import { Frame } from '../components/Frame'
import { TestCategories } from '../components/TestCategories'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import IconInput from '../components/IconInput'
import { IoMdSearch } from 'react-icons/io'
import { IoClose } from 'react-icons/io5'
import { useDebouncedCallback } from 'use-debounce'
import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../lib/colors'
import { useI18n } from '../provider/i18n'
import { ConfirmDialog, ConfirmDialogType } from '../components/ConfirmDialog'

export const CatalogPage = () => {
  const basket = useContext(BasketContext)
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const [
    {
      data: availableLaboratoriesData,
      fetching: availableLaboratoriesFetching,
    },
  ] = useGetAvailableLaboratoriesSubscription({
    pause: !basket.address?.location,
    variables: {
      point: basket.address?.location || {
        type: 'Point',
        coordinates: [0, 0],
      },
      limit: 1000,
    },
  })
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [searchInput, setSearchInput] = useState(searchParams.get('q') || '')
  const confirmDialogRef = useRef<ConfirmDialogType>(null)
  const [{ data: availableTestTagsData, fetching: availableTestTagsFetching }] =
    useGetAvailableTestTagsSubscription({
      variables: {
        point: basket.address?.location || {
          type: 'Point',
          coordinates: [0, 0],
        },
      },
      pause: !basket.address,
    })

  const isServiceAvailable = useMemo(
    () =>
      availableTestTagsFetching ||
      !!availableTestTagsData?.available_test_tag.length,
    [
      availableTestTagsData?.available_test_tag.length,
      availableTestTagsFetching,
    ]
  )

  const isSearchVisible = useMemo(() => {
    return (
      isServiceAvailable &&
      (location.pathname === '/' ||
        location.pathname.startsWith('/tag') ||
        location.pathname.startsWith('/search'))
    )
  }, [location, isServiceAvailable])

  const isTagsVisible = useMemo(() => {
    return (
      isServiceAvailable &&
      (location.pathname === '/' ||
        location.pathname.startsWith('/tag') ||
        location.pathname.startsWith('/search') ||
        location.pathname.startsWith('/test'))
    )
  }, [location, isServiceAvailable])

  const isTagsVisibleScreenSm = useMemo(() => {
    return (
      isServiceAvailable &&
      (location.pathname === '/' || location.pathname.startsWith('/tag'))
    )
  }, [location, isServiceAvailable])

  const setSearchQuery = useDebouncedCallback((query: string) => {
    if (query) {
      navigate(`/search?q=${encodeURIComponent(query)}`)
    } else {
      navigate(`/`)
    }
  }, 300)

  useEffect(() => {
    if (!basket.profile && profilesData?.client_profile.length) {
      basket.setProfile(profilesData?.client_profile[0])
    }
  }, [basket, profilesData])

  useEffect(() => {
    if (auth.accessToken) {
      return
    }

    if (basket.overrideAddress) {
      return
    }
  }, [auth.accessToken, basket.overrideAddress])

  const availableLaboratoriesWithLogo = useMemo(
    () =>
      (availableLaboratoriesData?.available_laboratory || []).filter(
        (
          laboratory
        ): laboratory is GetAvailableLaboratoriesSubscription['available_laboratory'][number] & {
          logoFile: { id: number }
        } => !!laboratory.logoFile
      ),
    [availableLaboratoriesData]
  )

  return (
    <Frame
      leftClassName="w-[210px] hidden md:block"
      rightClassName="xl:w-[210px] md:hidden xl:block"
      renderLeft={isTagsVisible && <TestCategories />}
      renderRight={
        isServiceAvailable &&
        basket.address?.location && (
          <>
            <div className="flex items-center gap-1 mb-5">
              <p className="text-blue-700">
                {i18n.t('screens.catalog.partners.title')}
              </p>
              {availableLaboratoriesFetching && (
                <TailSpin color={Blue300} height={16} width={16} />
              )}
            </div>
            <ul className="flex flex-row md:flex-col gap-5 overflow-x-scroll md:overflow-visible p-5 -m-5">
              <li
                className="hl-panel h-24 flex items-center justify-center cursor-pointer min-w-40 h-24"
                onClick={() => {
                  confirmDialogRef.current?.show({
                    title: i18n.t('screens.catalog.bloodlab.dialog.title'),
                    message: i18n.t('screens.catalog.bloodlab.dialog.message'),
                    successText: i18n.t(
                      'screens.catalog.bloodlab.dialog.button.ok'
                    ),
                    cancelText: i18n.t(
                      'screens.catalog.bloodlab.dialog.button.learnMore'
                    ),
                    onCancel: () => {
                      window.open(BloodlabInterpretationLink, '_blank')
                    },
                  })
                }}
              >
                <img
                  src={BloodlabLogo}
                  alt="Bloodlab"
                  width={136}
                  height={76}
                />
              </li>
              <li
                className="hl-panel h-24 flex items-center justify-center cursor-pointer min-w-40 h-24"
                onClick={() => window.open(HolterDoDomuLink, '_blank')}
              >
                <img
                  src={HolterDoDomuLogo}
                  alt="Holter Do Domu"
                  width={136}
                  height={76}
                />
              </li>
              {availableLaboratoriesWithLogo.map((laboratory) => (
                <li
                  key={laboratory.id}
                  className="hl-panel h-24 flex items-center justify-center min-w-40 h-24"
                >
                  <img
                    alt={laboratory.name}
                    width={136}
                    height={76}
                    src={`${guessStorageAddress()}/files/${
                      laboratory.logoFile.id
                    }`}
                  />
                </li>
              ))}
            </ul>
          </>
        )
      }
    >
      {isSearchVisible && (
        <div>
          <IconInput
            leftIcon={<IoMdSearch size={24} color="#000" />}
            rightIcon={
              <IoClose
                size={24}
                color="#000"
                className={searchInput ? 'cursor-pointer' : 'hidden'}
                onClick={() => {
                  setSearchInput('')
                  setSearchQuery('')
                }}
              />
            }
            placeholder={i18n.t('screens.catalog.search.placeholder')}
            onChange={(e) => {
              setSearchInput(e.target.value)
              setSearchQuery(e.target.value)
            }}
            value={searchInput}
          />
        </div>
      )}
      {isTagsVisibleScreenSm && (
        <div className="md:hidden mt-5">
          <TestCategories />
        </div>
      )}
      {isServiceAvailable && <Outlet />}
      {!isServiceAvailable && (
        <div className="flex flex-col justify-center h-80 py-60">
          <div>
            <p className="text-center text-base my-2">
              {i18n.t('screens.catalog.index.noService.instructions.line1')}
            </p>
            <p className="text-center text-lg text-blue-700 font-bold my-2">
              {i18n.t('screens.catalog.index.noService.instructions.line2')}
            </p>
          </div>
        </div>
      )}
      <ConfirmDialog ref={confirmDialogRef} />
    </Frame>
  )
}
