import React from 'react'
import { WelcomeFrame } from '../components/WelcomeFrame'
import PatientLogo from '../assets/patient-logo.svg'
import { useI18n } from '../provider/i18n'

export const NotFoundPage: React.FC = () => {
  const i18n = useI18n()

  return (
    <>
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4">
        <div className="flex-1 flex justify-center">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <div className="flex-1 my-0 mx-auto w-full bg-blue-100 bg-opacity-50">
          <p className="text-4xl text-center font-light">
            {i18n.t('screens.notFound.message')}
          </p>
        </div>
        <div className="flex-1" />
      </WelcomeFrame>
    </>
  )
}
