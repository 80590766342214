import 'moment/locale/pl'
import 'moment/locale/en-gb'
import 'moment/locale/uk'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  GetOrderSubscription,
  Order_State_Enum_Enum,
  useAcceptDifferentDateMutation,
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useGetOrderSubscription,
  useRejectDifferentDateMutation,
} from '../../generated/urql.client'
import { getP24TransactionPanelUrl } from '../../lib/p24'
import { useI18n } from '../../provider/i18n'
import { Link } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import { Blue300, Blue600 } from '../../lib/colors'
import { MessageBox } from '../../components/MessageBox'
import { formatDateTime } from '../../lib/date'
import { getOrderStateLabel, getStateColor } from '../../lib/order'
import OrderStateIcon from '../../components/OrderStateIcon'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { BloodlabInterpretationLink } from '../../const'
import BloodlabIcon from '../../assets/bloodlab-logo.svg'
import { guessStorageAddress } from '../../lib/storage'
import { AuthContext } from '../../provider/auth'
import { Disclosure, Transition } from '@headlessui/react'
import { MdAlarmOn, MdKeyboardArrowRight, MdPerson } from 'react-icons/md'
import Markdown from 'react-markdown'
import {
  ConfirmDialog,
  ConfirmDialogType,
} from '../../components/ConfirmDialog'
import { FaExclamation } from 'react-icons/fa'
import {
  ClientConsentModal,
  ClientConsentModalType,
} from '../../components/ClientConsentModal'
import {
  OrderIdentityModal,
  OrderIdentityModalType,
} from '../../components/OrderIdentityModal'

const cancellableStates = [
  'AwaitingConfirmation',
  'Confirmed',
  'DifferentDateProposed',
  'OnTheWayToClient',
]

function isOrderCancellable(
  order: NonNullable<GetOrderSubscription['order_by_pk']>
) {
  return (
    (order.orderCollections.length > 0 ||
      order.orderConsultations.length > 0) &&
    order.orderStates[0]?.state in cancellableStates
  )
}

type OrderTest = NonNullable<
  GetOrderSubscription['order_by_pk']
>['orderTests'][number]
type OrderConsultation = NonNullable<
  GetOrderSubscription['order_by_pk']
>['orderConsultations'][number]
type OrderTraining = NonNullable<
  GetOrderSubscription['order_by_pk']
>['orderTrainings'][number]

function isOrderTest(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderTest {
  return 'test' in item
}

function isOrderConsultation(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderConsultation {
  return 'consultation' in item
}

function isOrderTraining(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderTraining {
  return 'training' in item
}

export const OrdersOrderPage = () => {
  const i18n = useI18n()
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const id = parseInt(params.id || '0')
  const [{ data: orderData, fetching: orderFetching }] =
    useGetOrderSubscription({
      variables: { id },
      pause: !id,
    })
  const [{ fetching: cancelOrderFetching }, cancelOrder] =
    useCancelOrderMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [{ fetching: acceptDifferentDateFetching }, acceptDifferentDate] =
    useAcceptDifferentDateMutation()
  const [{ fetching: rejectDifferentDateFetching }, rejectDifferentDate] =
    useRejectDifferentDateMutation()
  const confirmDialogRef = useRef<ConfirmDialogType>(null)
  const clientConsentModalRef = useRef<ClientConsentModalType>(null)
  const orderIdentityModalRef = useRef<OrderIdentityModalType>(null)
  const [, confirmOrder] = useConfirmOrderMutation()

  moment.locale(i18n.locale)

  const order = useMemo(() => orderData?.order_by_pk, [orderData])
  const orderCollection = order?.orderCollections[0]
  const items = useMemo(
    () => [
      ...(order?.orderTests || []),
      ...(order?.orderConsultations || []),
      ...(order?.orderTrainings || []),
    ],
    [order?.orderConsultations, order?.orderTests, order?.orderTrainings]
  )

  const incompleteOrderProfiles = useMemo(
    () =>
      (order?.orderTests || [])
        .filter((item) => !item.identity)
        .reduce<
          { firstName: string; lastName: string; orderTestIds: number[] }[]
        >((profiles, item) => {
          const profile = profiles.find(
            (profile) =>
              profile.firstName === item.firstName &&
              profile.lastName === item.lastName
          )

          if (profile) {
            profile.orderTestIds.push(item.id)
            return profiles
          } else {
            return [
              ...profiles,
              {
                firstName: item.firstName,
                lastName: item.lastName,
                orderTestIds: [item.id],
              },
            ]
          }
        }, []),
    [order?.orderTests]
  )

  const needsConsent = useMemo(
    () =>
      order &&
      !order.clientConsent1 &&
      !order.clientConsent2 &&
      !order.clientConsent3,
    [order]
  )

  const doConfirmOrder = useCallback(async () => {
    if (
      order?.currentState ===
        Order_State_Enum_Enum.AwaitingClientConfirmation &&
      !needsConsent &&
      !incompleteOrderProfiles.length
    ) {
      const { error } = await confirmOrder({
        orderId: order.id,
      })
      if (error) {
        setGeneralError(error.message)
        return
      }
    }
  }, [confirmOrder, incompleteOrderProfiles.length, needsConsent, order])

  const proposedDate = useMemo(
    () => order?.orderStates[0]?.extra?.proposedDate,
    [order]
  )

  const nurseLocation = useMemo(
    () =>
      order?.orderStates[0]?.extra?.location
        ? {
            lat: order.orderStates[0]?.extra?.location.coordinates[0],
            lng: order.orderStates[0]?.extra?.location.coordinates[1],
          }
        : undefined,
    [order]
  )

  const doCancelOrder = useCallback(async () => {
    if (order?.id) {
      const { error } = await cancelOrder({
        orderId: order?.id,
      })
      if (error) {
        setGeneralError(error.message)
        return
      }

      navigate('/')
    }
  }, [order, cancelOrder, navigate])

  const renderCancelAlert = useCallback(() => {
    if (moment(orderCollection?.collectionDate).diff(moment(), 'hours') < 12) {
      confirmDialogRef.current?.show({
        title: i18n.t('screens.order.cancel.alert.title'),
        message: i18n.t('screens.order.cancel.lessThan12.alert.message'),
        successText: i18n.t('common.yes'),
        cancelText: i18n.t('common.no'),
        onSuccess: doCancelOrder,
      })
    } else {
      confirmDialogRef.current?.show({
        title: i18n.t('screens.order.cancel.alert.title'),
        message: i18n.t('screens.order.cancel.alert.message'),
        successText: i18n.t('common.yes'),
        cancelText: i18n.t('common.no'),
        onSuccess: doCancelOrder,
      })
    }
  }, [doCancelOrder, i18n, orderCollection?.collectionDate])

  const doFinalizePayment = useCallback(async () => {
    if (!order?.paymentToken) {
      return
    }

    setGeneralError(undefined)
    window.location.href = getP24TransactionPanelUrl(order.paymentToken)
  }, [order])

  const canFinalizePayment = useMemo(
    () =>
      order?.paymentToken &&
      order.currentState === Order_State_Enum_Enum.Unpaid,
    [order]
  )

  const downloadFile = useCallback(
    async (id: number) => {
      try {
        const response = await fetch(`${guessStorageAddress()}/files/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })

        const blob = await response.blob()
        const dataURL = window.URL.createObjectURL(blob)
        window.location.assign(dataURL)
      } catch (error) {
        if (error instanceof Error) {
          setGeneralError(error.message)
        }
        console.error(error)
      }
    },
    [auth.accessToken]
  )

  const doAcceptDifferentDate = useCallback(async () => {
    if (!order || !proposedDate) {
      return
    }

    const { error } = await acceptDifferentDate({
      orderId: order.id,
      date: proposedDate,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }
  }, [acceptDifferentDate, order, proposedDate])

  const doRejectDifferentDate = useCallback(async () => {
    if (!order) {
      return
    }

    const { error } = await rejectDifferentDate({
      orderId: order.id,
    })
    if (error) {
      setGeneralError(error.message)
      return
    }
  }, [order, rejectDifferentDate])

  return (
    <div className="mb-10">
      <div className="text-blue-700 mb-5 flex gap-1 items-center">
        <span>
          <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
          {' > '}
          <Link to={`/orders`}>
            {i18n.t('screens.orders.order.breadcrumbs.orders')}
          </Link>
          {' > '}
          {i18n.t('screens.orders.order.breadcrumbs.order', {
            orderId: order?.id,
          })}
        </span>
        {orderFetching && <TailSpin color={Blue300} height={16} width={16} />}
      </div>

      {order && (
        <>
          <div className="mb-8 mt-5">
            <div className="flex items-center gap-2.5">
              <h1 className="text-2xl md:text-3xl font-bold">
                {i18n.t('screens.orders.order.title', {
                  orderId: order.id,
                })}
              </h1>
              {orderFetching && (
                <TailSpin color={Blue600} width={20} height={20} />
              )}
            </div>
            <p className="text-blue-700">
              {i18n.t('screens.orders.order.orderedOn')}:{' '}
              <strong>{formatDateTime(order.firstStateDate)}</strong>
            </p>
          </div>

          {generalError && <MessageBox>{generalError}</MessageBox>}

          <div className="hl-panel overflow-hidden mb-8">
            <div
              className="p-5 flex items-center justify-between"
              style={{
                backgroundColor: getStateColor(order.orderStates[0].state),
              }}
            >
              <p className="text-lg font-bold">
                {i18n.t(getOrderStateLabel(order) || '')}
                {order.currentState ===
                  Order_State_Enum_Enum.DifferentDateProposed && (
                  <>
                    :
                    <span className="rounded-xl px-2 py-1 bg-blue-100 ml-1 font-black">
                      {formatDateTime(proposedDate)}
                    </span>
                  </>
                )}
              </p>
              {order.currentState ===
              Order_State_Enum_Enum.DifferentDateProposed ? (
                <div className="flex gap-2.5">
                  <button
                    className="hl-button hl-gradient-red"
                    disabled={rejectDifferentDateFetching}
                    onClick={doRejectDifferentDate}
                  >
                    {i18n.t(
                      'screens.orders.order.differentDateProposed.revoke'
                    )}
                  </button>
                  <button
                    className="hl-button"
                    disabled={acceptDifferentDateFetching}
                    onClick={doAcceptDifferentDate}
                  >
                    {i18n.t(
                      'screens.orders.order.differentDateProposed.accept'
                    )}
                  </button>
                </div>
              ) : (
                <OrderStateIcon
                  state={order.orderStates[0].state}
                  height={20}
                  width={20}
                />
              )}
            </div>
            {order.currentState === Order_State_Enum_Enum.OnTheWayToClient &&
              nurseLocation && (
                <GoogleMap
                  mapContainerClassName="w-full h-[200px]"
                  center={nurseLocation}
                  zoom={15}
                  options={{
                    streetViewControl: false,
                    controlSize: 24,
                  }}
                >
                  <Marker position={nurseLocation} />
                </GoogleMap>
              )}
            {!!orderCollection?.nurse && (
              <div className="p-5">
                <p>
                  {i18n.t('screens.orders.order.nurse')}:{' '}
                  <strong>{orderCollection?.nurse.name}</strong>
                </p>
              </div>
            )}
            {order && isOrderCancellable(order) && (
              <div className="bg-blue-200 rounded-b-2xl flex justify-end items-center py-8 px-4">
                <button
                  className="hl-button hl-gradient-red"
                  disabled={cancelOrderFetching}
                  onClick={renderCancelAlert}
                >
                  {i18n.t('screens.orders.order.cancel.button')}
                </button>
              </div>
            )}
            {order &&
              order.currentState ===
                Order_State_Enum_Enum.AwaitingClientConfirmation && (
                <div className="bg-blue-200 rounded-b-2xl flex justify-end items-center py-8 px-4">
                  <button
                    className="hl-button hl-gradient-green"
                    disabled={needsConsent || !!incompleteOrderProfiles.length}
                    onClick={doConfirmOrder}
                  >
                    {i18n.t('screens.orders.order.confirm.button')}
                  </button>
                </div>
              )}
            {order?.currentState === Order_State_Enum_Enum.Unpaid && (
              <div className="bg-blue-200 rounded-b-2xl justify-end items-center py-8 px-4 flex gap-2.5">
                <button
                  className="hl-button hl-gradient-red"
                  disabled={cancelOrderFetching}
                  onClick={() => {
                    confirmDialogRef.current?.show({
                      message: i18n.t(
                        'screens.orders.order.remove.alert.title'
                      ),
                      successText: i18n.t('common.yes'),
                      cancelText: i18n.t('common.no'),
                      onSuccess: doCancelOrder,
                    })
                  }}
                >
                  {i18n.t('screens.orders.order.cancel.button')}
                </button>
                <button
                  className="hl-button"
                  disabled={!canFinalizePayment}
                  onClick={doFinalizePayment}
                >
                  {i18n.t('screens.orders.order.finalizePayment.button')}
                </button>
              </div>
            )}
          </div>

          {incompleteOrderProfiles.map((profile) => (
            <button
              className="hl-panel flex items-center mb-8 gap-5 w-full"
              onClick={() =>
                orderIdentityModalRef.current?.show({
                  ...profile,
                  onError: setGeneralError,
                })
              }
            >
              <div className="bg-red-500 rounded-tl-xl rounded-bl-xl p-6">
                <FaExclamation color="white" />
              </div>
              <p>
                {i18n.t(
                  'screens.orders.order.message.fillInMissingProfileData'
                )}{' '}
                <strong>
                  {profile.firstName} {profile.lastName}
                </strong>
              </p>
              <div className="mr-5 ml-auto">
                <MdKeyboardArrowRight size={24} color={Blue600} />
              </div>
            </button>
          ))}

          {needsConsent && (
            <button
              className="hl-panel flex items-center mb-8 gap-5 w-full"
              onClick={() => {
                if (order) {
                  clientConsentModalRef.current?.show({
                    orderId: order.id,
                    onError: setGeneralError,
                  })
                }
              }}
            >
              <div className="bg-red-500 rounded-tl-xl rounded-bl-xl p-6">
                <FaExclamation color="white" />
              </div>
              <p>{i18n.t('screens.orders.order.message.fillInConsents')}</p>
              <div className="mr-5 ml-auto">
                <MdKeyboardArrowRight size={24} color={Blue600} />
              </div>
            </button>
          )}

          <ul className="flex flex-col gap-8 md:mx-8">
            {items.map((item) => {
              if (isOrderTest(item)) {
                return (
                  <li key={id} className="hl-panel overflow-hidden">
                    <div className="p-5 bg-blue-200 md:flex justify-between gap-2.5">
                      <div>
                        <p className="text-lg font-bold mb-2">
                          {item.test.name || item.test.shortName}
                        </p>
                        <div className="flex gap-1 items-center">
                          <MdAlarmOn size={24} color={Blue600} />
                          <p>
                            {i18n.t(
                              'screens.orders.order.orderTests.waitingTime.label'
                            )}
                            : <strong>{item.test.waitingTime}</strong>
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-1 items-center mr-2.5">
                        <MdPerson size={24} color={Blue600} />
                        <p className="text-blue-700 font-bold">
                          {item.firstName} {item.lastName}
                        </p>
                      </div>
                    </div>
                    {!!item.test.description && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="p-5 text-base font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                              <>
                                {i18n.t(
                                  'screens.orders.order.orderTests.description.label'
                                )}
                                <MdKeyboardArrowRight
                                  className={open ? 'rotate-90 transform' : ''}
                                  size={24}
                                  color={Blue600}
                                />
                              </>
                            </Disclosure.Button>
                            <Transition
                              className="overflow-hidden"
                              enter="transition-all ease-in-out duration-[500ms]"
                              enterFrom="transform max-h-0"
                              enterTo="transform max-h-[1000px]"
                              leave="transition-all ease-in-out duration-[500ms]"
                              leaveFrom="transform max-h-[1000px]"
                              leaveTo="transform max-h-0"
                            >
                              <Disclosure.Panel className="p-6 border-b border-b-border border-opacity-20">
                                <Markdown className="markdown">
                                  {item.test.description}
                                </Markdown>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {!!item.test.preparation && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="p-5 text-base font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                              <>
                                {i18n.t(
                                  'screens.orders.order.orderTests.preparation.label'
                                )}
                                <MdKeyboardArrowRight
                                  className={open ? 'rotate-90 transform' : ''}
                                  size={24}
                                  color={Blue600}
                                />
                              </>
                            </Disclosure.Button>
                            <Transition
                              className="overflow-hidden"
                              enter="transition-all ease-in-out duration-[500ms]"
                              enterFrom="transform  max-h-0"
                              enterTo="transform  max-h-[1000px]"
                              leave="transition-all ease-in-out duration-[500ms]"
                              leaveFrom="transform  max-h-[1000px]"
                              leaveTo="transform  max-h-0"
                            >
                              <Disclosure.Panel className="p-6 border-b border-b-border border-opacity-20">
                                <Markdown>{item.test.preparation}</Markdown>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {item.testResults.map(
                      ({ date, criticalParameter, file }) => (
                        <div
                          key={file.id}
                          className="p-5 bg-green-100 border-b border-b-border border-opacity-20"
                        >
                          <button
                            onClick={() => downloadFile(file.id)}
                            className="flex items-center w-full text-left"
                          >
                            <div>
                              <p className="font-bold text-base">
                                {criticalParameter ? '❗ ' : undefined}
                                {i18n.t(
                                  'screens.orders.order.orderTests.testResult.label'
                                )}
                              </p>
                              <p>
                                Data: <strong>{formatDateTime(date)}</strong>
                              </p>
                              <p className="text-red-700 text-xs">
                                {i18n.t(
                                  'screens.orders.order.orderTests.testResult.criticalParameter'
                                )}
                              </p>
                            </div>
                            <MdKeyboardArrowRight
                              size={24}
                              color={Blue600}
                              className="ml-auto"
                            />
                          </button>
                        </div>
                      )
                    )}
                    {item.testResults.length > 0 && item.test.bloodlabEligible && (
                      <div className="p-5">
                        <button
                          className="flex items-center w-full text-left"
                          onClick={() => {
                            confirmDialogRef.current?.show({
                              title: i18n.t(
                                'screens.orders.order.orderTests.bloodlab.dialog.title'
                              ),
                              message: i18n.t(
                                'screens.orders.order.orderTests.bloodlab.dialog.message'
                              ),
                              successText: i18n.t(
                                'screens.orders.order.orderTests.bloodlab.dialog.button.go'
                              ),
                              cancelText: i18n.t('common.cancel'),
                              onSuccess: () => {
                                window.open(
                                  BloodlabInterpretationLink,
                                  '_blank'
                                )
                              },
                            })
                          }}
                        >
                          <p className="text-base font-bold flex items-center gap-1.5">
                            <span>
                              {i18n.t(
                                'screens.orders.order.orderTests.bloodlab.label'
                              )}
                            </span>
                            <img
                              src={BloodlabIcon}
                              alt="Bloodlab"
                              width={100}
                              height={16}
                              className="mb-1"
                            />
                          </p>
                          <MdKeyboardArrowRight
                            size={24}
                            color={Blue600}
                            className="ml-auto"
                          />
                        </button>
                      </div>
                    )}
                  </li>
                )
              } else if (isOrderConsultation(item)) {
                return (
                  <li key={id} className="hl-panel overflow-hidden">
                    <div className="p-5 bg-blue-200 md:flex justify-between gap-2.5">
                      <div>
                        <p className="text-lg font-bold mb-2">
                          {item.consultation.name}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center mr-2.5">
                        <MdPerson size={24} color={Blue600} />
                        <p className="text-blue-700 font-bold">
                          {item.firstName} {item.lastName}
                        </p>
                      </div>
                    </div>
                    {!!item.consultation.description && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="p-5 text-base font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                              <>
                                {i18n.t(
                                  'screens.orders.order.orderConsultations.description.label'
                                )}
                                <MdKeyboardArrowRight
                                  className={open ? 'rotate-90 transform' : ''}
                                  size={24}
                                  color={Blue600}
                                />
                              </>
                            </Disclosure.Button>
                            <Transition
                              className="overflow-hidden"
                              enter="transition-all ease-in-out duration-[500ms]"
                              enterFrom="transform max-h-0"
                              enterTo="transform max-h-[1000px]"
                              leave="transition-all ease-in-out duration-[500ms]"
                              leaveFrom="transform max-h-[1000px]"
                              leaveTo="transform max-h-0"
                            >
                              <Disclosure.Panel className="p-6 border-b border-b-border border-opacity-20">
                                <Markdown className="markdown">
                                  {item.consultation.description}
                                </Markdown>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                )
              } else if (isOrderTraining(item)) {
                return (
                  <li key={id} className="hl-panel overflow-hidden">
                    <div className="p-5 bg-blue-200 md:flex justify-between gap-2.5">
                      <div>
                        <p className="text-lg font-bold mb-2">
                          {item.training.name}
                        </p>
                      </div>
                    </div>
                    {!!item.training.description && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="p-5 text-base font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                              <>
                                {i18n.t(
                                  'screens.orders.order.orderTrainings.description.label'
                                )}
                                <MdKeyboardArrowRight
                                  className={open ? 'rotate-90 transform' : ''}
                                  size={24}
                                  color={Blue600}
                                />
                              </>
                            </Disclosure.Button>
                            <Transition
                              className="overflow-hidden"
                              enter="transition-all ease-in-out duration-[500ms]"
                              enterFrom="transform max-h-0"
                              enterTo="transform max-h-[1000px]"
                              leave="transition-all ease-in-out duration-[500ms]"
                              leaveFrom="transform max-h-[1000px]"
                              leaveTo="transform max-h-0"
                            >
                              <Disclosure.Panel className="p-6 border-b border-b-border border-opacity-20">
                                <Markdown className="markdown">
                                  {item.training.description}
                                </Markdown>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {!!item.training.videoFile?.id && (
                      <button
                        onClick={() =>
                          navigate(`/training/${item.training.id}`)
                        }
                        className="bg-green-100 p-5 text-base font-bold flex items-center justify-between w-full border-b border-b-border border-opacity-20"
                      >
                        <span className="mr-auto">
                          {i18n.t(
                            'screens.orders.order.orderTrainings.play.label'
                          )}
                        </span>
                        <div
                          className={`min-h-16 min-w-24 bg-cover bg-center mr-6`}
                          style={{
                            backgroundImage: `url(${guessStorageAddress()}/files/${
                              item.training.previewFile.id
                            })`,
                          }}
                        />
                        <MdKeyboardArrowRight size={24} color={Blue600} />
                      </button>
                    )}
                  </li>
                )
              } else {
                return <li>Unknown item</li>
              }
            })}
          </ul>
        </>
      )}
      <ConfirmDialog ref={confirmDialogRef} />
      <ClientConsentModal ref={clientConsentModalRef} />
      <OrderIdentityModal ref={orderIdentityModalRef} />
    </div>
  )
}
