import React from 'react'

export function useInjectStyle(cssString: string) {
  React.useEffect(() => {
    const style = document.createElement('style')
    style.textContent = cssString
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [cssString])
}
