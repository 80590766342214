import ConfirmedIcon from '../assets/confirmed.svg'
import DoneIcon from '../assets/done.svg'
import DropIcon from '../assets/drop.svg'
import ErrorIcon from '../assets/error.svg'
import ProcessedIcon from '../assets/processed.svg'
import SearchIcon from '../assets/search-inverse.svg'
import SuitcaseIcon from '../assets/suitcase.svg'
import WaitingIcon from '../assets/waiting.svg'
import WarningIcon from '../assets/warning.svg'
import { Order_State_Enum_Enum } from '../generated/urql.client'

export default function OrderStateIcon({
  state,
  width,
  height,
}: {
  state: Order_State_Enum_Enum
  width: number
  height?: number
}) {
  switch (state) {
    case Order_State_Enum_Enum.DifferentDateProposed:
    case Order_State_Enum_Enum.DifferentDateRejected:
    case Order_State_Enum_Enum.Unpaid:
      return <img src={WarningIcon} width={width} height={height} />
    case Order_State_Enum_Enum.AwaitingClientConfirmation:
    case Order_State_Enum_Enum.AwaitingConfirmation:
      return <img src={WaitingIcon} width={width} height={height} />
    case Order_State_Enum_Enum.DifferentDateAccepted:
    case Order_State_Enum_Enum.Confirmed:
      return <img src={ConfirmedIcon} width={width} height={height} />
    case Order_State_Enum_Enum.Finished:
      return <img src={DoneIcon} width={width} height={height} />
    case Order_State_Enum_Enum.Cancelled:
      return <img src={ErrorIcon} width={width} height={height} />
    case Order_State_Enum_Enum.CollectingSamples:
      return <img src={DropIcon} width={width} height={height} />
    case Order_State_Enum_Enum.ExaminingSamples:
      return <img src={SearchIcon} width={width} height={height} />
    case Order_State_Enum_Enum.OnTheWayToClient:
      return <img src={ProcessedIcon} width={width} height={height} />
    case Order_State_Enum_Enum.OnTheWayToLab:
      return <img src={SuitcaseIcon} width={width} height={height} />
  }
}
