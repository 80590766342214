import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import TimeAgo from 'javascript-time-ago'
import pl from 'javascript-time-ago/locale/pl.json'
import en from 'javascript-time-ago/locale/en.json'
import uk from 'javascript-time-ago/locale/uk.json'
import { GoogleApiKey } from './const'
import { Helmet } from 'react-helmet'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(pl)
TimeAgo.addLocale(uk)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    {/* @ts-ignore */}
    <Helmet>
      {' '}
      <script
        defer
        async
        src={`https://maps.googleapis.com/maps/api/js?key=${GoogleApiKey}&libraries=places&loading=async&callback=GoogleMapsApiLoaded`}
      ></script>
    </Helmet>
    <App />
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
