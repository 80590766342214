import React, { useCallback, useContext, useMemo } from 'react'
import {
  GetAvailableTestTagsSubscription,
  useGetAvailableTestTagsSubscription,
} from '../generated/urql.client'
import { BasketContext } from '../provider/basket'
import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../lib/colors'
import { Link, useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import { useI18n } from '../provider/i18n'

const TagPill: React.FC<{
  tag: GetAvailableTestTagsSubscription['available_test_tag'][number]['Tag']
  isSelected?: boolean
}> = ({ tag, isSelected }) => (
  <div className="inline-flex max-w-full">
    <Link
      to={`/tag/${encodeURIComponent(tag.id)}`}
      title={tag.tag || '-'}
      className={`${
        isSelected
          ? 'bg-blue-700 text-white hover:text-blue-300'
          : 'bg-blue-300 text-blue-900 hover:text-blue-700'
      } block rounded-2xl py-1.5 px-3.5 font-bold no-underline overflow-hidden overflow-ellipsis whitespace-nowrap`}
    >
      {tag.tag || '-'}
    </Link>
  </div>
)

export const TestCategories = () => {
  const basket = useContext(BasketContext)
  const location = useLocation()
  const i18n = useI18n()
  const params = useParams()
  const [{ data: availableTestTagsData, fetching: availableTestTagsFetching }] =
    useGetAvailableTestTagsSubscription({
      variables: {
        point: basket.address?.location || {
          type: 'Point',
          coordinates: [0, 0],
        },
      },
      pause: !basket.address,
    })

  const selectedTagId = useMemo(() => {
    if (location.pathname.includes('/tag/') && params.tagId) {
      return params.tagId
    }

    if (location.state?.tagId) {
      return location.state.tagId
    }

    return
  }, [location, params])

  const availableTestTags = availableTestTagsData?.available_test_tag || []

  return (
    <>
      <div className="flex items-center gap-1 mb-5">
        <p className="text-blue-700">
          {i18n.t('components.testCategories.title')}
        </p>
        {availableTestTagsFetching && (
          <TailSpin color={Blue300} height={16} width={16} />
        )}
      </div>

      <ul className="hidden md:block">
        {availableTestTags.map(({ Tag: tag }) => (
          <li key={tag.id} className="my-2.5">
            <TagPill tag={tag} isSelected={tag.id === selectedTagId} />
          </li>
        ))}
      </ul>

      <div className="flex md:hidden flex-col gap-2.5 overflow-x-scroll p-5 -m-5">
        <ul className="flex gap-2.5">
          {availableTestTags
            .filter((_, i) => i % 3 === 0)
            .map(({ Tag: tag }) => (
              <li key={tag.id}>
                <TagPill tag={tag} isSelected={tag.id === selectedTagId} />
              </li>
            ))}
        </ul>{' '}
        <ul className="flex gap-2.5">
          {availableTestTags
            .filter((_, i) => i % 3 === 1)
            .map(({ Tag: tag }) => (
              <li key={tag.id}>
                <TagPill tag={tag} isSelected={tag.id === selectedTagId} />
              </li>
            ))}
        </ul>{' '}
        <ul className="flex gap-2.5">
          {availableTestTags
            .filter((_, i) => i % 3 === 2)
            .map(({ Tag: tag }) => (
              <li key={tag.id}>
                <TagPill tag={tag} isSelected={tag.id === selectedTagId} />
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}
