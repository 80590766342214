export const RegulationLinks: Record<
  'privacyPolicy' | 'regulation' | 'instructions',
  Record<string, string>
> = {
  privacyPolicy: {
    pl: 'https://api.homelab24.pl/uploads/homelab_client_privacy_policy_pl_c580c40d26.pdf',
    uk: 'https://api.homelab24.pl/uploads/homelab_client_privacy_policy_uk_e0a496a5bb.pdf',
    en: 'https://api.homelab24.pl/uploads/homelab_client_privacy_policy_en_GB_94aa190a06.pdf',
  },
  regulation: {
    pl: 'https://api.homelab24.pl/uploads/homelab_client_regulation_pl_102c42e29d.pdf',
    uk: 'https://api.homelab24.pl/uploads/homelab_client_regulation_uk_c4f558f29a.pdf',
    en: 'https://api.homelab24.pl/uploads/homelab_client_regulation_en_GB_3cc4365333.pdf',
  },
  instructions: {
    pl: 'https://api.homelab24.pl/uploads/Home_Lab_instructions_pl_8d0725af1f.pdf',
    en: 'https://api.homelab24.pl/uploads/home_Lab_instructions_en_18b6ae8f87.pdf',
  },
}
