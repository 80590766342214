export type Time = `${number}:${number}:${number}`
export type Interval = [Time, Time]

export function isTime(str: string): str is Time {
  return !!str.match(/^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/)
}

export function toShortFormat(time: Time) {
  const [hours, minutes] = time.split(':')
  return `${hours[0] === '0' ? hours[1] : hours}:${minutes}`
}
