import { TailSpin } from 'react-loader-spinner'
import { Blue300 } from '../../lib/colors'
import { TestItem } from '../../components/TestItem'
import React, { useCallback, useContext, useRef } from 'react'
import {
  SearchTestFragment,
  useGetAvailableTestsMostPopularSubscription,
  useGetClientFavouriteTestsSubscription,
  useGetProfilesSubscription,
} from '../../generated/urql.client'
import {
  ConsultationFragment,
  TrainingFragment,
  useGetConsultationsSubscription,
  useGetTrainingsSubscription,
} from '../../generated/urql.anonymous'
import {
  BasketContext,
  isConsultationBasketItem,
  isTestBasketItem,
  isTrainingBasketItem,
} from '../../provider/basket'
import { useI18n } from '../../provider/i18n'
import { AuthContext } from '../../provider/auth'
import ProfileSelectorModal, {
  ProfileSelectorModalType,
} from '../../components/ProfileSelectorModal'
import { ConsultationItem } from '../../components/ConsultationItem'
import { TrainingItem } from '../../components/TrainingItem'

export const CatalogIndexPage: React.FC<{}> = () => {
  const basket = useContext(BasketContext)
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const [{ data: mostPopularTestsData, fetching: mostPopularTestsFetching }] =
    useGetAvailableTestsMostPopularSubscription({
      pause: !basket.address?.location,
      variables: {
        point: basket.address?.location || {
          type: 'Point',
          coordinates: [0, 0],
        },
        limit: 1000,
      },
    })
  const [{ data: consultationsData, fetching: consultationsFetching }] =
    useGetConsultationsSubscription()
  const [{ data: trainingsData, fetching: trainingsFetching }] =
    useGetTrainingsSubscription()
  const [{ data: profilesData }] = useGetProfilesSubscription({
    pause: !auth.isLogged,
  })
  const [
    { data: clientFavouriteTestsData, fetching: clientFavouriteTestsFetching },
  ] = useGetClientFavouriteTestsSubscription({
    variables: {
      point: basket.address?.location || { type: 'Point', coordinates: [0, 0] },
    },
    pause: !auth.isLogged,
  })
  const profileSelectorModalRef = useRef<ProfileSelectorModalType>(null)

  const mostPopularTests = mostPopularTestsData?.search_test_most_popular || []
  const clientFavouriteTests =
    clientFavouriteTestsData?.client_favourite_test || []
  const consultations = consultationsData?.consultation || []
  const trainings = trainingsData?.training || []

  const onTestAddClick = useCallback(
    (test: SearchTestFragment) => {
      const profilesCount = profilesData?.client_profile.length || 0

      if (!auth.isLogged || profilesCount === 0) {
        basket.addItem({ test })
      } else if (profilesCount === 1) {
        basket.addItem({ test, profile: profilesData?.client_profile[0] })
      } else {
        profileSelectorModalRef.current?.show({
          onChange: (profile) => {
            basket.addItem({ test, profile })
          },
          filter: (profile) =>
            !basket.items.find(
              (item) =>
                isTestBasketItem(item) &&
                item.test.id === test.id &&
                item.profile?.id === profile.id
            ),
        })
      }
    },
    [auth.isLogged, basket, profilesData?.client_profile]
  )

  const onConsultationAddClick = useCallback(
    (consultation: ConsultationFragment) => {
      const profilesCount = profilesData?.client_profile.length || 0

      if (!auth.isLogged || profilesCount === 0) {
        basket.addItem({ consultation })
      } else if (profilesCount === 1) {
        basket.addItem({
          consultation,
          profile: profilesData?.client_profile[0],
        })
      } else {
        profileSelectorModalRef.current?.show({
          onChange: (profile) => {
            basket.addItem({ consultation, profile })
          },
          filter: (profile) =>
            !basket.items.find(
              (item) =>
                isConsultationBasketItem(item) &&
                item.consultation.id === consultation.id &&
                item.profile?.id === profile.id
            ),
        })
      }
    },
    [auth.isLogged, basket, profilesData?.client_profile]
  )

  const onTrainingAddClick = useCallback(
    (training: TrainingFragment) => {
      basket.addItem({ training })
    },
    [basket]
  )

  return (
    <div className="mb-16">
      {consultations.length > 0 && (
        <>
          <div className="text-blue-700 my-5 flex items-center gap-1">
            {i18n.t('screens.catalog.index.consultations.header')}
            {consultationsFetching && (
              <TailSpin color={Blue300} height={16} width={16} />
            )}
          </div>
          <ul className="md:mx-5 flex flex-col gap-5 mb-10">
            {consultations.map((consultation) => (
              <li key={consultation.id}>
                <ConsultationItem
                  consultation={consultation}
                  onConsultationAddClick={() =>
                    onConsultationAddClick(consultation)
                  }
                  showAddButton={
                    auth.isLogged
                      ? basket.items
                          .filter(isConsultationBasketItem)
                          .filter(
                            ({ consultation: { id } }) => consultation.id === id
                          ).length < (profilesData?.client_profile.length || 0)
                      : !basket.items.find(
                          (item) =>
                            isConsultationBasketItem(item) &&
                            consultation.id === item.consultation.id
                        )
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {trainings.length > 0 && (
        <>
          <div className="text-blue-700 my-5 flex items-center gap-1">
            {i18n.t('screens.catalog.index.trainings.header')}
            {trainingsFetching && (
              <TailSpin color={Blue300} height={16} width={16} />
            )}
          </div>
          <ul className="md:mx-5 flex flex-col gap-5 mb-10">
            {trainings.map((training) => (
              <li key={training.id}>
                <TrainingItem
                  training={training}
                  onTrainingAddClick={() => onTrainingAddClick(training)}
                  showAddButton={
                    !training.videoFile &&
                    !basket.items.find(
                      (item) =>
                        isTrainingBasketItem(item) &&
                        training.id === item.training.id
                    )
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {basket.address?.location && (
        <>
          <div className="text-blue-700 my-5 flex items-center gap-1">
            {i18n.t('screens.catalog.index.mostPopular.header')}
            {mostPopularTestsFetching && (
              <TailSpin color={Blue300} height={16} width={16} />
            )}
          </div>
          <ul className="md:mx-5 flex flex-col gap-5 mb-10">
            {mostPopularTests.map((test) => (
              <li key={test.id}>
                <TestItem
                  test={test}
                  variant="green"
                  onTestAddClick={() => onTestAddClick(test)}
                  showAddButton={
                    auth.isLogged
                      ? basket.items
                          .filter(isTestBasketItem)
                          .filter(({ test: { id } }) => test.id === id).length <
                        (profilesData?.client_profile.length || 0)
                      : !basket.items.find(
                          (item) =>
                            isTestBasketItem(item) && test.id === item.test.id
                        )
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {(clientFavouriteTests.length > 0 || clientFavouriteTestsFetching) && (
        <>
          <div className="text-blue-700 mb-5 flex items-center gap-1">
            {i18n.t('screens.catalog.index.favourite.header')}
            {clientFavouriteTestsFetching && (
              <TailSpin color={Blue300} height={16} width={16} />
            )}
          </div>
          <ul className="md:mx-5 flex flex-col gap-5 mb-10">
            {clientFavouriteTests.map(({ test }) => (
              <li key={test.id}>
                <TestItem
                  test={test}
                  onTestAddClick={() => onTestAddClick(test)}
                  showAddButton={
                    auth.isLogged
                      ? basket.items
                          .filter(isTestBasketItem)
                          .filter(({ test: { id } }) => test.id === id).length <
                        (profilesData?.client_profile.length || 0)
                      : !basket.items.find(
                          (item) =>
                            isTestBasketItem(item) && test.id === item.test.id
                        )
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}

      <ProfileSelectorModal ref={profileSelectorModalRef} />
    </div>
  )
}
