import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useRegisterFinishMutation } from '../generated/urql.anonymous'
import { useI18n } from '../provider/i18n'
import PatientLogo from '../assets/patient-logo.svg'
import { WelcomeFrame } from '../components/WelcomeFrame'
import IconInput from '../components/IconInput'
import { LuFormInput } from 'react-icons/lu'
import { Blue300 } from '../lib/colors'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { MessageBox } from '../components/MessageBox'

export const RegisterFinishPage = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const i18n = useI18n()
  const navigate = useNavigate()
  const [{ fetching: registerFinishFetching }, registerFinish] =
    useRegisterFinishMutation()
  const [generalError, setGeneralError] = useState<string>()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: searchParams.get('code') || '',
    },
  })

  useEffect(() => {
    setValue('code', searchParams.get('code') || '')
  }, [setValue, searchParams])

  const doRegisterFinish = useCallback(
    async ({ code }: { code: string }) => {
      const email = searchParams.get('email') || location.state?.email
      if (!email) throw new Error('Email is required')

      const { data, error } = await registerFinish({
        email,
        code,
      })

      if (error) {
        switch (error.graphQLErrors[0].extensions.code) {
          case 'missing-token':
            setGeneralError(
              i18n.t('screens.registerFinish.generalError.missingToken')
            )
            break
          case 'invalid-code':
            setGeneralError(
              i18n.t('screens.registerFinish.generalError.invalidCode')
            )
            break
          case 'client-not-found':
            setGeneralError(
              i18n.t('screens.registerFinish.generalError.clientNotFound')
            )
            break
          default:
            setGeneralError(error.message)
        }
      }

      if (data?.client_register_finish) {
        navigate(`/login`, { state: { email } })
      }
    },
    [registerFinish, i18n, searchParams, navigate, location.state?.email]
  )

  useEffect(() => {
    const code = searchParams.get('code')
    if (code) {
      doRegisterFinish({ code }).catch(console.error)
    }
  }, [searchParams, doRegisterFinish])

  if (searchParams.get('code')) {
    return (
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4 px-5">
        <div className="my-5">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <p className="text-center">
          {i18n.t('screens.registerFinish.message.confirmingRegistration')}
        </p>
        {generalError && <MessageBox>{generalError}</MessageBox>}
      </WelcomeFrame>
    )
  }

  return (
    <WelcomeFrame className="flex flex-col items-center justify-around pt-4">
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="my-5">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <p>{i18n.t('screens.registerFinish.message.codeSentToEmail')}</p>
      </div>
      <div className="flex-1 my-0 mx-auto w-[320px] bg-blue-100 bg-opacity-50">
        {generalError && <MessageBox>{generalError}</MessageBox>}
        <form onSubmit={handleSubmit(doRegisterFinish)}>
          <div className="mx-4">
            <div className="my-4">
              <IconInput
                leftIcon={<LuFormInput size={24} color={Blue300} />}
                {...register('code', {
                  required: i18n.t(
                    'screens.registerFinish.form.code.rules.required'
                  ),
                })}
                placeholder={i18n.t(
                  'screens.registerFinish.form.code.placeholder'
                )}
              />
              {errors.code && (
                <p className="hl-input-error">{errors.code.message}</p>
              )}
            </div>
          </div>
          <div className="my-4">
            <button
              className="hl-button-lg"
              type="submit"
              disabled={registerFinishFetching}
            >
              {i18n.t('screens.registerFinish.form.submit.button')}
            </button>
          </div>
        </form>
        <p className="m-4 text-center">
          <Link to={`/login`}>
            {i18n.t('screens.registerFinish.links.login.text')}
          </Link>
        </p>
      </div>
    </WelcomeFrame>
  )
}
