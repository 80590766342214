import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import {
  usePasswordResetFinishMutation,
  usePasswordResetInitMutation,
} from '../generated/urql.anonymous'
import { PasswordRegex } from '../lib/patterns'
import { useRecaptcha } from '../lib/recaptcha'
import { useI18n } from '../provider/i18n'
import PatientLogo from '../assets/patient-logo.svg'
import { WelcomeFrame } from '../components/WelcomeFrame'
import IconInput from '../components/IconInput'
import LockIcon from '../assets/lock.svg'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Blue300 } from '../lib/colors'
import { LuFormInput } from 'react-icons/lu'
import { TbRefresh } from 'react-icons/tb'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { MessageBox } from '../components/MessageBox'
import { ConfirmDialog, ConfirmDialogType } from '../components/ConfirmDialog'

export const ResetPasswordFinishPage = () => {
  const i18n = useI18n()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [{ fetching }, passwordResetFinish] = usePasswordResetFinishMutation()
  const [, passwordResetInit] = usePasswordResetInitMutation()
  const getRecaptchaToken = useRecaptcha()
  const [generalError, setGeneralError] = useState<string>()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: searchParams.get('code') || '',
      password: '',
      password2: '',
    },
  })
  const [revealPassword, setRevealPassword] = useState(false)
  const confirmDialogRef = useRef<ConfirmDialogType>(null)

  useEffect(() => {
    setValue('code', searchParams.get('code') || '')
  }, [setValue, searchParams])

  async function doPasswordResetFinish({
    code,
    password,
  }: {
    code: string
    password: string
    password2: string
  }) {
    if (!searchParams.get('email')) throw new Error('Email is required')

    const { data, error } = await passwordResetFinish({
      password,
      email: searchParams.get('email') || location.state?.email || '',
      code,
    })

    if (error) {
      switch (error.graphQLErrors[0].extensions.code) {
        case '400':
          setGeneralError(
            i18n.t('screens.resetPasswordFinish.generalError.missingCode')
          )
          break
        case '403':
          setGeneralError(
            i18n.t('screens.resetPasswordFinish.generalError.invalidCode')
          )
          break
        case '404':
          setGeneralError(
            i18n.t('screens.resetPasswordFinish.generalError.userNotFound')
          )
          break
        default:
          setGeneralError(error.message)
      }
    }

    if (data?.client_password_reset_finish) {
      navigate(`/login`, {
        state: { email: searchParams.get('email') || location.state?.email },
      })
    }
  }

  const doPasswordResetInit = useCallback(
    async (params: { email: string; recaptchaToken: string }) => {
      setGeneralError(undefined)

      const { error } = await passwordResetInit(params)

      if (error) {
        setGeneralError(error.message)
        return
      }
    },
    [passwordResetInit]
  )

  const resendEmailCode = useCallback(async () => {
    if (!(searchParams.get('email') || location.state?.email))
      throw new Error('Email is required')

    confirmDialogRef.current?.show({
      message: i18n.t(
        'screens.resetPasswordFinish.resendEmailCode.prompt.title'
      ),
      onSuccess: async () => {
        const recaptchaToken = await getRecaptchaToken({
          action: 'password_reset/init',
        })

        if (!recaptchaToken) {
          setGeneralError(
            i18n.t('screens.resetPasswordFinish.error.recaptcha-error')
          )
          return
        }

        await doPasswordResetInit({
          email: searchParams.get('email') || location.state?.email || '',
          recaptchaToken,
        })

        window.alert(
          i18n.t(
            'screens.resetPasswordFinish.resendEmailCode.confirmation.message'
          )
        )
      },
    })
  }, [
    doPasswordResetInit,
    getRecaptchaToken,
    i18n,
    location.state?.email,
    searchParams,
  ])

  return (
    <WelcomeFrame className="flex flex-col items-center justify-around pt-4 px-5">
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="my-5">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <p className="text-center">
          {i18n.t('screens.resetPasswordFinish.success')}
        </p>
      </div>
      <div className="flex-1 my-0 mx-auto w-[320px] bg-blue-100 bg-opacity-50">
        {generalError && <MessageBox>{generalError}</MessageBox>}
        <form onSubmit={handleSubmit(doPasswordResetFinish)}>
          <div className="mx-4">
            <div className="my-4">
              <IconInput
                leftIcon={<LuFormInput size={24} color={Blue300} />}
                rightIcon={
                  <TbRefresh
                    className="cursor-pointer"
                    size={24}
                    color={Blue300}
                    onClick={resendEmailCode}
                  />
                }
                {...register('code', {
                  required: i18n.t(
                    'screens.resetPasswordFinish.form.code.rules.required'
                  ),
                })}
                placeholder={i18n.t(
                  'screens.resetPasswordFinish.form.code.placeholder'
                )}
              />
              {errors.code && (
                <p className="hl-input-error">{errors.code.message}</p>
              )}
            </div>

            <div className="my-4">
              <IconInput
                leftIcon={
                  <img src={LockIcon} width={24} height={24} alt="Lock icon" />
                }
                rightIcon={
                  revealPassword ? (
                    <FaEyeSlash
                      className="cursor-pointer"
                      size={24}
                      color={Blue300}
                      onClick={() => setRevealPassword(!revealPassword)}
                    />
                  ) : (
                    <FaEye
                      className="cursor-pointer"
                      size={24}
                      color={Blue300}
                      onClick={() => setRevealPassword(!revealPassword)}
                    />
                  )
                }
                {...register('password', {
                  required: i18n.t(
                    'screens.resetPasswordFinish.form.password.rules.required'
                  ),
                  pattern: {
                    value: PasswordRegex,
                    message: i18n.t(
                      'screens.resetPasswordFinish.form.password.rules.pattern'
                    ),
                  },
                })}
                type={revealPassword ? 'text' : 'password'}
                placeholder={i18n.t(
                  'screens.resetPasswordFinish.form.password.placeholder'
                )}
              />
              {errors.password && (
                <p className="hl-input-error">{errors.password.message}</p>
              )}
            </div>
            <div className="my-4">
              <IconInput
                leftIcon={
                  <img src={LockIcon} width={24} height={24} alt="Lock icon" />
                }
                rightIcon={
                  revealPassword ? (
                    <FaEyeSlash
                      className="cursor-pointer"
                      size={24}
                      color={Blue300}
                      onClick={() => setRevealPassword(!revealPassword)}
                    />
                  ) : (
                    <FaEye
                      className="cursor-pointer"
                      size={24}
                      color={Blue300}
                      onClick={() => setRevealPassword(!revealPassword)}
                    />
                  )
                }
                {...register('password2', {
                  required: i18n.t(
                    'screens.resetPasswordFinish.form.password2.rules.required'
                  ),
                  validate: (value) =>
                    value !== watch('password')
                      ? i18n.t(
                          'screens.resetPasswordFinish.form.password2.rules.mismatch'
                        )
                      : undefined,
                })}
                type={revealPassword ? 'text' : 'password'}
                placeholder={i18n.t(
                  'screens.resetPasswordFinish.form.password2.placeholder'
                )}
              />
              {errors.password2 && (
                <p className="hl-input-error">{errors.password2.message}</p>
              )}
            </div>
          </div>
          <div className="my-4">
            <button className="hl-button-lg" type="submit" disabled={fetching}>
              {i18n.t('screens.resetPasswordFinish.form.submit.button')}
            </button>
          </div>
        </form>
        <p className="m-4 text-center">
          <Link to="/login">
            {i18n.t('screens.resetPasswordFinish.links.login')}
          </Link>{' '}
          |{' '}
          <Link to={`/register`}>
            {i18n.t('screens.resetPasswordFinish.links.register')}
          </Link>
        </p>
      </div>
      <ConfirmDialog ref={confirmDialogRef} />
    </WelcomeFrame>
  )
}
