import React, {
  forwardRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import ProfileSelect from '../components/ProfileSelect'
import { GetProfilesSubscription } from '../generated/urql.client'
import { Point } from '../lib/geography'
import { AuthContext } from '../provider/auth'
import { BasketContext } from '../provider/basket'
import { useI18n } from '../provider/i18n'
import { Dialog, Transition } from '@headlessui/react'
import { Blue300, Green600 } from '../lib/colors'
import IconInput from './IconInput'
import { MdOutlineSmartphone } from 'react-icons/md'
import Switch from 'react-switch'
import { AddressSelect } from './AddressSelect'
import { Link } from 'react-router-dom'

export type AddressSelectorModalType = {
  show: () => void
}

export const AddressSelectorModal = forwardRef<AddressSelectorModalType>(
  (_, ref) => {
    const basket = useContext(BasketContext)
    const auth = useContext(AuthContext)
    const i18n = useI18n()
    const [overrideAddress, setOverrideAddress] = useState(
      !!basket.overrideAddress
    )
    const [show, setShow] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState<
      GetProfilesSubscription['client_profile'][number] | undefined
    >(basket.profile)

    useImperativeHandle(ref, () => ({
      show: () => {
        setShow(true)
      },
    }))

    const isProfileComplete = !!basket.profile?.address

    const {
      handleSubmit,
      reset,
      register,
      formState: { errors },
      setValue,
      watch,
    } = useForm<{
      phoneNumber: string
      address: string
      postalCode: string
      city: string
      location: Point
    }>({
      defaultValues: {
        phoneNumber: selectedProfile?.phoneNumber,
        address: selectedProfile?.address || '',
        postalCode: selectedProfile?.postalCode || '',
        city: selectedProfile?.city || '',
        location: undefined,
      },
    })

    useEffect(() => {
      reset({
        address: basket.address?.address || undefined,
        postalCode: basket.address?.postalCode || undefined,
        phoneNumber: basket.address?.phoneNumber || undefined,
        city: basket.address?.city || undefined,
      })
    }, [reset, basket.address])

    const doSubmit = useCallback(
      async (address: {
        phoneNumber?: string
        address: string
        postalCode: string
        city: string
        location: Point
      }) => {
        basket.setProfile(selectedProfile)
        if (auth.isLogged ? overrideAddress : true) {
          basket.setOverrideAddress({ ...address })
        }
        reset()
        setShow(false)
      },
      [basket, selectedProfile, overrideAddress, reset]
    )

    const doCancel = useCallback(() => {
      reset()
      setShow(false)
    }, [setShow, reset])

    if (!show) return null

    return (
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={show}
          onClose={doCancel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Title className="text-lg mb-2.5 text-blue-700">
                    {i18n.t('components.addressSelectorModal.title')}
                  </Dialog.Title>

                  {auth.isLogged && (
                    <>
                      <ProfileSelect
                        onChange={setSelectedProfile}
                        value={selectedProfile}
                        appearance="input"
                      />
                      {!isProfileComplete && (
                        <>
                          <span>
                            {i18n.t(
                              'components.addressSelectorModal.form.profile.noProfile.click'
                            )}
                          </span>
                          <Link to={`/profiles/${basket.profile?.id}`}>
                            {i18n.t(
                              'components.addressSelectorModal.form.profile.noProfile.here'
                            )}
                          </Link>
                          <span>
                            {i18n.t(
                              'components.addressSelectorModal.form.profile.noProfile.toFillProfile'
                            )}
                          </span>
                        </>
                      )}

                      <div className="my-4">
                        <label>
                          <Switch
                            checked={overrideAddress}
                            onChange={(value) => {
                              if (!value) {
                                basket.setOverrideAddress(undefined)
                              }
                              setOverrideAddress(value)
                            }}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={40}
                            height={24}
                            onColor={Green600}
                            className="align-middle mr-1"
                          />
                          <span className="text-xs">
                            {i18n.t(
                              'components.addressSelectorModal.form.overrideAddress'
                            )}
                          </span>
                        </label>
                      </div>

                      <div>
                        <IconInput
                          leftIcon={
                            <MdOutlineSmartphone size={24} color={Blue300} />
                          }
                          {...register('phoneNumber', {
                            required: i18n.t(
                              'components.addressSelectorModal.form.phoneNumber.rules.required'
                            ),
                            pattern: {
                              value: /^((00|\+)\d{1,3})? ?(\d[ -]?){9}$/,
                              message: i18n.t(
                                'components.addressSelectorModal.form.phoneNumber.rules.pattern'
                              ),
                            },
                          })}
                          disabled={!overrideAddress}
                          placeholder={i18n.t(
                            'components.addressSelectorModal.form.phoneNumber.placeholder'
                          )}
                        />
                        {errors.phoneNumber && (
                          <p className="hl-input-error">
                            {errors.phoneNumber.message}
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  <div className="mb-8">
                    <AddressSelect
                      onChange={(address) => {
                        if (address) {
                          setValue('address', address.address!)
                          setValue('postalCode', address.postalCode!)
                          setValue('city', address.city!)
                          setValue('location', address.location!)
                        } else {
                          setValue('address', '')
                          setValue('postalCode', '')
                          setValue('city', '')
                          setValue('location', {
                            type: 'Point',
                            coordinates: [0, 0],
                          })
                        }
                      }}
                      value={
                        watch('address')
                          ? {
                              address: watch('address'),
                              city: watch('city'),
                              postalCode: watch('postalCode'),
                              location: watch('location'),
                            }
                          : undefined
                      }
                      disabled={auth.isLogged ? !overrideAddress : false}
                    />
                  </div>
                  <div className="flex justify-end gap-2.5">
                    <button
                      className="hl-button"
                      onClick={handleSubmit(doSubmit)}
                      disabled={overrideAddress ? !watch('address') : false}
                    >
                      {i18n.t('common.ok')}
                    </button>
                    <button
                      className="hl-button hl-gradient-red"
                      onClick={doCancel}
                    >
                      {i18n.t('common.cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
)
