import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import { useClient } from 'urql'
import {
  GetConsultationDocument,
  GetConsultationQuery,
} from '../../generated/urql.anonymous'
import {
  GetProfilesSubscription,
  useGetProfilesSubscription,
} from '../../generated/urql.client'
import { AuthContext } from '../../provider/auth'
import { BasketContext, isConsultationBasketItem } from '../../provider/basket'
import { useI18n } from '../../provider/i18n'
import { Link } from 'react-router-dom'
import { Disclosure, Transition } from '@headlessui/react'
import { MdKeyboardArrowRight, MdShoppingBasket } from 'react-icons/md'
import { Blue600 } from '../../lib/colors'
import Markdown from 'react-markdown'
import ProfileSelect from '../../components/ProfileSelect'
import { MessageBox } from '../../components/MessageBox'
import {
  ConfirmDialog,
  ConfirmDialogType,
} from '../../components/ConfirmDialog'

export const CatalogConsultationPage = () => {
  const params = useParams<{ id: string }>()
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const basket = useContext(BasketContext)
  const client = useClient()
  const [generalError, setGeneralError] = useState<string>()
  const [consultation, setConsultation] =
    useState<GetConsultationQuery['consultation_by_pk']>()
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const id = parseInt(params.id || '0', 10)
  const confirmDialogRef = useRef<ConfirmDialogType>(null)

  const profiles = profilesData?.client_profile
  const [selectedProfile, setSelectedProfile] =
    useState<GetProfilesSubscription['client_profile'][number]>()
  const isAddedToBasket = useMemo(
    () =>
      auth.isLogged
        ? basket.items
            .filter(isConsultationBasketItem)
            .filter(({ consultation: { id } }) => consultation?.id === id)
            .length >= (profilesData?.client_profile.length || 0)
        : !!basket.items.find(
            (item) =>
              isConsultationBasketItem(item) && item.consultation.id === id
          ),
    [
      auth.isLogged,
      basket.items,
      profilesData?.client_profile.length,
      consultation?.id,
      id,
    ]
  )

  useEffect(() => {
    !selectedProfile &&
      profiles &&
      setSelectedProfile(profiles.find(({ isMain }) => isMain) || profiles[0])
  }, [profiles])

  const fetchConsultation = useCallback(() => {
    client
      .query(
        GetConsultationDocument,
        {
          id,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then(({ data }) => setConsultation(data.consultation_by_pk))
      .catch(console.error)
  }, [client, id])

  useEffect(fetchConsultation, [fetchConsultation])

  const doAddToBasket = useCallback(() => {
    if (isAddedToBasket || !consultation) {
      return
    }

    const profilesCount = profilesData?.client_profile.length || 0

    if (profilesCount === 0) {
      basket.addItem({ consultation })
    } else if (profilesCount === 1) {
      basket.addItem({ consultation, profile: profilesData?.client_profile[0] })
    } else {
      basket.addItem({ consultation, profile: selectedProfile })
    }
  }, [
    basket,
    isAddedToBasket,
    profilesData?.client_profile,
    selectedProfile,
    consultation,
  ])

  const doRemoveFromBasket = useCallback(() => {
    if (isAddedToBasket && consultation) {
      const items = basket.items
        .filter(isConsultationBasketItem)
        .filter((item) => item.consultation.id === consultation.id)
      if (items.length === 1) {
        basket.removeItem(items[0])
      } else if (items.length > 1) {
        confirmDialogRef.current?.show({
          message: i18n.t(
            'screens.catalog.test.basket.removeForAllProfiles.title'
          ),
          onSuccess: () => {
            basket.removeItem(...items)
          },
        })
      }
    }
  }, [basket, i18n, isAddedToBasket, consultation])

  return (
    <div className="mb-10">
      <div className="text-blue-700 mb-5">
        <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
        {' > '}
        <>
          <Link to={`/`}>{i18n.t('common.breadcrumbs.consultations')}</Link>
          {' > '}
        </>
        {consultation?.name || '-'}
      </div>

      {generalError && <MessageBox>{generalError}</MessageBox>}

      {consultation && (
        <>
          <div className="flex justify-between items-center mb-8 mt-5">
            <h1 className="text-2xl md:text-3xl font-bold leading-tight">
              {consultation.name}
            </h1>
          </div>
          <div className="hl-panel">
            {!!consultation.description && (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="p-4 md:p-6 text-lg font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                      <>
                        {i18n.t(
                          'screens.catalog.consultation.description.label'
                        )}
                        <MdKeyboardArrowRight
                          className={open ? 'rotate-90 transform' : ''}
                          size={24}
                          color={Blue600}
                        />
                      </>
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform max-h-0"
                      enterTo="transform max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform max-h-[1000px]"
                      leaveTo="transform max-h-0"
                    >
                      <Disclosure.Panel className="p-4 md:p-6 border-b border-b-border border-opacity-20">
                        <Markdown className="markdown">
                          {consultation.description}
                        </Markdown>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            <div className="bg-blue-200 rounded-b-2xl md:flex justify-end items-center p-5 md:py-12 md:px-8 gap-2.5 md:gap-5 grid grid-cols-2">
              <div className="flex gap-2.5 items-center justify-center md:justify-start">
                <MdShoppingBasket size={24} color={Blue600} />
                <p className="text-md md:text-lg font-bold">
                  {i18n.t('screens.catalog.test.price', {
                    price: consultation.price,
                  })}
                </p>
              </div>
              <button
                className={`hl-button ${
                  isAddedToBasket ? 'hl-gradient-red' : ''
                }`}
                onClick={!isAddedToBasket ? doAddToBasket : doRemoveFromBasket}
              >
                {!isAddedToBasket
                  ? i18n.t('screens.catalog.consultation.basket.add')
                  : i18n.t('screens.catalog.consultation.basket.remove')}
              </button>
              {auth.accessToken && !isAddedToBasket && (
                <ProfileSelect
                  onChange={setSelectedProfile}
                  value={selectedProfile}
                  filter={(profile) =>
                    !basket.items.find(
                      (item) =>
                        isConsultationBasketItem(item) &&
                        item.consultation.id === consultation.id &&
                        item.profile?.id === profile.id
                    )
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
      <ConfirmDialog ref={confirmDialogRef} />
    </div>
  )
}
