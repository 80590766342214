import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import { useI18n } from '../provider/i18n'
import { useUpdateOrderClientConsentsMutation } from '../generated/urql.client'
import Switch from 'react-switch'
import { Green600 } from '../lib/colors'
import { RegulationLinks } from '../lib/regulations'

export type ClientConsentModalType = {
  show: (args: {
    orderId: number
    onSuccess?: () => void
    onError?: (error: string) => void
  }) => void
}

type ClientConsentForm = {
  clientConsent1: boolean
  clientConsent2: boolean
  clientConsent3: boolean
}

export const ClientConsentModal = forwardRef<ClientConsentModalType>(
  (_, ref) => {
    const [show, setShow] = useState(false)
    const [orderId, setOrderId] = useState<number>()
    const [onSuccess, setOnSuccess] = useState<() => void>()
    const [onError, setOnError] = useState<(error: string) => void>()
    const [
      { fetching: updateOrderClientConsentsFetching },
      updateOrderClientConsents,
    ] = useUpdateOrderClientConsentsMutation()
    const i18n = useI18n()

    const { handleSubmit, control, reset } = useForm<ClientConsentForm>({
      defaultValues: {
        clientConsent1: false,
        clientConsent2: false,
        clientConsent3: false,
      },
    })

    useImperativeHandle(ref, () => ({
      show: ({ orderId, onSuccess, onError }) => {
        setOrderId(orderId)
        setOnSuccess(() => onSuccess)
        setOnError(() => onError)
        setShow(true)
        reset()
      },
    }))

    const doSubmit = useCallback(
      async (form: ClientConsentForm) => {
        if (!orderId) {
          onError?.('Order ID is required')
          setShow(false)
          return
        }
        const { error } = await updateOrderClientConsents({
          orderId,
          ...form,
        })

        if (error) {
          onError?.(error.message)
          setShow(false)
          return
        }

        onSuccess?.()
        setShow(false)
      },
      [onError, orderId, updateOrderClientConsents]
    )

    const doCancel = useCallback(() => {
      setShow(false)
    }, [setShow])

    if (!show) return null

    return (
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={show}
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl p-5 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Title className="text-lg mb-2.5 text-blue-700">
                    {i18n.t('components.clientConsentModal.title')}
                  </Dialog.Title>
                  <div className="mb-10">
                    <div className="my-5 md:mx-5">
                      <Controller
                        control={control}
                        rules={{
                          required: i18n.t(
                            'components.clientConsentModal.consentRequired'
                          ),
                        }}
                        name="clientConsent1"
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <label className="flex" ref={ref}>
                            <Switch
                              checked={value}
                              onChange={onChange}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={24}
                              onColor={Green600}
                              className="mr-6"
                            />
                            <span className="text-xs text-blue-700 pt-1">
                              {i18n.t('common.order.consent.text1')}
                              {error && (
                                <p className="hl-input-error">
                                  {error.message}
                                </p>
                              )}
                            </span>
                          </label>
                        )}
                      />
                    </div>

                    <div className="my-5 md:mx-5">
                      <Controller
                        control={control}
                        rules={{
                          required: i18n.t(
                            'components.clientConsentModal.consentRequired'
                          ),
                        }}
                        name="clientConsent2"
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <label className="flex" ref={ref}>
                            <Switch
                              checked={value}
                              onChange={onChange}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={24}
                              onColor={Green600}
                              className="mr-6"
                            />
                            <span className="text-xs text-blue-700 pt-1">
                              {i18n.t('common.order.consent.text2.part1')}{' '}
                              <a
                                href={
                                  RegulationLinks.regulation[i18n.locale] ||
                                  RegulationLinks.regulation.pl
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {i18n.t('common.order.consent.text2.part2')}
                              </a>{' '}
                              {i18n.t('common.order.consent.text2.part3')}{' '}
                              <a
                                href={
                                  RegulationLinks.privacyPolicy[i18n.locale] ||
                                  RegulationLinks.privacyPolicy.pl
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {i18n.t('common.order.consent.text2.part4')}
                              </a>{' '}
                              {i18n.t('common.order.consent.text2.part5')}
                              {error && (
                                <p className="hl-input-error">
                                  {error.message}
                                </p>
                              )}
                            </span>
                          </label>
                        )}
                      />
                    </div>

                    <div className="my-5 md:mx-5">
                      <Controller
                        control={control}
                        rules={{
                          required: i18n.t(
                            'components.clientConsentModal.consentRequired'
                          ),
                        }}
                        name="clientConsent3"
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <label className="flex" ref={ref}>
                            <Switch
                              checked={value}
                              onChange={onChange}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={24}
                              onColor={Green600}
                              className="mr-6"
                            />
                            <div className="text-xs text-blue-700 pt-1">
                              <span className="leading-tight">
                                {i18n.t('common.order.consent.text3.main')}
                              </span>
                              <br />
                              <span className="leading-tight whitespace-pre-wrap">
                                {i18n.t('common.order.consent.text3.bullets')}
                              </span>
                              {error && (
                                <p className="hl-input-error">
                                  {error.message}
                                </p>
                              )}
                            </div>
                          </label>
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-2.5">
                    <button
                      className="hl-button"
                      disabled={updateOrderClientConsentsFetching}
                      onClick={handleSubmit(doSubmit)}
                    >
                      {i18n.t('common.ok')}
                    </button>
                    <button
                      className="hl-button hl-gradient-red"
                      disabled={updateOrderClientConsentsFetching}
                      onClick={doCancel}
                    >
                      {i18n.t('common.cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
)
