import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { GoogleRecaptchaSiteKey } from '../const'
import { useRegisterMutation } from '../generated/urql.anonymous'
import { EmailRegex, PasswordRegex } from '../lib/patterns'
import { useRecaptcha } from '../lib/recaptcha'
import { useI18n } from '../provider/i18n'
import { WelcomeFrame } from '../components/WelcomeFrame'
import IconInput from '../components/IconInput'
import LockIcon from '../assets/lock.svg'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Blue300, Green600 } from '../lib/colors'
import PersonIcon from '../assets/person.svg'
import { MdEmail, MdOutlineSmartphone } from 'react-icons/md'
import { RegulationLinks } from '../lib/regulations'
import Switch from 'react-switch'
import PatientLogo from '../assets/patient-logo.svg'
import { Link } from 'react-router-dom'
import { MessageBox } from '../components/MessageBox'

export const RegisterPage = () => {
  const i18n = useI18n()
  const params = useParams<{ backTo: string }>()
  const navigate = useNavigate()
  const [{ fetching }, register] = useRegisterMutation()
  const getRecaptchaToken = useRecaptcha()
  const [generalError, setGeneralError] = useState<string>()
  const {
    control,
    handleSubmit,
    watch,
    register: registerForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      regulationConsent: false,
      newsletterConsent: false,
      pushConsent: false,
    },
  })
  const [revealPassword, setRevealPassword] = useState(false)

  async function doRegister({
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    newsletterConsent,
    pushConsent,
  }: {
    email: string
    password: string
    password2: string
    firstName: string
    lastName: string
    phoneNumber: string
    newsletterConsent: boolean
    pushConsent: boolean
  }) {
    setGeneralError(undefined)

    const recaptchaToken = await getRecaptchaToken({ action: 'signup' })

    if (!recaptchaToken) {
      setGeneralError(i18n.t('screens.register.generalError.recaptcha-error'))
      return
    }

    const { data, error } = await register({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      newsletterConsent,
      pushConsent,
      recaptchaToken,
    })

    if (error) {
      switch (error.graphQLErrors[0].extensions.code) {
        case 'invalid-email':
          setGeneralError(i18n.t('screens.register.generalError.invalidEmail'))
          return
        case 'email-exists':
          setGeneralError(i18n.t('screens.register.generalError.emailExists'))
          return
        case 'invalid-recaptcha':
          setGeneralError(
            i18n.t('screens.register.generalError.invalid-recaptcha')
          )
          return
        default:
          setGeneralError(error.message)
      }
      return
    }

    if (data?.client_register) {
      navigate(`/register/finish`, {
        state: {
          email,
          backTo: params.backTo,
        },
      })
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${GoogleRecaptchaSiteKey}`}
        ></script>
      </Helmet>
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4 px-5">
        <div className="flex justify-center mt-4">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <div className="flex-1 my-0 mx-auto sm:w-[480px] bg-blue-100 bg-opacity-50">
          {!!generalError && <MessageBox>{generalError}</MessageBox>}
          <form onSubmit={handleSubmit(doRegister)}>
            <div className="mx-4">
              <div className="my-4">
                <IconInput
                  leftIcon={<MdEmail size={24} color={Blue300} />}
                  {...registerForm('email', {
                    required: i18n.t(
                      'screens.register.form.email.rules.required'
                    ),
                    pattern: {
                      value: EmailRegex,
                      message: i18n.t(
                        'screens.register.form.email.rules.pattern'
                      ),
                    },
                  })}
                  placeholder={i18n.t(
                    'screens.register.form.email.placeholder'
                  )}
                />
                {errors.email && (
                  <p className="hl-input-error">{errors.email.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={LockIcon}
                      width={24}
                      height={24}
                      alt="Lock icon"
                    />
                  }
                  rightIcon={
                    revealPassword ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    )
                  }
                  {...registerForm('password', {
                    required: i18n.t(
                      'screens.register.form.password.rules.required'
                    ),
                    pattern: {
                      value: PasswordRegex,
                      message: i18n.t(
                        'screens.register.form.password.rules.pattern'
                      ),
                    },
                  })}
                  type="password"
                  placeholder={i18n.t(
                    'screens.register.form.password.placeholder'
                  )}
                />
                {errors.password && (
                  <p className="hl-input-error">{errors.password.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={LockIcon}
                      width={24}
                      height={24}
                      alt="Lock icon"
                    />
                  }
                  rightIcon={
                    revealPassword ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    )
                  }
                  {...registerForm('password2', {
                    required: i18n.t(
                      'screens.register.form.password2.rules.required'
                    ),
                    validate: (value) =>
                      value !== watch('password')
                        ? i18n.t(
                            'screens.register.form.password2.rules.mismatch'
                          )
                        : undefined,
                  })}
                  type="password"
                  placeholder={i18n.t(
                    'screens.register.form.password2.placeholder'
                  )}
                />
                {errors.password2 && (
                  <p className="hl-input-error">{errors.password2.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={PersonIcon}
                      width={24}
                      height={24}
                      alt="Person icon"
                    />
                  }
                  {...registerForm('firstName', {
                    required: i18n.t(
                      'screens.register.form.firstName.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.register.form.firstName.placeholder'
                  )}
                />
                {errors.firstName && (
                  <p className="hl-input-error">{errors.firstName.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={PersonIcon}
                      width={24}
                      height={24}
                      alt="Person icon"
                    />
                  }
                  {...registerForm('lastName', {
                    required: i18n.t(
                      'screens.register.form.lastName.rules.required'
                    ),
                  })}
                  placeholder={i18n.t(
                    'screens.register.form.lastName.placeholder'
                  )}
                />
                {errors.lastName && (
                  <p className="hl-input-error">{errors.lastName.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={<MdOutlineSmartphone size={24} color={Blue300} />}
                  {...registerForm('phoneNumber', {
                    required: i18n.t(
                      'screens.register.form.phoneNumber.rules.required'
                    ),
                    pattern: {
                      value: /^((00|\+)\d{1,3})? ?(\d[ -]?){9}$/,
                      message: i18n.t(
                        'screens.register.form.phoneNumber.rules.pattern'
                      ),
                    },
                  })}
                  placeholder={i18n.t(
                    'screens.register.form.phoneNumber.placeholder'
                  )}
                />
                {errors.phoneNumber && (
                  <p className="hl-input-error">{errors.phoneNumber.message}</p>
                )}
              </div>
              <div className="my-4">
                <Controller
                  control={control}
                  rules={{
                    required: i18n.t(
                      'screens.register.form.regulationConsent.rules.required'
                    ),
                  }}
                  name="regulationConsent"
                  render={({ field: { value, onChange } }) => (
                    <label>
                      <Switch
                        checked={value}
                        onChange={onChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={28}
                        height={14}
                        onColor={Green600}
                        className="align-text-bottom mr-1"
                      />
                      <span className="text-xs">
                        {i18n.t(
                          'screens.register.form.regulationConsent.part1'
                        )}{' '}
                        <a
                          href={
                            RegulationLinks.regulation[i18n.locale] ||
                            RegulationLinks.regulation.pl
                          }
                        >
                          {i18n.t(
                            'screens.register.form.regulationConsent.part2'
                          )}
                        </a>{' '}
                        {i18n.t(
                          'screens.register.form.regulationConsent.part3'
                        )}{' '}
                        <a
                          href={
                            RegulationLinks.privacyPolicy[i18n.locale] ||
                            RegulationLinks.privacyPolicy.pl
                          }
                        >
                          {i18n.t(
                            'screens.register.form.regulationConsent.part4'
                          )}
                        </a>
                      </span>
                    </label>
                  )}
                />
                {errors.regulationConsent && (
                  <p className="hl-input-error">
                    {errors.regulationConsent.message}
                  </p>
                )}
              </div>
              <div className="my-4">
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <label>
                      <Switch
                        checked={value}
                        onChange={onChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={28}
                        height={14}
                        onColor={Green600}
                        className="align-text-bottom mr-1"
                      />
                      <span className="text-xs">
                        {i18n.t('screens.register.form.newsletterConsent')}
                      </span>
                    </label>
                  )}
                  name="newsletterConsent"
                />
                {errors.newsletterConsent && (
                  <p className="hl-input-error">
                    {errors.newsletterConsent.message}
                  </p>
                )}
              </div>
              {/*<div className="my-4">
              <Controller
                control={control}
                rules={{
                  required: i18n.t(
                    'screens.register.form.pushConsent.rules.required'
                  ),
                }}
                render={({ field: { value, onChange } }) => (
                  <label>
                    <Switch
                      checked={value}
                      onChange={onChange}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      width={28}
                      height={14}
                      onColor={Green600}
                      className="align-text-bottom mr-1"
                    />
                    <span className="text-xs">
                      {i18n.t('screens.register.form.pushConsent')}
                    </span>
                  </label>
                )}
                name="pushConsent"
              />

              {errors.pushConsent && (
                <p className="hl-input-error">{errors.pushConsent.message}</p>
              )}
            </div>*/}
            </div>
            <div className="my-8 text-center">
              <button
                className="hl-button-lg"
                type="submit"
                disabled={fetching}
              >
                {i18n.t('screens.register.form.submit')}
              </button>
            </div>
          </form>
          <p className="m-4 text-center">
            <Link to="/resetpassword">
              {i18n.t('screens.register.links.forgotPassword.text')}
            </Link>{' '}
            |{' '}
            <Link to={`/login`}>
              {i18n.t('screens.register.links.login.text')}
            </Link>
          </p>
        </div>
      </WelcomeFrame>
    </>
  )
}
