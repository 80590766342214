import {
  Client_Profile,
  GetProfilesSubscription,
} from '../generated/urql.client'

export function isProfileComplete(
  profile: GetProfilesSubscription['client_profile'][number]
) {
  return (
    isProfileAddressComplete(profile) &&
    isProfileIdentityComplete(profile) &&
    isProfilePhoneNumberComplete(profile) &&
    isProfileGenderComplete(profile)
  )
}

export function isProfileIdentityComplete(
  profile: Pick<Client_Profile, 'identity'>
) {
  return !!profile.identity?.value
}

export function isProfileAddressComplete(
  profile: Pick<Client_Profile, 'city' | 'location'>
) {
  return !!profile.city && !!profile.location
}

export function isProfilePhoneNumberComplete(
  profile: Pick<Client_Profile, 'phoneNumber'>
) {
  return !!profile.phoneNumber
}

export function isProfileGenderComplete(
  profile: Pick<Client_Profile, 'gender'>
) {
  return !!profile.gender
}
